// @ts-nocheck

import { ShapeInfo, Intersection } from "kld-intersections";
import { SWEEP } from "../values/enums";
import { isSamePoint } from "../utils";
import { findAngleAtPointInEllipse, findPointAtAngleInEllipse, isPointOnArc } from "../utils/ellipseUtils";
import { generateRandomColor, rand, sq } from "../utils/utils";
import { intersectEE } from "../utils/intersectEE";
import Segment from "../tools/Segment";
import RootSegment from "../tools/RootSegment";

class ArcSegment extends RootSegment {
  constructor(type, p1, p2, opt = {}) {
    super();
    if (!p1) return; // for separators
    this.center = opt.center;
    this.width = opt.width;
    this.height = opt.height;
    this.sweepFlag = opt.sweepFlag;
    this.angle = opt.angle || 0;
    this.init(type, p1, p2, opt);
  }
  equals(seg) {
    if (this.type !== seg.type) return false;
    if (!isSamePoint(this.center, seg.center) || this.radiusX !== seg.radiusX || this.radiusY !== seg.radiusY || this.largeArcFlag !== seg.largeArcFlag) return false;
    if (isSamePoint(this.p1, seg.p1) && isSamePoint(this.p2, seg.p2) && this.sweepFlag == seg.sweepFlag) return true;
    if (isSamePoint(this.p1, seg.p2) && isSamePoint(this.p2, seg.p1) && this.sweepFlag != seg.sweepFlag) return true;
    return false;
  }
  intersects(segment) {
    let points = [];
    if (segment.type == "arc") {
      points = intersectEE({ o: this.center, rx: this.radiusX, ry: this.radiusY, rotation: this.angle }, { o: segment.center, rx: segment.radiusX, ry: segment.radiusY, rotation: segment.angle });
    } else {
      const intersection = Intersection.intersect(this.kldSegment, segment.kldSegment);
      points = intersection.points.map((p) => ({ x: p.x, y: p.y }));
    }

    points = points.filter((p) => isPointOnArc(p, this)); // kld bug fix: take only points that lie on arc

    if (segment.type == "arc") {
      points = points.filter((p) => isPointOnArc(p, segment)); // kld bug fix: take only points that lie on arc
    }
    return points;
  }
  intersectsKLDLine(kldLine) {
    return this.intersectsKLDPath(kldLine);
  }
  intersectsKLDPath(kldPath) {
    let points = Intersection.intersect(this.kldSegment, kldPath).points.map((p) => ({ x: p.x, y: p.y }));
    if (this.type == "arc") {
      points = points.filter((p) => isPointOnArc(p, this)); // kld bug fix: take only points that lie on arc
    }
    return points;
  }
  makeKldSegment() {
    this.radiusX = this.width / 2;
    this.radiusY = this.height / 2;
    this.startRadians = findAngleAtPointInEllipse(this, this.p1);
    this.endRadians = findAngleAtPointInEllipse(this, this.p2);

    if (this.sweepFlag == null) {
      this.sweepFlag = SWEEP.CLOCKWISE;
    }
    this.largeArcFlag = this.getLargeArcFlag(this.sweepFlag);

    const angleDeg = this.angle * (180 / Math.PI);
    return ShapeInfo.ellipse(this.center.x, this.center.y, this.radiusX, this.radiusY, -angleDeg);
  }
  getLargeArcFlag(sweepFlag) {
    let largeFlag;
    // this assumes clockwise drawing of arc
    let startDegrees = this.startRadians * (180 / Math.PI),
      endDegrees = this.endRadians * (180 / Math.PI);
    if (startDegrees < endDegrees) {
      largeFlag = endDegrees - startDegrees <= 180 ? 0 : 1;
    } else {
      let diff = 360 - (startDegrees - endDegrees);
      largeFlag = diff >= 180 ? 1 : 0;
    }

    // if it's anti-clockwise, simply flip the large flag
    if (sweepFlag == SWEEP.ANTICLOCKWISE) {
      largeFlag = 1 - largeFlag;
    }
    return largeFlag;
  }
  getPathData() {
    let d = "";
    const debugEndPoint = findPointAtAngleInEllipse(this, findAngleAtPointInEllipse(this, this.p2));
    if (!isSamePoint(debugEndPoint, this.p2, 5)) {
      console.log("ERROR: real and constructed end points are different!", debugEndPoint, this.p2);
    }
    const angleDeg = this.angle * (180 / Math.PI);
    d = `M${this.p1.x},${this.p1.y} A ${this.radiusX},${this.radiusY} ${angleDeg} ${this.largeArcFlag},${this.sweepFlag} ${this.p2.x},${this.p2.y}`;

    return d;
  }
  isPointOnIt(pt, distanceEPS = 0.001) {
    // rotate the point to match the ellipse rotation
    let x = (pt.x - this.center.x) * Math.cos(-this.angle) - (pt.y - this.center.y) * Math.sin(-this.angle) + this.center.x;
    let y = (pt.x - this.center.x) * Math.sin(-this.angle) + (pt.y - this.center.y) * Math.cos(-this.angle) + this.center.y;

    // find angle from center
    const dx = x - this.center.x;
    const dy = y - this.center.y;
    const angle = Math.atan2(dy * this.radiusX, dx * this.radiusY);

    // find point on ellipse perimeter that lies at that angle
    const ellipsePt = {
      x: this.center.x + this.radiusX * Math.cos(angle),
      y: this.center.y + this.radiusY * Math.sin(angle),
    };
    if (!isPointOnArc(ellipsePt, this)) return false;

    // calculate distance between pt and ellipsePt
    const dist = Math.sqrt(sq(x - ellipsePt.x) + sq(y - ellipsePt.y));

    // console.log("isPointOnIt", pt, dist);
    return dist < distanceEPS;
  }
  trim(end, k) {
    // find a point on segment, at a distance of k from end
    const o = findPointOnArc(this.center, this.radiusX, this.radiusY, this.startRadians, this.endRadians, this.getLargeArcFlag(this.sweepFlag), k, end, this.angle);
    if (end == 0) {
      this.p1 = o.pt;
      this.startRadians = o.radians;
    } else {
      this.p2 = o.pt;
      this.endRadians = o.radians;
    }

    this.kldSegment = this.makeKldSegment();
    return this;
  }
}
export default ArcSegment;

function findPointOnArc(center, radiusX, radiusY, startRadians, endRadians, largeArcFlag, k, whichEnd, rotationAngle) {
  // find a point on the perimeter of the arc at a distance of k from startRadians (if whichEnd is 0) or endRadians (if whichEnd is 1)
  while (startRadians < 0) startRadians += 2 * Math.PI;
  while (endRadians < 0) endRadians += 2 * Math.PI;
  let angle = computeKAngle(startRadians, endRadians, largeArcFlag, whichEnd, k);

  let x = center.x + radiusX * Math.cos(angle);
  let y = center.y + radiusY * Math.sin(angle);

  // rotate the point to match the ellipse rotation
  x = (x - center.x) * Math.cos(rotationAngle) - (y - center.y) * Math.sin(rotationAngle) + center.x;
  y = (x - center.x) * Math.sin(rotationAngle) + (y - center.y) * Math.cos(rotationAngle) + center.y;

  return {
    pt: { x, y },
    radians: angle,
  };
}

function computeKAngle(startRad, endRad, largeArcFlag, end, k) {
  // rotate end such that start lies on x axis
  var endRad2 = endRad - startRad;
  while (endRad2 < 0) endRad2 += 2 * Math.PI;

  // you have to visualize this to understand what's going on
  let kFromStart, kFromEnd;
  if (endRad2 < Math.PI) {
    // end lies below x axis
    kFromStart = k;
    kFromEnd = endRad2 - k;

    if (largeArcFlag) {
      kFromStart = 2 * Math.PI - k;
      kFromEnd = endRad2 + k;
    }
  } else {
    // end lies above x axis
    kFromStart = 2 * Math.PI - k;
    kFromEnd = endRad2 + k;

    if (largeArcFlag) {
      kFromStart = k;
      kFromEnd = endRad2 - k;
    }
  }

  // reverse the rotation
  let kFromStartReversed = kFromStart + startRad;
  let kFromEndReversed = kFromEnd + startRad;
  while (kFromStartReversed > 2 * Math.PI) kFromStartReversed -= 2 * Math.PI;
  while (kFromStartReversed < 0) kFromStartReversed += 2 * Math.PI;
  while (kFromEndReversed > 2 * Math.PI) kFromEndReversed -= 2 * Math.PI;
  while (kFromEndReversed < 0) kFromEndReversed += 2 * Math.PI;

  return end == 0 ? kFromStartReversed : kFromEndReversed;
}
