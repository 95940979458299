import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete, Menu, ListItem, List } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import LazyImage from "../renderer/library/LazyImage";
import TemplateCategories from "../renderer/TemplateCategories";
import { TEMPLATE_CATEGORIES } from "../renderer/values/TemplateCategories";
import Gallery from "./Gallery";

const LoggedoutTemplates = memo(() => {
  let [category, setCategory] = useState();
  const [query, setQuery] = useState("");
  const [cache, setCache] = useState({});
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (cache[category] === undefined) {
      handleSearch();
    } else {
      setResults([...cache[category]]);
    }
  }, [category]);

  const handleSearch = async () => {
    try {
      const cat = category || "Infographics";
      const response = await axios.get(`https://api.movingpencils.com/templates?category=${cat.toLowerCase()}&q=${query}`);
      setResults([...response.data]);

      if (cache[cat] === undefined) {
        setCache({ ...cache, [cat]: response.data });
      } else {
        setCache({ ...cache, [cat]: [...cache[cat], ...response.data] });
      }
    } catch (error) {
      setResults([]);
      console.error("Error fetching search results:", error);
    }
  };

  const onClick = async (id) => {
    try {
      //   redirect to template listing page
      console.log("clicked", id);
    } catch (error) {
      console.error("Error fetching template detail:", error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "stretch",
        alignItems: "stretch",

        height: "100%",
      }}
    >
      <div
        style={{
          padding: 15,
        }}
      >
        <List>
          {TEMPLATE_CATEGORIES.map((cat) => (
            <ListItem key={cat} style={{}} onClick={() => setCategory(cat)} style={{ cursor: "pointer" }}>
              {cat}
            </ListItem>
          ))}
        </List>
      </div>

      <Gallery data={results} onClick={onClick} />
    </div>
  );
});
export default LoggedoutTemplates;
