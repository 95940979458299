// @ts-nocheck

import { dup, getBoundingBox } from "../../utils/utils";
import { applyInlineStyles, toFloats, parseInlineStyle, read } from "../ParserUtils";

const DEFAULTS = {
  type: { value: "linearGradient" }, // not part of SVG spec, this is for my use
  id: { value: null },
  gradientUnits: { value: "objectBoundingBox" },
  gradientTransform: { value: "identity" },
  href: { aliases: ["xlink:href"], value: null },
  spreadMethod: { value: "pad" },
  x1: { value: "0%" },
  x2: { value: "100%" },
  y1: { value: "0%" },
  y2: { value: "0%" },
};
const DEFAULT_STOPS = [
  { stopColor: "#FF3357", offset: 0 },
  { stopColor: "#FF2A7C", offset: 100 },
];
const DEFAULTS_STOP = {
  offset: { value: "0" },
  "stop-color": { value: "black", rename: "color" },
  "stop-opacity": { value: "1", rename: "opacity" },
};

export class LinearGradient {
  static from_item(svgRef, item, stops = DEFAULT_STOPS) {
    const line = line_from_box(svgRef, item);
    let result = {
      stops,
      id: item.id,
      type: "linearGradient",
      line,
      initialLine: {
        p1: dup(line.p1),
        p2: dup(line.p2),
      },
    };
    return result;
  }
  static from_svg(elem) {
    if (elem.tagName !== "linearGradient") return null;
    let linearGradient = read(elem, DEFAULTS);
    linearGradient.stops = Array.from(elem.children).map((stop) => toFloats(applyInlineStyles(read(stop, DEFAULTS_STOP), stop)));
    linearGradient = toFloats(applyInlineStyles(linearGradient, elem));

    return linearGradient;
  }
}

function line_from_box(svgRef, item) {
  const box = getBoundingBox(svgRef, item);
  return {
    p1: {
      x: box?.x + 10,
      y: box?.y + box?.height / 2,
    },
    p2: {
      x: box?.x + box?.width - 10,
      y: box?.y + box?.height / 2,
    },
  };
}
