import { useEffect, useState } from "react";
import { useSVG } from "./renderer/SVGContext";
import axios from "axios";
import { Button, Card } from "@mui/material";
import "./Designs.css";

export function Designs({}) {
  const { getHeaders } = useSVG();
  const [designs, setDesigns] = useState([]);
  console.log("designs:", designs);

  function fetchDesigns() {
    axios
      .get(`https://api.movingpencils.com/designs`, {
        headers: getHeaders(),
      })
      .then((response) => {
        setDesigns(response.data);
      })
      .catch((error) => {
        console.error("Error fetching designs:", error);
      });
  }
  useEffect(() => {
    setDesigns(fetchDesigns());
  }, []);
  console.log("designs:", designs);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 20,
        justifyContent: "start",
        alignItems: "start",
      }}
    >
      {designs && designs.map((design) => <Design design={design} />)}
    </div>
  );
}

function Design({ design }) {
  const tid = design.design_json.templateId || "c352d8d4-c897-4fa4-9403-1f50c9a81039";

  return (
    <Card style={{ width: 200 }} className="design">
      <div
        style={{
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "100%",
            width: "100%",
          }}
          src={"https://s3.amazonaws.com/thumbnails.venngage.com/template/" + tid + ".png"}
        />
      </div>
      <div
        style={{
          padding: 15,
          textAlign: "center",
        }}
      >
        {design.design_json.title || "Untitled"}
      </div>
      <div className="edit">
        <Button variant="contained">Edit</Button>
      </div>
    </Card>
  );
}
