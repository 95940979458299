// @ts-nocheck

import BezierSegment from "../segments/BezierSegment";
import { onSameLine } from "./bezierOffsetingUtils";
import { dup } from "./utils";

// Threshold value for area comparison
var threshold = 0.0001;
const isPointOnSegmentEPS = 0.01;

// Function to check if bounding boxes of two Bezier curves intersect
function bi(B1, B2) {
	// https://stackoverflow.com/a/4041286
	var bbox1 = getBoundingBox(B1),
		bbox2 = getBoundingBox(B2);
	if (bbox1.maxX < bbox2.minX || bbox1.minX > bbox2.maxX || bbox1.maxY < bbox2.minY || bbox1.minY > bbox2.maxY) return null;

	if (bboxArea(bbox1) + bboxArea(bbox2) < threshold) {
		return findIntersection(bbox1, bbox2);
	}

	// Split B1 into B1a and B1b at t = 0.5
	var B1a = halveBezier(B1, true);
	var B1b = halveBezier(B1, false);

	// Split B2 into B2a and B2b at t = 0.5
	var B2a = halveBezier(B2, true);
	var B2b = halveBezier(B2, false);

	return bi(B1a, B2a) || bi(B1a, B2b) || bi(B1b, B2a) || bi(B1b, B2b);
}
export const bezierIntersections = bi;

// Function to get bounding box of a Bezier curve
function getBoundingBox(B) {
	var minX = Infinity;
	var minY = Infinity;
	var maxX = -Infinity;
	var maxY = -Infinity;

	// Loop through control points to find bounding box
	for (var i = 0; i < B.length; i++) {
		var point = B[i];
		minX = Math.min(minX, point.x);
		minY = Math.min(minY, point.y);
		maxX = Math.max(maxX, point.x);
		maxY = Math.max(maxY, point.y);
	}

	// Return bounding box as an object
	return {
		minX: minX,
		minY: minY,
		maxX: maxX,
		maxY: maxY,
	};
}

// Function to calculate area of a bounding box
function bboxArea(bbox) {
	return (bbox.maxX - bbox.minX) * (bbox.maxY - bbox.minY);
}

// Function to find intersection between two rectangles
function findIntersection(bbox1, bbox2) {
	// Extract coordinates of each rectangle
	var rect1 = [
		{ x: bbox1.minX, y: bbox1.minY }, // Top-left
		{ x: bbox1.maxX, y: bbox1.minY }, // Top-right
		{ x: bbox1.maxX, y: bbox1.maxY }, // Bottom-right
		{ x: bbox1.minX, y: bbox1.maxY }, // Bottom-left
	];
	var rect2 = [
		{ x: bbox2.minX, y: bbox2.minY }, // Top-left
		{ x: bbox2.maxX, y: bbox2.minY }, // Top-right
		{ x: bbox2.maxX, y: bbox2.maxY }, // Bottom-right
		{ x: bbox2.minX, y: bbox2.maxY }, // Bottom-left
	];

	// Array to store intersection points
	var intersectionPoints = [];

	// Loop through each line segment of rect1
	for (var i = 0; i < 4; i++) {
		var p1 = rect1[i];
		var p2 = rect1[(i + 1) % 4];

		// Loop through each line segment of rect2
		for (var j = 0; j < 4; j++) {
			var p3 = rect2[j];
			var p4 = rect2[(j + 1) % 4];

			// Calculate intersection point of two line segments
			var intersect = lineIntersection(p1, p2, p3, p4);

			// If intersection point exists and is within the line segments, add it to the array
			if (intersect && isPointOnSegment(intersect, p1, p2) && isPointOnSegment(intersect, p3, p4)) {
				intersectionPoints.push(intersect);
			}
		}
	}

	// Return the array of intersection points
	return intersectionPoints;
}

// Function to calculate intersection point of two line segments
export function lineIntersection(p1, p2, p3, p4) {
	var denominator = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
	if (Math.abs(denominator) < 0.0001) {
		// Lines are parallel
		// are they part of the same line segment?
		if (isPointOnSegment(p1, p3, p4)) return p1;
		if (isPointOnSegment(p2, p3, p4)) return p2;
		if (isPointOnSegment(p3, p1, p2)) return p3;
		if (isPointOnSegment(p4, p1, p2)) return p4;

		return null;
	}

	var ua = ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denominator;
	var ub = ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denominator;

	// Check if intersection point is within the line segments
	if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
		var x = p1.x + ua * (p2.x - p1.x);
		var y = p1.y + ua * (p2.y - p1.y);
		return { x: x, y: y };
	} else {
		// Intersection point is outside the line segments
		return null;
	}
}

// Function to check if a point lies on a line segment
function isPointOnSegment(point, p1, p2) {
	const eps = isPointOnSegmentEPS;
	if (Math.abs(p1.x - p2.x) < eps) {
		// vertical line
		return Math.abs(point.x - p1.x) < eps && point.y >= Math.min(p1.y, p2.y) && point.y <= Math.max(p1.y, p2.y);
	} else if (Math.abs(p1.y - p2.y) < eps) {
		// horiz line
		return Math.abs(point.y - p1.y) < eps && point.x >= Math.min(p1.x, p2.x) && point.x <= Math.max(p1.x, p2.x);
	} else {
		const slope = (p2.y - p1.y) / (p2.x - p1.x);
		const intercept = p1.y - slope * p1.x;
		return Math.abs(point.y - (slope * point.x + intercept)) < eps && point.x >= Math.min(p1.x, p2.x) && point.x <= Math.max(p1.x, p2.x) && point.y >= Math.min(p1.y, p2.y) && point.y <= Math.max(p1.y, p2.y);
	}
}

function halveBezier(B, firstHalf) {
	const o = splitBezierCurveWithT(...[...B, 0.5]);
	return firstHalf ? o.curve1 : o.curve2;
}
export function splitBezierCurveWithT(P0, P1, P2, P3, t) {
	// Calculate intermediate points
	let P01 = lerp(P0, P1, t);
	let P12 = lerp(P1, P2, t);
	let P23 = lerp(P2, P3, t);

	let P012 = lerp(P01, P12, t);
	let P123 = lerp(P12, P23, t);

	let Pnew = lerp(P012, P123, t);

	// Return the two new curves
	return {
		curve1: [P0, P01, P012, Pnew],
		curve2: [Pnew, P123, P23, P3],
	};
}
export function splitBezierCurveV2(segment, targetPoint) {
	let o = splitBezierCurve(segment.p1, segment.p1.handles[1], segment.p2.handles[0], segment.p2, targetPoint);
	let curve1p1 = {
			x: segment.p1.x,
			y: segment.p1.y,
			handles: [
				{
					x: segment.p1.handles[0].x,
					y: segment.p1.handles[0].y,
				},
				{
					x: o.curve1[1].x,
					y: o.curve1[1].y,
				},
			],
		},
		curve1p2 = {
			x: o.curve1[3].x,
			y: o.curve1[3].y,
			handles: [
				{
					x: o.curve1[2].x,
					y: o.curve1[2].y,
				},
				{
					x: o.curve1[3].x,
					y: o.curve1[3].y,
				},
			],
		},
		curve2p1 = {
			x: o.curve2[0].x,
			y: o.curve2[0].y,
			handles: [
				{
					x: o.curve2[0].x,
					y: o.curve2[0].y,
				},
				{
					x: o.curve2[1].x,
					y: o.curve2[1].y,
				},
			],
		},
		curve2p2 = {
			x: segment.p2.x,
			y: segment.p2.y,
			handles: [
				{
					x: o.curve2[2].x,
					y: o.curve2[2].y,
				},
				{
					x: segment.p2.handles[1].x,
					y: segment.p2.handles[1].y,
				},
			],
		};
	return {
		curve1: new BezierSegment("bezier", curve1p1, curve1p2),
		curve2: new BezierSegment("bezier", curve2p1, curve2p2),
	};
}
export function splitBezierCurve(P0, P1, P2, P3, targetPoint) {
	if (onSameLine(P0, P1, P2, P3)) {
		return {
			curve1: [P0, P1, dup(targetPoint), dup(targetPoint)],
			curve2: [dup(targetPoint), dup(targetPoint), P2, P3],
		};
	}
	const t = approximateTForPointOnBezier(P0, P1, P2, P3, targetPoint);
	return splitBezierCurveWithT(P0, P1, P2, P3, t);
}

function lerp(Pa, Pb, t) {
	return {
		x: (1 - t) * Pa.x + t * Pb.x,
		y: (1 - t) * Pa.y + t * Pb.y,
	};
}

export function approximateTForPointOnBezier(P0, P1, P2, P3, targetPoint) {
	let closestT = 0;
	let closestDistance = Infinity;
	const samples = 10000; // Increase for higher precision

	for (let i = 0; i <= samples; i++) {
		let t = i / samples;
		let pointOnCurve = bezierPoint(P0, P1, P2, P3, t);
		let distance = distanceBetweenPoints(pointOnCurve, targetPoint);

		if (distance < closestDistance) {
			closestT = t;
			closestDistance = distance;
		}
	}

	return closestT;
}

export function bezierPoint(P0, P1, P2, P3, t) {
	// Calculate a point on the Bezier curve for a given t
	let x = Math.pow(1 - t, 3) * P0.x + 3 * Math.pow(1 - t, 2) * t * P1.x + 3 * (1 - t) * Math.pow(t, 2) * P2.x + Math.pow(t, 3) * P3.x;
	let y = Math.pow(1 - t, 3) * P0.y + 3 * Math.pow(1 - t, 2) * t * P1.y + 3 * (1 - t) * Math.pow(t, 2) * P2.y + Math.pow(t, 3) * P3.y;
	return { x, y };
}

function distanceBetweenPoints(pointA, pointB) {
	// Calculate the Euclidean distance between two points
	let dx = pointA.x - pointB.x;
	let dy = pointA.y - pointB.y;
	return Math.sqrt(dx * dx + dy * dy);
}
