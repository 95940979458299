// @ts-nocheck

import GradientOverlayLine from "./components/GradientOverlayLine";
import { useSVG } from "./SVGContext";
import { fillToFillId } from "./utils/utils";

const SVGOverlayItem = ({ item }) => {
  if (!item || item.hidden) return null;

  const provider = useSVG();
  const { selectedCorners, selectedIds, decorators, defs } = useSVG();

  if (!selectedIds.includes(item.id)) return null;

  const o = { item, selectedIds, selectedCorners, provider };

  function getTextPathDecoration() {
    // GOTCHA: if the selected item is a text path, then show that path
    if (item.type == "text" && item.pathId) {
      const def = defs.find((x) => x.id === item.pathId);
      if (def) {
        return decorators.map((x) => x.decorateItem({ item: def })).filter((x) => x);
      }
    }
  }

  // function getGradientLine() {
  //   if (!["gradient-line-editor"].includes(provider.tool?.name)) return null;
  //   if (!item.fill?.startsWith("url")) return null;
  //   const def = provider.defs.find((def) => def.id === fillToFillId(item.fill));
  //   if (!def) return null;

  //   return <GradientOverlayLine itemId={item.id} def={def} lineFill="white" lineStroke="black" />;
  // }
  return (
    <>
      {decorators.map((x) => x.decorateItem(o)).filter((x) => x)}
      {getTextPathDecoration()}
      {/* {getGradientLine()} */}
    </>
  );
};

export default SVGOverlayItem;
