import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSVG } from "../SVGContext";
import LazyImage from "./LazyImage";
import { rand } from "../utils";
import { constants, ROOT_GROUP_ID } from "../values/constants";

const iconHeight = 50;
const categories = [];
const IconSearch = memo(() => {
  const { replacingIcon, setReplacingIcon } = useSVG();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    try {
      const response = await axios.get(`https://api.movingpencils.com/icons?q=${query.toLowerCase()}`);
      // in response, delete svg tag's height and width
      for (let i = 0; i < response.data.length; i++) {
        const svgCode = response.data[i];
        const svgTag = svgCode.match(/<svg[^>]*>/)[0];

        // replace width="24" or width="24em" or width="24px" with empty string
        // but not stroke-width="24"
        let newSvgTag = svgTag.replace(/ width="[^"]*"/, "");
        // replace height="24" or height="24em" or height="24px" with empty string
        newSvgTag = newSvgTag.replace(/ height="[^"]*"/, "");

        // if there is no width and height, set width and height to 100
        if (!newSvgTag.includes("width") && !newSvgTag.includes("height")) {
          newSvgTag = newSvgTag.replace("<svg", '<svg height="${iconHeight}"');
        }
        response.data[i] = svgCode.replace(svgTag, newSvgTag);
      }
      setResults([...response.data]);
    } catch (error) {
      setResults([]);
      console.error("Error fetching search results:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const { items, groups, setItems, setGroups } = useSVG();

  const onClick = async (iconSvg) => {
    // TODO edit iconSvg to set x, y, etc

    console.log("iconSvg", iconSvg);
    const newItemId = "icon-" + rand();
    items[newItemId] = {
      id: newItemId,
      type: "svg",
      innerHTML: iconSvg,
    };
    if (replacingIcon) {
      // delete this
      const oldItem = items[replacingIcon];

      // set same x, y, height, width, fill, stroke and transform as old item
      for (const key of ["x", "y", "height", "width", "fill", "stroke", "transform"]) {
        items[newItemId][key] = oldItem[key];
      }

      if (groups[oldItem.group]) {
        // delete from its group
        groups[oldItem.group].children = groups[oldItem.group].children.filter((i) => i !== replacingIcon);

        // add new item to same group
        groups[oldItem.group].children.push(newItemId);
        items[newItemId].group = oldItem.group;
      } else {
        console.log("why was that item not in any group?");
      }
      items[replacingIcon] = null;

      setReplacingIcon(null);

      setItems({ ...items });
      setGroups({ ...groups });
    } else {
      items[newItemId].group = ROOT_GROUP_ID;
      setItems({ ...items, [newItemId]: items[newItemId] });
      groups[ROOT_GROUP_ID].children.push(newItemId);
      setGroups({ ...groups });
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", padding: "0 20px" }}>
        <Autocomplete
          fullWidth
          freeSolo
          options={categories}
          inputValue={query}
          onInputChange={(event, newValue) => {
            if (categories.includes(newValue)) {
              category = newValue;
              setCategory(category);
              setQuery(newValue);
              handleSearch();
            } else {
              setQuery(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" onKeyPress={handleKeyPress} placeholder="Search Icons" />}
        />

        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>

      <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", padding: 10, overflowY: "scroll", height: "100%" }}>
        {results.map(
          (result, j) =>
            j % 3 == 0 && (
              <div
                key={j}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {[0, 1, 2].map(
                  (i) =>
                    j + i < results.length && (
                      <LazyImage
                        minHeight={iconHeight}
                        key={j + i}
                        svg={results[j + i]}
                        style={{ width: "100%", border: "1px solid #e0e0e0" }}
                        onClick={() => {
                          onClick(results[j + i]);
                        }}
                      />
                    ),
                )}
              </div>
            ),
        )}
      </div>
    </>
  );
});
export default IconSearch;
