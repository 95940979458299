// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { toBezier } from "../utils/PathUtils";
import { BezierItem } from "../interfaces/BezierItem";
import { initSegments } from "../utils/SegmentUtils";
import { dup, pointsToPath } from "../utils/utils";

export const pathOnMouseDown = ({ xy, path, setPath }) => {
  const rawPoints = [dup(xy)];
  path = new BezierItem({ points: rawPoints });
  path.rawPoints = rawPoints;
  path.smoothness = 20;

  setPath(path);

  return path;
};

export const pathOnMouseMove = ({ xy, path, setPath }) => {
  if (!path) return;

  path.rawPoints.push(dup(xy));
  path.points = toBezier(path.rawPoints, 0);
  setPath(path);
  return path;
};

export const pathOnMouseUp = ({ path, setPath }) => {
  path = path.update({ points: toBezier(path.rawPoints, path.smoothness) });

  path = null;
  setPath(null);
};
