// @ts-nocheck

export function transform(item, manyOrOneTransformations) {
  let t = manyOrOneTransformations;
  if (!t.length) {
    t = [t];
  }
  for (let i = 0; i < t.length; i++) {
    item = transformItem(item, t[i]);
  }
  return item;
}

function transformItem(item, transformation) {
  return transformation && item && item.mpTransform ? item.mpTransform(transformation) : item;
}

export function transformPoint(pt, t) {
  let { x, y } = pt;
  const center = t.center;
  if (t.type == "rotate") {
    const angle = t.angle;
    const xx = x - center.x;
    const yy = y - center.y;
    const xNew = xx * Math.cos(angle) - yy * Math.sin(angle);
    const yNew = xx * Math.sin(angle) + yy * Math.cos(angle);
    x = xNew + center.x;
    y = yNew + center.y;
  }

  if (t.type == "translate") {
    x = x + t.x;
    y = y + t.y;
  }
  if (t.type == "skew") {
    // TODO:
  }
  if (t.type == "scale") {
    // todo: rename to scaleMultiply
    // scale the point away or towards t.center
    if (!center) {
      console.log("need center for scaling point");
    } else {
      x = center.x + (x - center.x) * t.x;
      y = center.y + (y - center.y) * t.y;
    }
  }
  if (isNaN(x) || isNaN(y)) {
    console.error("stop! x, y is NaN", pt, t, center);
  }
  return {
    x: x,
    y: y,
  };
}
