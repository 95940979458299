// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { TextItem } from "../interfaces/TextItem";
import { initPointsForShape, initSegments, segmentsToPathData } from "../utils/SegmentUtils";
import { dup, rand, toDegree, toRadians } from "../utils/utils";
import { toBezier } from "../utils/PathUtils";
import { BezierItem } from "../interfaces/BezierItem";
import { ShapeType, SWEEP } from "../values/enums";
import { Group } from "../Group";
import { arcToBezier } from "./ArcToBezierUtils";
import BezierSegment from "../segments/BezierSegment";
import { segmentsToPoints } from "./bezierUtils";

export function toColumnItems(data) {
  const maxValue = Math.max(...data.map((d) => d.value));
  const chartHeight = 400;
  const chartWidth = 100;
  const barWidth = chartWidth / data.length;

  let items = [];
  // generate bezier items based on data
  let item;
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    let shape = {
      shapeType: ShapeType.Rectangle,
      center: {
        x: i * barWidth,
        y: 0,
      },
      width: barWidth - 2,
      height: (d.value / maxValue) * chartHeight,
      sides: 4,
      isClosed: true,
    };

    const height = (d.value / maxValue) * chartHeight;
    const topGap = chartHeight - height;
    const centerY = topGap + height / 2;
    shape.center = {
      x: i * barWidth + barWidth / 2,
      y: centerY,
    };
    item = initSegments(initPointsForShape(shape));
    item = new BezierItem({
      segments: item.segments,
      points: item.points,
      isClosed: true,
      source: {
        fill: "red",
      },
    });

    let textItem = new TextItem({
      id: "text-" + rand(),
      type: "text",
      x: i * barWidth + barWidth / 2,
      y: centerY + height / 2 + 20,
      fontSize: 10,
      textAnchor: "middle",
      fill: "black",
      text: d.label,
    });
    items = [...items, item, textItem];
  }
  return items;
}

export function toBarItems(data) {
  const maxValue = Math.max(...data.map((d) => d.value));
  const chartHeight = 100;
  const chartWidth = 400;
  const barHeight = chartHeight / data.length;

  let items = [];
  // generate bezier items based on data
  let item;
  for (let i = 0; i < data.length; i++) {
    const d = data[i];
    let shape = {
      shapeType: ShapeType.Rectangle,
      center: {
        x: 0,
        y: i * barHeight,
      },
      width: (d.value / maxValue) * chartWidth,
      height: barHeight - 2,
      sides: 4,
      isClosed: true,
    };

    const width = (d.value / maxValue) * chartWidth;
    const gap = chartWidth - width;
    const centerX = width / 2;
    shape.center = {
      x: centerX,
      y: i * barHeight + barHeight / 2,
    };
    item = initSegments(initPointsForShape(shape));
    item = new BezierItem({
      segments: item.segments,
      points: item.points,
      isClosed: true,
      source: {
        fill: "red",
      },
    });

    let textItem = new TextItem({
      id: "text-" + rand(),
      type: "text",
      x: shape.center.x + 20,
      y: shape.center.y,
      fontSize: 10,
      textAnchor: "middle",
      fill: "black",
      text: d.label,
    });
    items = [...items, item, textItem];
  }
  return items;
}


export function toPieItems(data) {
  const radius = 100;
  const circumference = 2 * Math.PI * radius;
  const total = data.reduce((acc, datum) => acc + datum.value, 0);

  let cumulativeValue = 0;

  let items = [];
  const center = {
    x: 200,
    y: 200,
  };
  for (let i = 0; i < data.length; i++) {
    const datum = data[i];

    const sliceAngle = toRadians((datum.value / total) * 360);
    const largeArcFlag = sliceAngle > Math.PI ? 1 : 0;
    const startAngle = toRadians((cumulativeValue / total) * 360);
    cumulativeValue += datum.value;

    const startX = center.x + radius * Math.cos(startAngle);
    const startY = center.y + radius * Math.sin(startAngle);
    const endX = center.x + radius * Math.cos(startAngle + sliceAngle);
    const endY = center.y + radius * Math.sin(startAngle + sliceAngle);

    const arcD = `M ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;
    // console.log(arcD);
    //   x1, y1, rx, ry, xAxisRotation, largeArc, sweep, x2, y2, sequence, isClosed;
    const item = arcToBezier(startX, startY, radius, radius, 0, largeArcFlag, SWEEP.CLOCKWISE, endX, endY, [], true);
    item.fill = `hsl(${(i / data.length) * 360}, 100%, 50%)`;

    // console.log(segmentsToPathData(item.segments));
    // you have to manually add the first and the last segments to turn it from an arc to a closed pizza slice
    let seg0 = new BezierSegment("bezier", dup(center), dup(item.segments[0].p1));
    let seg1 = new BezierSegment("bezier", dup(item.segments[item.segments.length - 1].p2), dup(center));
    // item.segments = [seg0, ...item.segments, seg1];
    // item.points = segmentsToPoints(item.segments, true);
    // console.log(center, toDegree(startAngle), toDegree(sliceAngle), startX, startY);

    let textItem = new TextItem({
      id: "text-" + rand(),
      type: "text",
      x: (radius + 20) * Math.cos(startAngle + sliceAngle / 2),
      y: (radius + 20) * Math.sin(startAngle + sliceAngle / 2),
      fontSize: 10,
      text: datum.label,
    });
    items = [...items, item, textItem];
  }
  return items;
}

export function toChartItems(chartType, data) {
  let newItems = [];
  if (chartType === "column") {
    newItems = toColumnItems(data);
  } else if (chartType == "bar") {
    newItems = toBarItems(data);
  } else if (chartType === "pie") {
    newItems = toPieItems(data);
  }
  return newItems;
}
