import { Button, ButtonGroup, Divider, IconButton, InputLabel, ListSubheader, Menu, MenuItem, Select, Slider, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSVG } from "../SVGContext";
import { GoogleFontFamilies, WebSafeFonts } from "../values/GoogleFontFamilies";
import { DEFTYPES } from "../values/enums";
import { TextItem } from "../interfaces/TextItem";
import { constants } from "../values/constants";
import React, { useEffect, useState } from "react";
import { allGoogleFonts } from "../values/all-google-fonts";
import { FontsList } from "../details/FontsList";
import MenuIcon from "@mui/icons-material/Menu";
import HeightIcon from "@mui/icons-material/Height";
import AlignLeft from "@mui/icons-material/FormatAlignLeft";
import AlignCenter from "@mui/icons-material/FormatAlignCenter";
import AlignRight from "@mui/icons-material/FormatAlignRight";
import { ColorSquare } from "../ColorSquare";
import { addFontDef } from "../utils/utils";

const TopbarText = ({ item }) => {
  let { items, setItems, selectedIds, defs, setDefs } = useSVG();

  const [selectedFontIdx, setSelectedFontIdx] = React.useState(-1);
  let [fonts, setFonts] = React.useState([...GoogleFontFamilies]);

  useEffect(() => {
    if (!item) return;

    let settingsSource;
    if (item.text) {
      settingsSource = item;
    } else if (item.tspans) {
      // GOTCHA: this is a hack to show \n in textfield
      // text = item.tspans.map((t) => t.text).join("\n");
      settingsSource = item;
    } else if (item.ps) {
      settingsSource = item.style;
    }

    // font family
    if (settingsSource?.fontFamily) {
      const pat = settingsSource?.fontFamily;
      if (allGoogleFonts[pat]) {
        // if the font is not present on the list shown here, but is a google font, then add it to the list
        fonts.push(pat);
        setFonts([...fonts]);
        setSelectedFontIdx(fonts.length - 1);
      }
    }
  }, [item]);

  if (!item) return;

  function onFontSelect(e, v) {
    const font = fonts[e.target.value];
    setSelectedFontIdx(fonts.findIndex((f) => f === font));

    if (item.style) {
      item.style = { ...item.style, fontFamily: font };
      items[item.id] = item;
      setItems({ ...items });
    }

    // note: we use webfontloader to show the font in the editor. But for the svg to show the
    // correct font, we need to add the font to the defs.
    // Check if this font is already in the defs, and if not, add it.
    defs = addFontDef(font, defs);
    setDefs([...defs]);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <FontColor item={item} />
      <FontsList onChange={onFontSelect} fonts={fonts} index={selectedFontIdx} />
      <FontSize item={item} />

      {multiButtons.map((button) => {
        const prop = "text" + button.name.charAt(0).toUpperCase() + button.name.slice(1);
        return (
          <div key={button.name}>
            <ToggleButtonGroup
              value={item[prop]}
              exclusive
              onChange={(e, value) => {
                item[prop] = value;
                setItems({ ...items });
              }}
            >
              {button.options.map((option) => (
                <ToggleButton size="small" value={option} key={option}>
                  <span
                    style={{
                      textDecoration: option,
                      fontSize: "0.8em",
                      lineHeight: "1.5em",
                    }}
                  >
                    A
                  </span>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        );
      })}
      <BoldItalicUnderline item={item} />
      <Align item={item} />

      <IconButton>
        <HeightIcon />
      </IconButton>
    </div>
  );
};

export default TopbarText;

const multiButtons = [
  {
    name: "decoration",
    options: ["underline", "line-through", "overline"],
  },
  // {
  //   name: "anchor",
  //   options: ["start", "middle", "end"],
  // },
];

function FontSize({ item }) {
  const { items, setItems } = useSVG();
  let fontSize = +item.style?.fontSize?.replace("px", "") || 12;

  function setFontSize(size) {
    item.style = { ...item.style, fontSize: size + "px" };
    items[item.id] = item;
    setItems({ ...items });
  }

  return (
    <ButtonGroup size="small">
      <Button onClick={() => setFontSize(fontSize - 1)}>
        <span>-</span>
      </Button>
      <Button>{fontSize}</Button>
      <Button onClick={() => setFontSize(fontSize + 1)}>
        <span>+</span>
      </Button>
    </ButtonGroup>
  );
}

function Align({ item }) {
  const { items, setItems } = useSVG();
  const [anchorEl, setAnchor] = useState(null);

  let alignment = item.style?.textAlign;

  return (
    <div>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        {(!alignment || alignment === "left") && <AlignLeft />}
        {alignment === "center" && <AlignCenter />}
        {alignment === "right" && <AlignRight />}
      </IconButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {["Left", "Center", "Right"].map((choice) => (
          <MenuItem
            onClick={() => {
              item.style = { ...item.style, textAlign: choice.toLowerCase() };
              items[item.id] = item;
              setItems({ ...items });

              setAnchor(null);
            }}
          >
            {choice === "Left" && <AlignLeft />}
            {choice === "Center" && <AlignCenter />}
            {choice === "Right" && <AlignRight />}
            <span style={{ marginLeft: 8 }}>{choice}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

function BoldItalicUnderline({ item }) {
  const { items, setItems } = useSVG();

  return ["B", "I", "U"].map((t) => (
    <IconButton
      size="small"
      onClick={() => {
        if (item.style) {
          if (t == "B") {
            const choice = item.style.fontWeight == "bold" ? "normal" : "bold";
            item.style = { ...item.style, fontWeight: choice };
          }
          if (t == "I") {
            const choice = item.style.fontStyle == "italic" ? "normal" : "italic";
            item.style = { ...item.style, fontStyle: choice };
          }
          if (t == "U") {
            const choice = item.style.textDecoration == "underline" ? "none" : "underline";
            item.style = { ...item.style, textDecoration: choice };
          }
          items[item.id] = item;
          setItems({ ...items });
        }
      }}
    >
      <span
        style={{
          textDecoration: t,
          fontSize: "0.8em",
          lineHeight: "1.5em",
        }}
      >
        {t}
      </span>
    </IconButton>
  ));
}

function FontColor({ item }) {
  const { items, setItems } = useSVG();

  return (
    <ColorSquare
      color={item.style.color}
      onChange={(color) => {
        item.style = { ...item.style, color: color.hex };
        items[item.id] = item;
        setItems({ ...items });
      }}
    />
  );
}
