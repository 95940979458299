// @ts-nocheck

export function findAngleAtPointInEllipse(segment, pt) {
	// first, translate this point such that the ellipse is centered at the origin
	pt = { x: pt.x - segment.center.x, y: pt.y - segment.center.y };
	// then, rotate the point around the origin by the angle of the ellipse
	pt = {
		x: pt.x * Math.cos(-segment.angle) - pt.y * Math.sin(-segment.angle),
		y: pt.x * Math.sin(-segment.angle) + pt.y * Math.cos(-segment.angle),
	};
	// then, translate the point back to the original position
	pt = { x: pt.x + segment.center.x, y: pt.y + segment.center.y };

	// then, find the angle
	let { x: cx, y: cy } = segment.center,
		rx = segment.radiusX,
		ry = segment.radiusY;
	let a = Math.atan2((pt.y - cy) * rx, (pt.x - cx) * ry);
	while (a < 0) a += Math.PI * 2;
	return a;
}

export function findPointAtAngleInEllipse(segment, angle) {
	const { x: cx, y: cy } = segment.center,
		rx = segment.radiusX,
		ry = segment.radiusY;
	const unRotatedPt = { x: cx + rx * Math.cos(angle), y: cy + ry * Math.sin(angle) };
	const rotatedPt = {
		x: (unRotatedPt.x - cx) * Math.cos(segment.angle) - (unRotatedPt.y - cy) * Math.sin(segment.angle) + cx,
		y: (unRotatedPt.x - cx) * Math.sin(segment.angle) + (unRotatedPt.y - cy) * Math.cos(segment.angle) + cy,
	};
	return rotatedPt;
}

export function isPointOnArc(point, arc) {
	// GOTCHA: we don't take into account rotation at all. I think it should work without it.
	let angle = findAngleAtPointInEllipse(arc, point);

	let startDegrees = arc.startRadians * (180 / Math.PI),
		endDegrees = arc.endRadians * (180 / Math.PI);
	let angleDegrees = angle * (180 / Math.PI);

	while (startDegrees < 0) startDegrees += 360;
	while (endDegrees < 0) endDegrees += 360;
	while (angleDegrees < 0) angleDegrees += 360;

	if (startDegrees > endDegrees) {
		// valid values are from start to 360 and 0 to end
		return (angleDegrees >= startDegrees && angleDegrees <= 360) || (angleDegrees >= 0 && angleDegrees <= endDegrees);
	} else {
		// valid values are from start to end
		return angleDegrees >= startDegrees && angleDegrees <= endDegrees;
	}
}
