// @ts-nocheck

import { BezierItem } from "../interfaces/BezierItem";
import { toDegree, toRadians } from "../utils/utils";
import { DEFTYPES } from "../values/enums";

// function calculateGradientTransform(line, initialLine) {
//   const { p1, p2 } = line;

//   const dx = p2.x - p1.x;
//   const dy = p2.y - p1.y;

//   const length = Math.sqrt(dx * dx + dy * dy);
//   const origLength = Math.sqrt((initialLine.p2.x - initialLine.p1.x) ** 2 + (initialLine.p2.y - initialLine.p1.y) ** 2);
//   const scale = length / origLength;

//   const deg = toDegree(Math.atan2(dy, dx));
//   return `rotate(${deg}) scale(${scale})`;
// }
function Stops({ stops }) {
  return (
    <>
      {stops.map((stop, i) => (
        <stop key={i} offset={stop.offset} stopColor={stop.stopColor} stopOpacity={stop.stopOpacity || 1} />
      ))}
    </>
  );
}
function LinearGradient({ def }) {
  const props = { ...def };
  delete props.stops;
  props.gradientTransform = props.gradientTransform;
  return (
    <linearGradient {...props}>
      <Stops stops={def.stops} />
    </linearGradient>
  );
}

function RadialGradient({ def }) {
  const props = { ...def };
  delete props.stops;
  props.gradientTransform = props.gradientTransform;
  return (
    <radialGradient {...props}>
      <Stops stops={def.stops} />
    </radialGradient>
  );
}

function ImportUrl({ def }) {
  return <style>@import url('{def.url}');</style>;
}
function Path({ def }) {
  return <path {...def} />;
}

function SVGDef({ def }) {
  if (!def) return null;
  return (
    <>
      {def.type === DEFTYPES.LINEARGRADIENT && <LinearGradient def={def} />}
      {def.type === DEFTYPES.RADIALGRADIENT && <RadialGradient def={def} />}
      {def.type === DEFTYPES.IMPORTURL && <ImportUrl def={def} />}
      {def.type === BezierItem.type && <Path def={def} />}
    </>
  );
}

export default SVGDef;
