// @ts-nocheck
import React, { useRef } from "react";
// import { useEditor } from './EditorContext';

import { IconButton, Grid, Tooltip, Dialog, Popover } from "@mui/material";
import BrushIcon from "@mui/icons-material/Brush";
import CropFreeIcon from "@mui/icons-material/CropFree";
import PointerIcon from "@mui/icons-material/Navigation";
import FontIcon from "@mui/icons-material/Title";
import TextPathIcon from "@mui/icons-material/TextFormat";
import PhotoIcon from "@mui/icons-material/PhotoSizeSelectActual";
import AnchorPointIcon from "@mui/icons-material/BrightnessLow";
import PenIcon from "@mui/icons-material/Mode";
import CurveIcon from "@mui/icons-material/SwitchAccessShortcut";
import PanIcon from "@mui/icons-material/PanTool";
import ChartIcon from "@mui/icons-material/BarChart";
import SquareIcon from "@mui/icons-material/Square";
import Eraser from "./icons/Eraser";
import Gradient from "./icons/Gradient";
import Width from "./icons/Width";
import WhiteArrow from "./icons/WhiteArrow";
import Knife from "./icons/Knife";

import { useSVG } from "./SVGContext";
import BlackArrow from "./icons/BlackArrow";
import Artboard from "./icons/Artboard";
import CropFree from "@mui/icons-material/CropFree";
import Eyedropper from "./icons/Eyedropper";
import Blend from "./icons/Blend";
import MySketchPicker from "./details/MySketchPicker";
import { ShapeBuilder } from "./icons/ShapeBuilder";
import { constants } from "./values/constants";
import { isDev } from "./utils/utils";
import LinesDropDown from "./components/LinesDropdown";
import Bezier from "./icons/Bezier";
import Curve from "./icons/Curve";
import VideoTooltip from "./VideoTooltip";
import { Shapes } from "./dialogs/Shapes";
import WhiteArrowTest from "./icons/WhiteArrowTest";
import ShapesDropdown from "./components/ShapesDropdown";
import ChartsDropDown from "./components/ChartsDropdown";

function noModifier(e) {
  return !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey;
}
function noModifierExceptShift(e) {
  return !e.ctrlKey && e.shiftKey && !e.altKey && !e.metaKey;
}
export const toolbarTools = [
  {
    name: "pointer",
    icon: <BlackArrow />,
    shortCut: (e) => e.key === "v" && noModifier(e),
    tooltip: "Selection (V)",
  },
  {
    name: "chart",
    icon: <ChartsDropDown />,
    shortCut: (e) => e.key === "c" && noModifier(e),
    tooltip: "Chart (C)",
    disabled: true,
  },
  {
    name: "pan",
    icon: <PanIcon />,
    shortCut: (e) => e.key === "h" && noModifier(e),
    tooltip: "Hand (H)",
  },
  {
    name: "shape",
    icon: <ShapesDropdown />,
    shortCut: (e) => e.key === "l" && noModifier(e),
    tooltip: "Shape (L)",
  },
  {
    name: "text",
    icon: <FontIcon />,
    shortCut: (e) => e.key === "t" && noModifier(e),
    tooltip: "Text (T)",
  },
  {
    name: "textPath",
    icon: <TextPathIcon />,
    shortCut: (e) => e.key === "t" && e.shiftKey && !e.altKey && !e.metaKey,
    tooltip: "Text Path (T)",
  },
  {
    name: "eyeDropper",
    icon: <Eyedropper />,
    shortCut: (e) => e.key === "i" && noModifier(e),
    tooltip: "Eye dropper (I)",
  },

  { name: "resizeCanvas", icon: <CropFreeIcon />, tooltip: "Resize Canvas" },
];

// if (isDev) {
// 	rightTools.push({ name: 'glue', icon: <BrushIcon />, tooltip: "Glue", });
// }

const Toolbar = ({ tools }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  let { items, setItems, tool, setTool, fill, setFill, stroke, setStroke, setOpenDialog, settings } = useSVG();
  const [showFillPicker, setShowFillPicker] = React.useState(false);
  const [showStrokePicker, setShowStrokePicker] = React.useState(false);
  const [isShapesPopoverOpen, setShapesPopoverOpen] = React.useState(false);
  const shapesButtonRef = useRef(null);

  const iconColor = "gray";

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        let image = {
          type: "image",
          id: new Date().getTime(),
          href: reader.result as string,
          x: 0,
          y: 0,
          width: 100,
          height: 100,
        };
        items.push(image);
        setItems([...items]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUploadClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onFillClicked = () => {
    setShowFillPicker(!showFillPicker);
  };
  const onFillChange = (color) => {
    setShowFillPicker(false);
    setFill(color.hex);
  };
  const onStrokeClicked = () => {
    setShowStrokePicker(!showStrokePicker);
  };
  const onStrokeChange = (color) => {
    setShowStrokePicker(false);
    setStroke(color.hex);
  };

  const fillBackground = fill == "transparent" ? "white" : fill;

  return (
    <div className="Toolbar">
      <div className="ToolbarColumns">
        <div className="toolbar-column">
          {toolbarTools.map(
            (thisTool, index) =>
              !thisTool.disabled && (
                <div key={index} ref={thisTool.name == "shape" ? shapesButtonRef : null} className={"toolbar-item " + (tool && thisTool.name === tool.name ? "selected" : "")}>
                  {
                    <Tooltip
                      key={"tooltip_" + index}
                      title={thisTool.tooltip}
                      placement="right-end"
                      TransitionProps={{ timeout: 0 }} // disables the transition animation
                    >
                      <>
                        {thisTool.name === "shape" && (
                          <ShapesDropdown
                            onShapeSelect={(shape) => {
                              if (shape) {
                                setTool(tools["shape"][shape]);
                              } else {
                                setShapesPopoverOpen(true);
                              }
                            }}
                          />
                        )}

                        {thisTool.name === "chart" && (
                          <ChartsDropDown
                            onChartSelect={(type) => {
                              type && setTool(tools["chart"][type]);
                            }}
                          />
                        )}
                        {thisTool.name !== "shape" && thisTool.name !== "chart" && (
                          <IconButton
                            className="mpen-icon-button"
                            disableRipple
                            disabled={thisTool.disabled}
                            style={{
                              opacity: thisTool.disabled ? 0.1 : 1,
                            }}
                            onClick={() => {
                              if (thisTool.name === "photo") {
                                handleImageUploadClick();
                              } else {
                                setTool(tools[thisTool.name]);
                              }
                            }}
                            sx={{
                              color: iconColor,
                            }}
                          >
                            {thisTool.icon}
                          </IconButton>
                        )}
                      </>
                    </Tooltip>
                  }

                  <Popover
                    open={isShapesPopoverOpen}
                    anchorEl={shapesButtonRef.current}
                    onClose={() => setShapesPopoverOpen(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    PaperProps={{
                      sx: {
                        boxShadow: "none",
                        marginTop: "25px",
                      },
                    }}
                  >
                    <Shapes close={() => setShapesPopoverOpen(null)} />
                  </Popover>
                </div>
              ),
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            width: "20px",
            height: "20px",
            background: stroke,
            borderRadius: "2px",
            border: "solid 1px black",
            marginRight: "-30px",
            marginTop: "10px",
          }}
          onClick={onStrokeClicked}
        >
          <Dialog open={showStrokePicker}>
            <MySketchPicker
              style={{
                top: "200px",
              }}
              setShowPicker={setShowStrokePicker}
              color={stroke}
              onChangeComplete={onStrokeChange}
            />
          </Dialog>
        </div>
        <div
          style={{
            width: "20px",
            height: "20px",
            background: fillBackground,
            borderRadius: "2px",
            border: "solid 1px black",
          }}
          onClick={onFillClicked}
        >
          <Dialog open={showFillPicker}>
            <MySketchPicker
              style={{
                top: "200px",
              }}
              setShowPicker={setShowFillPicker}
              color={fill}
              onChangeComplete={onFillChange}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
