// @ts-nocheck

import { ShapeInfo, Intersection } from "kld-intersections";
import { isPointOnArc } from "../utils/ellipseUtils";
import { distanceToLine, generateRandomColor, isSamePoint, rand } from "../utils/utils";
import RootSegment from "../tools/RootSegment";

class LineSegment extends RootSegment {
	constructor(type, p1, p2, opt = {}) {
		super();
		this.init(type, p1, p2, opt);
	}
	equals(seg) {
		if (this.type !== seg.type) return false;
		return (isSamePoint(this.p1, seg.p1) && isSamePoint(this.p2, seg.p2)) || (isSamePoint(this.p1, seg.p2) && isSamePoint(this.p2, seg.p1));
	}
	intersects(segment) {
		let points = [];
		const intersection = Intersection.intersect(this.kldSegment, segment.kldSegment);
		points = intersection.points.map((p) => ({ x: p.x, y: p.y }));

		if (segment.type == "arc") {
			points = points.filter((p) => isPointOnArc(p, segment)); // kld bug fix: take only points that lie on arc
		}
		return points;
	}
	makeKldSegment() {
		return ShapeInfo.line(this.p1.x, this.p1.y, this.p2.x, this.p2.y);
	}
	getPathData() {
		return `M${this.p1.x},${this.p1.y} L${this.p2.x},${this.p2.y}`;
	}
	isPointOnIt(pt, distanceEPS = 0.001) {
		return distanceToLine(pt, this) < distanceEPS;
	}
	trim(end, k) {
		// find a point on segment, at a distance of k from end
		if (end == 0) {
			this.p1 = findPointOnLineSegment(this.p1, this.p2, k);
		} else {
			this.p2 = findPointOnLineSegment(this.p2, this.p1, k);
		}
		return this;
	}
}
export default LineSegment;

function findPointOnLineSegment(p1, p2, k) {
	// find a point on line segment from p1 to p2, at a distance of k from p2 towards p1
	const dx = p1.x - p2.x;
	const dy = p1.y - p2.y;
	const length = Math.sqrt(dx * dx + dy * dy);
	const ratio = k / length;
	const x = p2.x + ratio * dx;
	const y = p2.y + ratio * dy;
	return { x, y };
}
