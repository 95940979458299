// @ts-nocheck
import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Cursor } from "./values/enums";
import axios from "axios";

import useFileManager from "./hooks/FileManager";
import History from "./hooks/History";
import { getBoundingBox } from "./utils";
import { copy, cut, paste } from "./utils/ClipboardUtils";
import { initSegments } from "./utils/SegmentUtils";
import { Group } from "./Group";
import { pathfind } from "./utils/PathfinderUtils";
import { BezierItem } from "./interfaces/BezierItem";
import { parseSVGRaw, svgPathToPoints } from "./svgparser/svgParser";

import { transform } from "./utils/transformUtils";
import { Commands } from "./Commands";
import { createMultiSelectBox } from "./utils/PointerUtils";
import ImageTracer from "imagetracerjs";
import { LABELS } from "./values/labels";
import { useAnimate } from "framer-motion";
import { arrange } from "./utils/ArrangeUtils";
import { useIdGenerator } from "./hooks/IdGenerator";
import { ROOT_GROUP_ID } from "./values/constants";
import { promises } from "dns";
import { deleteItems } from "./utils/DeleteUtils";
import Zoom from "./hooks/Zoom";
import { useGroupManager } from "./hooks/GroupManager";
import { useItemsManager } from "./hooks/ItemsManager";
import { snap, snapAngle, snapItem, snapMouse } from "./utils/SnapUtils";
import { getZoomLevel, rand } from "./utils/utils";
import { allGoogleFonts } from "./values/all-google-fonts";
import { createDesignServer, updateDesignServer } from "./utils/DesignUtils";
const parse = require("inline-style-parser");
const parseInlineCSS = parse;

const defaultSettings = {
  language: {
    values: ["English", "Spanish", "Tamil"],
    value: "English",
  },
  theme: {
    values: ["Light", "Dark"],
    value: "Light",
  },
};

const mainGroup = new Group(ROOT_GROUP_ID);

const SVGContext = createContext({});
export const SVGProvider = ({ children }) => {
  const [items, setItems] = useState({});
  const [groups, setGroups] = useState({ "group-root": mainGroup }); // FIXME, use constants
  const [defs, setDefs] = useState([]);

  const [selectedIds, setSelectedIdsLocal] = useState([]);

  const [selectedCorners, setSelectedCorners] = useState({});
  const [outline, setOutline] = useState(null);

  const [boundingBoxes, setBoundingBoxes] = useState([]);

  const [intersections, setIntersections] = useState([]);
  let [region, setRegion] = useState([]);
  let [grayRegion, setGrayRegion] = useState([]);
  const [multiSelectBox, setMultiSelectBox] = useState(null);

  let [lines, setLines] = useState([]);
  const [shouldSnap, setShouldSnap] = useState(true);

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(600);
  const [svgWidthPx, setSvgWidthPx] = useState(600);
  const [svgHeightPx, setSvgHeightPx] = useState(600);
  const [viewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    width: width,
    height: height,
  });

  const svgRef = React.useRef<SVGSVGElement>(null);

  const [tool, setToolInternal] = useState(null);
  const [cursor, setCursor] = useState(Cursor.Default);

  const [fill, setFill] = useState("transparent");
  const [stroke, setStroke] = useState("black");

  const [resizeRect, setResizeRect] = useState(null);

  const [openDialog, setOpenDialog] = useState(null);

  const [activeBezierPoint, setActiveBezierPoint] = useState(null);
  const [hoverBezierPoint, setHoverBezierPoint] = useState(null);
  const [hoverPoint, setHoverPoint] = useState(null);

  const [decorators, setDecorators] = useState([]);
  const [mouseReceivers, setMouseReceivers] = useState([]);
  const [svgDecorators, setSVGDecorators] = useState([]);

  const [rightPanel, setRightPanel] = React.useState(null);

  const [snapLines, setSnapLines] = useState([]);
  const [showGrid, setShowGrid] = useState(false);

  const [settings, setSettings] = useState(defaultSettings);
  const [scope, animate] = useAnimate();
  const [animationOpen, setAnimationOpen] = useState(false);
  const [animationSettings, setAnimationSettings] = useState({
    totalSeconds: 3,
    fps: 60,
  });
  const [isAnimationPlaying, setIsAnimationPlaying] = React.useState(false);
  const [backgroundStyle, setBackgroundStyle] = React.useState({});
  const [replacingIcon, setReplacingIcon] = React.useState(null);
  const [userInfo, setUserInfo] = React.useState(null);
  const [designId, setDesignId] = React.useState(null);

  const setSelectedIds = (indices) => {
    setSelectedIdsLocal(indices);
    createMultiSelectBox(items, indices, setMultiSelectBox, svgRef);
  };

  useEffect(() => {
    // if url has localhost
    if (window.location.href.includes("localhost")) {
      if (window.location.href.includes("loggedin")) {
        setUserInfo({
          name: "Yash",
          email: "yash@gmail.com",
        });
      } else {
        setUserInfo(null);
      }
    } else {
      const response = axios
        .get(`https://api.movingpencils.com/user_info`, { withCredentials: true })
        .then((response) => {
          if (response.data) {
            return response.data;
          } else {
            throw new Error("User not authenticated");
          }
        })
        .then((data) => {
          setUserInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching user info:", error);
        });
    }
  }, []);

  useEffect(() => {
    fileManager.load({ type: "jsonURLFromURLParams" }, init);
  }, []);

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const getHeaders = () => {
    return {
      Authorization: "Bearer specialsecrettoken",
    };
  };

  const loadRawSVG = async (svgString, opts) => {
    const mg = new Group(ROOT_GROUP_ID);

    const o = parseSVGRaw(svgString, mg, idGenerator.id);

    let width = parseFloat(o.svgElement.getAttribute("width")) || 500;
    let height = parseFloat(o.svgElement.getAttribute("height")) || 500;
    const viewBoxAttr = o.svgElement.getAttribute("viewBox");
    let viewbox;
    if (viewBoxAttr) {
      const [x, y, w, h] = viewBoxAttr.split(" ").map((x) => parseFloat(x));
      viewbox = { x, y, width: w, height: h };
      width = w;
      height = h;
    } else {
      viewbox = { x: 0, y: 0, width, height };
    }

    changeDimensions({ width, height, viewbox });

    let style = o.svgElement.getAttribute("style");
    if (style) {
      style = parseInlineCSS(style);
      const styleProps = {};
      for (let prop of style) {
        styleProps[prop.property] = prop.value;
      }
      if (styleProps.background) {
        // create an item, which is a rectangle with the background color
        const item = {
          id: "rect-" + rand(),
          type: "rect",
          x: 0,
          y: 0,
          width,
          height,
          fill: styleProps.background,
        };
        o.items[item.id] = item;
        o.groups[mg.id].children = [item.id, ...o.groups[mg.id].children];
      }
    }

    setItems({ ...o.items });
    setDefs([...o.defs]);
    setGroups({ ...o.groups });
    setSelectedIds([]);

    createDesignServer(
      {
        width,
        height,
        items,
        groups,
        defs,
        templateId: opts?.templateId,
        title: opts?.title,
      },
      getHeaders(),
      function (data) {
        console.log("Design created successfully!", data);
        setDesignId(data.design_id);
      },
    );
  };

  function saveDesign(cb) {
    updateDesignServer(
      designId,
      {
        width,
        height,
        items,
        groups,
        defs,
      },
      getHeaders(),
      cb,
    );
  }

  // when selectedIds is changed, multibox has to be updated
  let [debounceTimeout, setDebounceTimeout] = useState(null);
  useEffect(() => {
    if (selectedIds.length < 10) {
      // debounce
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        createMultiSelectBox(items, selectedIds, setMultiSelectBox, svgRef);
        const fills = Array.from(new Set(selectedIds.map((i) => items[i]?.fill)));
        const strokes = Array.from(new Set(selectedIds.map((i) => items[i]?.stroke)));
        if (fills.length == 1) setFill(fills[0]);
        if (strokes.length == 1) setStroke(strokes[0]);
      }, 50);
      setDebounceTimeout(debounceTimeout);
    }
  }, [selectedIds, items, groups]);

  const addToGroup = (item, group = mainGroup) => {
    item.group = group.id;
    groups[group.id].children.push(item.id);
    setGroups({ ...groups });
    return item;
  };
  const deleteFromGroup = (item) => {
    const group = groups[item.group];
    const idx = group.children.findIndex((x) => x == item.id);
    group.children.splice(idx, 1);
    groups[item.group] = group;
    setGroups({ ...groups });

    delete item.group;
    return item;
  };

  /********** MANAGERS ************/
  const idGenerator = useIdGenerator();
  const fileManager = useFileManager();
  const groupManager = useGroupManager({ selectedIds, items, setItems, groups, setGroups, addToGroup, deleteFromGroup, id: idGenerator.id });
  const zoom = Zoom({ multiSelectBox, svgWidthPx, svgHeightPx, setSvgWidthPx, setSvgHeightPx });
  const history = History({ setItems, setSelectedIds });
  const commands = new Commands({ selectedIds, items, setItems, history, animationOpenFn: () => animationOpen });
  const itemsManager = useItemsManager({ svgRef, items, setItems, mainGroup, groups, setGroups, setSelectedIds, history, addItems });

  const setTool = (nextTool) => {
    if (tool && tool.onToolUnselect) {
      tool.onToolUnselect(items, selectedIds);
    }

    tool && tool.decorateItem && removeDecorator(tool);
    tool && tool.decorateSvg && removeSvgDecorator(tool);

    setToolInternal(nextTool);
    if (nextTool && nextTool.onToolSelect) {
      nextTool.onToolSelect(items, selectedIds);
    }
    nextTool && nextTool.decorateItem && addDecorator(nextTool);
    nextTool && nextTool.decorateSvg && addSvgDecorator(nextTool);
  };

  const addDecorator = (decorator) => {
    if (decorator.name && !decorators.find((d) => d.name === decorator.name)) {
      setDecorators([...decorators, decorator]);
    }
  };

  const removeDecorator = (decorator) => {
    setDecorators(decorators.filter((d) => d.name !== decorator.name));
  };

  const addSvgDecorator = (decorator) => {
    if (decorator.name && !svgDecorators.find((d) => d.name === decorator.name)) {
      setSVGDecorators([...svgDecorators, decorator]);
    }
  };

  const removeSvgDecorator = (decorator) => {
    setSVGDecorators(svgDecorators.filter((d) => d.name !== decorator.name));
  };

  const addMouseReceiver = (mouseReceiver) => {
    if (mouseReceiver.name && !mouseReceivers.find((m) => m.name === mouseReceiver.name)) {
      setMouseReceivers([...mouseReceivers, mouseReceiver]);
    }
  };

  const removeMouseReceiver = (mouseReceiver) => {
    setMouseReceivers(mouseReceivers.filter((m) => m.name !== mouseReceiver.name));
  };

  /*********************************** saving & loading json, svg etc *******************/

  function init(args) {
    let mg = new Group("group-root");

    changeDimensions(args);
    args.items = args.items || {};
    for (let it of args.items) {
      it = initSegments(it);
      it.group = mg;
      mg.children.push(it.id);
    }
    setItems(args.items);
    setGroups({ "group-root": mg });
    setSelectedIds([]);
    history.setHistoryList([]);
  }

  function changeDimensions(args) {
    args.width = parseInt(("" + args.width).replace("px", "")) || 500;
    args.height = parseInt(("" + args.height).replace("px", "")) || 500;
    setWidth(args.width);
    setHeight(args.height);
    args.viewbox = args.viewbox || `0 0 ${args.width} ${args.height}`;
    setViewBox(args.viewbox);
  }

  function vectorizeSelected() {
    const promises = selectedIds
      .map((i) => items[i])
      .filter((it) => it.type == "image")
      .map((item) => new Promise((resolve, reject) => ImageTracer.imageToSVG(item.href, resolve, "detailed")));
    Promise.all(promises).then((results) => {
      const res = results.map((s) => parseSVGRaw(s, mainGroup, idGenerator.id));
      for (let r of res) {
        for (let it of r.items) {
          items[it.id] = it;
        }
        for (let d of r.defs) {
          defs.push(d);
        }
        for (let g in r.groups) {
          groups[g] = r.groups[g];
        }
      }
      deleteItems({ selectedIds, items, setGroups, groups, setItems, setSelectedIds, setMultiSelectBox });

      setItems({ ...items });
      setDefs([...defs]);
      setGroups({ ...groups });
    });
  }

  function vectorizeImage(imageData, group = mainGroup) {}
  function vectorize() {
    for (let i of selectedIds) {
      if (items[i].type == "image") {
        vectorizeImage(items[i].href, items[i].group);
      }
    }
  }

  /***************** items, groups, defs management ************************/

  function setThings(items, groups, ids, msg) {
    setItems({ ...items });
    setGroups({ ...groups });
    setSelectedIds([...ids]);
  }

  function modifyItem(item) {
    items[item.id] = item;
    setItems({ ...items });
  }

  function modifyItems(o) {
    deleteItems(o.delete);
    addItems(o.add, o.msg);
  }

  function addItems(its, msg = "add items") {
    for (let it of its) {
      items[it.id] = it;
      it.group = it.group || mainGroup.id;
      groups[it.group].children.push(it.id);
    }
    const ids = its.map((i) => i.id);
    setThings(items, groups, ids, msg);
  }

  function addItem(item, msg = "add item", group = mainGroup) {
    items[item.id] = item;
    item.group = group.id;
    if (!groups[group.id]) {
      groups[group.id] = group;
      groups[mainGroup.id].children.push(group.id);
    }
    groups[group.id].children.push(item.id);
    setThings(items, groups, [item.id], msg);

    history.addUndo(() => {
      delete items[item.id];
      deleteFromGroup(item);
      setItems({ ...items });
      setGroups({ ...groups });
      setSelectedIds([]);
    });
  }

  function deleteItems(ids, msg = "delete items") {
    for (let i of ids) {
      const item = items[i];
      if (!item.group) continue; // shapebuilder items
      const idx = groups[item.group].children.findIndex((x) => x == item.id);
      groups[item.group].children.splice(idx, 1);
    }
    for (let i of ids) {
      delete items[i];
    }
    for (let id in groups) {
      if (id != ROOT_GROUP_ID && groups[id].children.length == 0) {
        delete groups[id];
      }
    }

    setThings(items, groups, [], msg);
    return { items, groups };
  }

  function modifyDef(def) {
    const idx = defs.findIndex((d) => d.id === def.id);
    if (idx > -1) {
      defs[idx] = def;
      setDefs([...defs]);
    }
  }

  return (
    <SVGContext.Provider
      value={{
        items,
        setItems,

        mainGroup,
        groups,
        setGroups,

        width,
        height,
        svgRef,
        arrange: (type) =>
          arrange({
            type,
            selectedIds,
            items,
            setItems,
            groups,
            setGroups,
            setSelectedIds,
          }),
        intersections,
        setIntersections,
        region,
        setRegion,
        grayRegion,
        setGrayRegion,

        lines,
        setLines,

        multiSelectBox,
        setMultiSelectBox,

        history: history,
        undo: history.undo,
        redo: history.redo,
        addToHistory: history.addToHistory,
        historyList: history.historyList,

        zoomIn: (xy) => zoom.zoomIn(xy, viewBox, setViewBox, width, height),
        zoomOut: (xy) => zoom.zoomOut(xy, viewBox, setViewBox, width, height),
        viewBox: viewBox,
        setViewBox: setViewBox,
        handleWheel: zoom.handleWheel,

        init,
        tool,
        setTool,
        group: groupManager.group,
        ungroup: groupManager.ungroup,
        selectedIds,
        setSelectedIds,
        defs,
        setDefs,

        boundingBoxes,
        setBoundingBoxes,
        cursor,
        setCursor,

        // addItem: itemsManager.addItem,
        // addItems: itemsManager.addItems,
        addDValues: itemsManager.addDValues,

        fileManager: fileManager,

        fill,
        setFill,
        stroke,
        setStroke,

        cut: () => cut({ items, addItems, selectedIds, deleteItems }),
        copy: (asFormat = "json") =>
          copy({
            asFormat,
            items,
            setItems,
            selectedIds,
            setSelectedIds,
            svgRef,
            fileManager,
            width,
            height,
          }),
        paste: () =>
          paste({
            items,
            changeDimensions,
            addItems,
          }),

        resizeRect,
        setResizeRect,
        changeDimensions,

        openDialog,
        setOpenDialog,

        selectedCorners,
        setSelectedCorners,

        outline,
        setOutline,

        activeBezierPoint,
        setActiveBezierPoint,
        hoverBezierPoint,
        setHoverBezierPoint,

        pathfind: (arg) =>
          pathfind({
            type: arg,
            items,
            groups,
            selectedIds,
            svgRef,
            setSelectedIds,
            setItems,
            setGroups,
            setIntersections,
            deleteItems,
            setMultiSelectBox,
            idGenerator,
          }),

        commands,

        decorators,
        addDecorator,
        removeDecorator,
        mouseReceivers,
        addMouseReceiver,
        removeMouseReceiver,
        svgDecorators,
        addSvgDecorator,
        removeSvgDecorator,

        hoverPoint,
        setHoverPoint,

        label: (str) => {
          const labels = LABELS[settings.language.value];
          return (labels && labels[str]) || str;
        },
        settings,
        setSettings,

        scope,
        animate,
        animationOpen,
        setAnimationOpen,
        animationSettings,
        setAnimationSettings,
        isAnimationPlaying,
        setIsAnimationPlaying,

        idGenerator,
        id: idGenerator.id,

        addToGroup: addToGroup,
        deleteFromGroup: deleteFromGroup,

        vectorizeSelected,

        svgWidthPx,
        setSvgWidthPx,
        svgHeightPx,
        setSvgHeightPx,

        deleteItems,
        addItems,
        addItem,
        modifyItems,
        modifyItem,
        modifyDef,

        rightPanel,
        setRightPanel,

        snapLines,
        setSnapLines,
        shouldSnap,
        setShouldSnap,
        snapMouse: (xy) => {
          // shouldSnap && snapMouse({ xy, selectedIds, items, snapLines, setSnapLines });
        },
        snapAngle: (xy, pxy, center) => {
          snapAngle({ xy, pxy, center });
        },
        snapItem: ({ xy, items: its }) => shouldSnap && snapItem({ xy, items: its, selectedIds, snapLines, setSnapLines }),

        showGrid,
        setShowGrid,

        toSvgUnits: (px) => getZoomLevel(svgRef) * px,

        loadRawSVG,

        mousePos,
        setMousePos,
        backgroundStyle,
        setBackgroundStyle,

        replacingIcon,
        setReplacingIcon,

        userInfo,
        getHeaders,

        createDesignServer: (args, cb) => createDesignServer(args, getHeaders(), cb),
        saveDesign,
      }}
    >
      {children}
    </SVGContext.Provider>
  );
};

export const useSVG = () => useContext(SVGContext);
