// @ts-nocheck

import { angleAtB } from "./ShapeBuilderUtils";
import { onSameLine } from "./bezierOffsetingUtils";
import { transformPoint } from "./transformUtils";
import { dup, dupz, isSamePoint, ppt } from "./utils";

export function updateBezierPointPos(point, xy) {
  let { x: oldX, y: oldY } = point;
  let { x: newX, y: newY } = xy;
  let oh = point.handles;
  point.handles = [
    {
      x: newX + (oh[0].x - oldX),
      y: newY + (oh[0].y - oldY),
    },
    {
      x: newX + (oh[1].x - oldX),
      y: newY + (oh[1].y - oldY),
    },
  ];
  point.x = newX;
  point.y = newY;
  return point;
}

export function transformBezierPoint(pt, transformation) {
  if (!pt) return pt;
  // draw a line from center to this point, then move the point away on that line
  let ohandles = pt.handles;
  pt = transformPoint(pt, transformation);
  ohandles[0] = transformPoint(ohandles[0], transformation);
  ohandles[1] = transformPoint(ohandles[1], transformation);
  pt.handles = ohandles;
  return pt;
}

export const getBezierPath = (points, isClosed) => {
  let prevPoint,
    dd = "";

  if (isClosed) prevPoint = points[points.length - 1];
  points.forEach((point, i) => {
    // we set display=false when we don't show a point when it has a radius, and we show 2 other
    // points instead on either side of it
    if (point.handles == null || point.handles[0] == null || point.display == false) return;

    if (prevPoint != null) {
      if (!prevPoint.handles) {
        console.error("point without handles", prevPoint, i);
        prevPoint = point;
        return;
      }
      let a = prevPoint;
      let b = prevPoint.handles[1];
      let c = point.handles[0];
      let d = point;
      if (dd === "") {
        dd = `M ${a.x} ${a.y}`;
      }
      dd += `C ${b.x} ${b.y}, ${c.x} ${c.y}, ${d.x} ${d.y}`;
    }
    prevPoint = point;
  });
  if (isClosed) dd += "Z";
  return dd;
};
export function wasClickOnBezierPath(item, xy) {
  const path = document.querySelector(`path[id="${item.id}"]`);
  if (!path) return -1;

  // Check if the click is near the bezier path
  const pathLength = path.getTotalLength();
  let pointAfter = -1;
  for (let i = 0; i < pathLength; i += 1) {
    const point = path.getPointAtLength(i);
    for (let j = 0; j < item.points.length; j++) {
      if (isSamePoint(point, item.points[j], 3)) {
        // console.log("found point ", j);
        pointAfter = j;
      }
    }
    if (isSamePoint(xy, point, 3)) {
      // console.log("found point on line");
      if (pointAfter == -1) {
        console.error("CHECK THIS CASE -- TODO");
      }
      return { pointAfter, intersection: point };
    }
  }
  // console.log("not found");
  return { pointAfter: -1 };
}

function cubicBezierCurveCentroid(p0, p1, p2, p3) {
  // Calculate the centroid of a cubic Bézier curve
  const cx = (p0.x + p1.x + p2.x + p3.x) / 4;
  const cy = (p0.y + p1.y + p2.y + p3.y) / 4;
  return { x: cx, y: cy };
}

export function approximateCenterOfBezierCurve(item) {
  let totalCentroid = { x: 0, y: 0 };
  let totalCount = 0;

  for (let i = 0; i < item.points.length; i++) {
    let j = (i + 1) % item.points.length;
    let p = item.points;

    const centroid = cubicBezierCurveCentroid(p[i], p[i].handles[0], p[j].handles[1], p[j]);
    totalCentroid.x += centroid.x;
    totalCentroid.y += centroid.y;
    totalCount++;
  }

  // Average centroid
  return {
    x: totalCentroid.x / totalCount,
    y: totalCentroid.y / totalCount,
  };
}

// Function to calculate the derivative of a cubic Bezier curve
function cubicBezierDerivative(p0, p1, p2, p3, t) {
  // Calculate the derivative of the x-coordinate
  const dx = -3 * p0.x * Math.pow(1 - t, 2) + 3 * p1.x * (Math.pow(1 - t, 2) - 2 * t * (1 - t)) + 3 * p2.x * (2 * t * (1 - t) - t * t) + 3 * p3.x * Math.pow(t, 2);

  // Calculate the derivative of the y-coordinate
  const dy = -3 * p0.y * Math.pow(1 - t, 2) + 3 * p1.y * (Math.pow(1 - t, 2) - 2 * t * (1 - t)) + 3 * p2.y * (2 * t * (1 - t) - t * t) + 3 * p3.y * Math.pow(t, 2);

  return [dx, dy];
}
export function segmentTangentAngleAtT(s, t) {
  return tangentAngleAtT(s.p1, s.p1.handles[1], s.p2.handles[0], s.p2, t);
}
export function tangentAngleAtT(p0, p1, p2, p3, t) {
  // Calculate the derivative of the curve at parameter t
  const derivative = cubicBezierDerivative(p0, p1, p2, p3, t);

  // Calculate the angle between the tangent vector and the x-axis
  const tangentAngle = Math.atan2(derivative[1], derivative[0]);

  return tangentAngle;
}
export function normalAngleAtT(p0, p1, p2, p3, t) {
  return tangentAngleAtT(p0, p1, p2, p3, t) + Math.PI / 2;
}

export function segmentsToPoints(segments, isClosed) {
  if (segments.length == 0) return [];
  let points = [];
  for (let i = 0; i < segments.length; i++) {
    const s = segments[i],
      ps = segments[(i - 1 + segments.length) % segments.length];
    if (i == 0 && !isClosed) {
      points.push(dupz(segments[0].p1));
      continue;
    }
    if ((isSamePoint(s.p1, ps.p2), 0.1)) {
      let p = dup(s.p1);

      const h0 = (ps.p2.handles && ps.p2.handles[0]) || ps.p2;
      const h1 = (s.p1.handles && s.p1.handles[1]) || s.p1;
      p.handles = [dup(h0), dup(h1)];

      // angle from ps.p2 to ps.p1 vs s.p1 to s.p2
      let angle = angleAtB(ps.p1, s.p1, s.p2);
      if (Math.abs(angle - Math.PI) > 0.0001) {
        p.isCorner = true;
      }
      points.push(p);
    } else {
      console.log("ERROR: segments not continuous?");
      return points;
    }
  }
  if (!isClosed) {
    points.push(dupz(segments[segments.length - 1].p2));
  }
  return points;
}
