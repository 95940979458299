// @ts-nocheck

import { useSVG } from "../SVGContext";

export function useRightMenu() {
  const { smartGuidesEnabled, setSmartGuidesEnabled } = useSVG();

  return [
    {
      name: "Cut",
      action: "cut",
    },
    {
      name: "Copy",
      action: "copy",
    },
    {
      name: "Paste",
      action: "paste",
    },
    {
      name: "separator",
    },
    {
      name: "Arrange",
      children: [
        {
          name: "Bring to Front",
          action: "bringtofront",
        },
        {
          name: "Bring Forward",
          action: "bringforward",
        },
        {
          name: "Send Backward",
          action: "sendbackward",
        },
        {
          name: "Send to Back",
          action: "sendtoback",
        },
      ],
    },
    {
      name: "Flip",
      children: [
        {
          name: "Horizontally",
          action: "fliphorizontal",
        },
        {
          name: "Vertically",
          action: "flipvertical",
        },
      ],
    },
    {
      name: "Rotate",
      children: [
        {
          name: "Rotate 90°",
          action: "rotate90",
        },
        {
          name: "Rotate 180°",
          action: "rotate180",
        },
      ],
    },
  ];
}
