// @ts-nocheck

import { constructExtraPoints } from "./PathfinderUtils";
import { arrangeSegmentsForMergedPath } from "./SegmentUtils";
import { findClosestSegmentsV2, findSomeTranslatedSegment } from "./ShapeBuilderUtils";
import { dedupPoints, isSamePoint } from "./utils";

/*
preprocessing:
    - translate all segments of selected items & store translated_segments, each as its own node & tree
    - for each interesting point:
        - find enclosing segments
            - for each enclosing segment, find the translated segment the line intersects first
            - call union(find(prevTranslatedSegment), find(thisTranslatedSegment))
    - for each root node, arrange segments in a closed path

query:
    - given a translatedSegment, call find(translatedSegment).path
*/

class Node {
  parent;
  path;
  translatedSegment;
  segment;
  maxDepth;
  constructor(translatedSegment, segment) {
    this.translatedSegment = translatedSegment;
    this.segment = segment;
    this.maxDepth = 0;
  }
  equals(other) {
    return this.translatedSegment.id == other.translatedSegment.id;
  }
}

export class EnclosureFinder {
  constructor(tempSegments, translatedSegments, filterPointsInsideItems, sequence) {
    // init root nodes
    this.nodes = {};
    for (let t = 0; t < translatedSegments.length; t++) {
      const trans = translatedSegments[t],
        temp = tempSegments[parseInt(t / 2)];
      this.nodes[trans.id] = new Node(trans, temp);
    }

    // generate interesting points. for each interesting point, call union on its closest translated segments
    let points = [];
    for (let s of translatedSegments) {
      points = [...points, ...constructExtraPoints(s.p1), ...constructExtraPoints(s.p2)];
    }
    points = dedupPoints(points, (a, b) => isSamePoint(a, b, 1));

    const segments = [...translatedSegments, ...tempSegments];

    // call union on enclosing translated segments of each point
    for (let point of points) {
      const o = findClosestSegmentsV2(point, segments, (s) => s?.isTranslatedSegment);
      const closestSegments = o.closestSegments;
      if (closestSegments.length == 0) continue;
      // sequence.push({ type: "point", v: point })
      // sequence.push({ type: "segments", v: closestSegments })
      // sequence.push({ type: "lines", v: o.lines })

      // sequence.push({ type: "clear" })
      let root = this.find(this.nodes[closestSegments[0].id]);
      for (let i = 1; i < closestSegments.length; i++) {
        const closestSegment = closestSegments[i];

        root = this.union(this.find(this.nodes[closestSegment.id]), this.find(root));
      }
    }

    // do path compression on all nodes
    for (let i in this.nodes) this.find(this.nodes[i]);

    // for each root node, collect children and combine them into a single path
    for (let id in this.nodes) {
      const node = this.nodes[id];
      if (!node.parent) {
        let c = this.children(node).map((n) => n.segment);

        sequence.push({
          type: "segments",
          v: c,
        });
        sequence.push({
          type: "clear",
        });
        node.arranged = arrangeSegmentsForMergedPath(c)[0];
      }
    }
  }

  children(parent) {
    let res = [parent];
    for (let id in this.nodes) {
      if (this.nodes[id].parent?.equals(parent)) res.push(this.nodes[id]);
    }
    return res;
  }

  union(node1, node2) {
    if (!node2) return node1;

    const root1 = this.find(node1),
      root2 = this.find(node2);
    if (root1.equals(root2)) return root1;
    if (root1.maxDepth < root2.maxDepth) {
      root1.parent = root2;
      return root2;
    } else if (root1.maxDepth > root2.maxDepth) {
      root2.parent = root1;
      return root1;
    } else {
      root2.parent = root1;
      root1.maxDepth++;
      return root1;
    }
  }

  find(node) {
    if (!node) return null;
    if (!node.parent) return node;
    node.parent = this.find(node.parent);
    return node.parent;
  }

  findEnclosureRoot(xy, translatedSegments) {
    const t = findSomeTranslatedSegment(xy, translatedSegments);
    return this.find(this.nodes[t.id]);
  }
}
