// @ts-nocheck

import { SVG_ATTRIBUTES } from "../values/SvgAttributes";

const parse = require("inline-style-parser");
const parseInlineCSS = parse;

export function applyInlineStyles(obj, svgElement) {
  if (!svgElement.getAttribute("style")) return obj;
  const props = parseInlineCSS(svgElement.getAttribute("style"));
  for (let p of props) {
    obj[p.property] = p.value || obj[p.property]; // GOTCHA: precedence is given to inline styles
  }
  return renameKeysToCamelcase(obj);
}
export function read(src, defaults) {
  let result = {};
  for (let key in defaults) {
    const aliases = defaults[key].aliases || [key];
    for (let alias of aliases) {
      result[key] = result[key] || src.getAttribute(alias) || defaults[alias]?.value;
    }
  }
  return renameKeysToCamelcase(result);
}
export function toFloats(obj) {
  return obj;
  for (let key in obj) {
    const floated = parseFloat(obj[key]);
    if ("" + floated == obj[key]) {
      obj[key] = floated;
    }
  }
  return obj;
}

export function renameKeysToCamelcase(obj) {
  const valid = SVG_ATTRIBUTES;
  for (let key in obj) {
    if (!valid[key]) continue; // don't rename invalid keys

    const camelCasedKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

    if (key == "style") {
      obj[key] = stringStyleToJsonStyle(obj[key]);
    } else if (camelCasedKey !== key) {
      obj[camelCasedKey] = obj[key];
      delete obj[key];
    }
  }
  // rename class to className
  if (obj.class) {
    obj.className = obj.class;
    delete obj.class;
  }
  return obj;
}

export function stringStyleToJsonStyle(string) {
  if (typeof string !== "string") return string;

  // replace " with '
  string = string.replace(/"/g, "'");

  // replace ; with "; "
  string = string.replace(/;/g, "; ");

  let json = {};
  string.split(";").map((s) => {
    if (!s?.includes(":")) return;

    const [key, value] = s.split(":");
    json[key.trim()] = value.trim();
  });

  const keyValues = Object.keys(json).map((key) => {
    var camelCased = key.replace(/-[a-z]/g, (g) => g[1].toUpperCase());
    return { [camelCased]: json[key] };
  });
  return Object.assign({}, ...keyValues);
}
