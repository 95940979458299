// @ts-nocheck

import { useSVG } from "../SVGContext";

export function BottomBar() {
  const { setOpenDialog } = useSVG();

  const style = {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    padding: "10px 0",
    fontSize: "0.8em",
  };
  return (
    <div className="BottomBar" style={style}>
      <a href="/#" onClick={() => setOpenDialog("pricing")}>
        Pricing
      </a>
      <a href="/#" onClick={() => setOpenDialog("privacy")}>
        Privacy Policy
      </a>
      <a href="/#" onClick={() => setOpenDialog("contact")}>
        Contact Us
      </a>
    </div>
  );
}
