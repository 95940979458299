// @ts-nocheck

import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const BlackArrow = createSvgIcon(
    <svg viewBox="0 0 24 24" >
        <path d="M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71z" fill="currentColor" stroke="currentColor"></path>
    </svg>,
    "blackArrow"
);

export default BlackArrow;
