// @ts-nocheck

import { useSVG } from "./SVGContext";

function Zoom({ multiSelectBox, svgWidthPx, svgHeightPx, setSvgWidthPx, setSvgHeightPx }) {
  const scaleFactor = 1.1;

  function zoom(xy, zoomingIn, viewBox, setViewBox, width, height) {
    let x = width / 2,
      y = height / 2;

    if (multiSelectBox) {
      x = multiSelectBox.x;
      y = multiSelectBox.y;
    }
    if (xy) {
      x = xy.x;
      y = xy.y;
    }

    const mult = zoomingIn ? scaleFactor : 1 / scaleFactor;

    // if ((svgWidthPx <= maxWidth && svgHeightPx <= maxHeight && zoomingIn) || (svgWidthPx >= maxWidth && svgHeightPx >= maxHeight && !zoomingIn)) {
    // svgWidthPx *= mult;
    // svgHeightPx *= mult;
    // setSvgWidthPx(svgWidthPx);
    // setSvgHeightPx(svgHeightPx);
    // return;
    // }
    // console.log("outside");
    // return;

    const dx = (viewBox.width * (1 - mult)) / 2;
    const dy = (viewBox.height * (1 - mult)) / 2;

    setViewBox({ x: viewBox.x - dx, y: viewBox.y - dy, width: viewBox.width + 2 * dx, height: viewBox.height + 2 * dy });
  }
  function zoomIn(xy, viewBox, setViewBox, width, height) {
    zoom(xy, true, viewBox, setViewBox, width, height);
  }
  function zoomOut(xy, viewBox, setViewBox, width, height) {
    zoom(xy, false, viewBox, setViewBox, width, height);
  }

  return { zoomIn, zoomOut };
}
export default Zoom;
