// @ts-nocheck

import { useState } from "react";

function History({ setItems, setSelectedIds }) {
  let [historyList, setHistoryList] = useState([]);
  let [poppedList, setPoppedList] = useState([]);

  const addToHistory = (msg, o) => {
    historyList = [
      ...historyList,
      {
        items: o.items,
        msg: msg,
        selectedIndices: o.selectedIndices || [],
      },
    ];
    setHistoryList(historyList);

    poppedList = [];
    setPoppedList(poppedList);
  };
  const addUndo = (fn) => {
    historyList = [
      ...historyList,
      {
        fn: fn,
      },
    ];
    setHistoryList(historyList);
  };
  const undo = (items) => {
    if (historyList.length === 0) return;

    let newHistory = historyList;

    let popped = newHistory.pop();

    poppedList = [...poppedList, popped];
    setPoppedList(poppedList);

    historyList = [...newHistory];
    setHistoryList(historyList);

    if (popped.items) {
      const state = historyList[historyList.length - 1];
      items = { ...state.items };
      setItems(items);
      setSelectedIds(state.selectedIndices);
    } else if (popped.fn) {
      popped.fn();
    }
    return popped;
  };

  const redo = (items) => {
    if (poppedList.length === 0) return;

    let popped = poppedList.pop();
    setPoppedList([...poppedList]);

    let newHistory = [...historyList, popped];
    setHistoryList(newHistory);

    if (popped.items) {
      items = popped.items;
      items = { ...items };
      setItems(items);
    } else if (popped.fn) {
      popped.fn();
    }
    return popped;
  };
  return { historyList, addToHistory, setHistoryList, undo, redo, addUndo, getHistoryList: () => historyList };
}
export default History;
