// @ts-nocheck

import { Dialog } from "@mui/material";

export function TermsDialog({ type, open, onClose }) {
	const style = { padding: "0 20px 20px 20px" };
	return (
		<Dialog open={open} onClose={onClose}>
			<div style={{ background: "lightgray", padding: "15px", textAlign: "center", fontSize: "1.2em" }}>Moving Pencils - Free Online Vector Editor </div>
			<div style={style}>
				<div>
					<h3>Pricing:</h3>
					<div>Moving Pencils is a free tool. You can use it for free for as long as you want.</div>
				</div>
				<div>
					<h3>Privacy:</h3>
					<div>We do not create or store cookies. This is a static web page and there is no database that we use to store your data. You can use Moving Pencils completely offline. However, we do use Google Analytics and Crisp.</div>
				</div>
				<div>
					<h3>Contact:</h3>
					<div>I am Yaz, the creator of Moving Pencils. For feature requests, bug reports and press enquiries, or if you just want to say hi to me, please contact me at hello [at] movingpencils.com.</div>
				</div>
			</div>
		</Dialog>
	);
}
