// @ts-nocheck

import { rand } from "../utils/utils";

export class ImageItem {
  static type = "image";

  constructor({ id = "image-" + rand(), x, y, href, width, height, style }) {
    this.type = ImageItem.type;
    this.id = id;
    this.x = x;
    this.y = y;

    this.href = href;
    this.width = width;
    this.height = height;
    this.style = style;
    this.center = { x: x + width / 2, y: y + height / 2 };
  }
  clone() {}

  mpTransform(t) {
    if (t.type == "rotate") {
    } else if (t.type == "scale") {
    } else if (t.type == "translate") {
      this.x += t.x;
      this.y += t.y;
    }
    return this;
  }
}
