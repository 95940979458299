// @ts-nocheck
import { useSVG } from "../SVGContext";
import TextView from "./TextView";

const SVGItemView = ({ item }) => {
  if (!item || item.hidden) return null;

  const { items, setItems } = useSVG();

  if (item.type == "text") return <TextView item={item} />;

  let props = { ...item, mp_item_id: item.id };
  if (item.type == "rect") return <rect {...props} />;
  if (item.type == "circle") return <circle {...props} />;
  if (item.type == "ellipse") return <ellipse {...props} />;
  if (item.type == "polygon") return <polygon {...props} />;
  if (item.type == "polyline") return <polyline {...props} />;
  if (item.type == "line") return <line {...props} />;
  if (item.type == "path") return <path {...props} />;
  if (item.type == "image") {
    return <image {...props} />;
  }
  if (item.type == "svg") {
    return <svg {...props} dangerouslySetInnerHTML={{ __html: item.innerHTML }}></svg>;
  }
  if (item.type == "foreignObject") {
    if (item.ps && item.ps.length) {
      // this is our manually constructed object from svgparser.
      // when a foreign object has only <p> elements, we render them ourselves
      delete props.content;
      delete props.ps;

      return (
        <foreignObject {...props}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={{ width: "100%", height: "100%" }}>
            {item.ps.map((p, i) => (
              <p
                key={i}
                style={item.style}
                mp_item_id={item.id}
                contenteditable="true"
                onBlur={() => {
                  item.ps[i] = p;
                  items[item.id] = item;
                  setItems({ ...items });
                }}
              >
                {p}
              </p>
            ))}
          </div>
        </foreignObject>
      );
    } else if (item.imageSrc) {
      // See SvgParser for why we need this.
      // when there's an image in div in foreignObject, for some reason, the self-closing
      // <img> tag is not rendered. So we need to render it ourselves.
      delete props.content;
      delete props.imageSrc;
      delete props.imageDivStyle;
      return (
        <foreignObject {...props}>
          <div xmlns="http://www.w3.org/1999/xhtml" style={item.imageDivStyle}>
            <img
              style={{
                height: "100%",
                width: "100%",
              }}
              alt="logo"
              src={item.imageSrc}
            ></img>
          </div>
        </foreignObject>
      );
    } else {
      delete props.content;
      return <foreignObject {...props} dangerouslySetInnerHTML={{ __html: item.content }}></foreignObject>;
    }
  }
  if (item.type == "style") {
    return <style>{item.content}</style>;
  }
};

export default SVGItemView;
