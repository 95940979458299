// @ts-nocheck

import { BezierItem } from "../interfaces/BezierItem";
import { getBoundingBox } from "../utils";
import { initSegments } from "../utils/SegmentUtils";
import { transform } from "../utils/transformUtils";

export function useItemsManager({ svgRef, items, setItems, mainGroup, groups, setGroups, setSelectedIds, history, addItems }) {
  function updateSelectedItem({ item, idx, msg, deep = true }) {
    if (deep) {
      // item = initSegments(item);
      item.box = getBoundingBox(svgRef, item);

      if (!item.shapeType && item.box) {
        // if it's not a regular shape (what about small arcs?)
        // console.log("setting the box dims as item's center, width and height");
        item.center = {
          x: item.box.x + item.box.width / 2,
          y: item.box.y + item.box.height / 2,
        };
        item.width = item.box.width;
        item.height = item.box.height;
      }
    }

    let nItems = { ...items };
    nItems[idx] = item;
    setItems(nItems);
    msg && history.addToHistory(msg, { items: nItems });
  }

  const addDValues = (dValues) => {
    let newItems = [];
    for (let d of dValues) {
      let item = new BezierItem({ points: svgPathToPoints(d) });
      item = initSegments(item);
      item = transform(item, [{ type: "scale", x: 2, y: 2, center: item.center }]);
      newItems.push(item);
    }
    addItems(newItems);
  };

  const addItem = ({ item, shouldInitSegments = true }) => {
    item.group = mainGroup;
    if (shouldInitSegments) {
      item = initSegments(item);
    }
    item.box = getBoundingBox(svgRef, item);
    items[item.id] = item;
    item.group = mainGroup.id;
    setItems({ ...items });
    setSelectedIds([item.id]);

    groups[mainGroup.id].children.push(item.id);
    setGroups({ ...groups });

    return {
      items,
      item,
      selectedIds: [item.id],
    };
  };
  return { updateSelectedItem, addDValues, addItem };
}
