// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { Cursor, ShapeType } from "../values/enums";
import { initPointsForShape, initSegments } from "../utils/SegmentUtils";
import { BezierItem } from "../interfaces/BezierItem";
import { dupz } from "../utils/utils";
import { snapMouse } from "../utils/SnapUtils";

const SHAPES_WITH_WIDTH = [ShapeType.Rectangle, ShapeType.Circle, ShapeType.Polygon, ShapeType.Star];

function useShape(shapeType) {
  const { addItem, modifyItem, deleteItems, snapMouse } = useSVG();
  let { cursor, setCursor, setMultiSelectBox } = useSVG();
  let [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  let [currentShape, setCurrentShape] = useState(null);

  const onToolSelect = () => {
    cursor = Cursor.Crosshair;
    setCursor(cursor);
  };
  const onToolUnselect = () => {
    cursor = Cursor.Default;
    setCursor(cursor);
  };

  const init = () => {
    startPosition = { x: 0, y: 0 };
    setStartPosition(startPosition);
    currentShape = null;
    setCurrentShape(currentShape);
  };

  const onMouseDownNew = ({ xy, items, fill, stroke, z }) => {
    setMultiSelectBox(null);
    snapMouse(xy);

    startPosition = xy;
    setStartPosition(startPosition);

    let sides;
    if (shapeType == ShapeType.Polygon || shapeType == ShapeType.Star) sides = 6;
    if (shapeType == ShapeType.Rectangle) sides = 4;
    currentShape = {
      type: "shape",
      shapeType: shapeType,
      id: z.id("shape"),
      x: xy.x,
      y: xy.y,
      center: {
        x: xy.x,
        y: xy.y,
      },
      stroke: stroke,
      strokeWidth: 1,
      sides,
    };
    if (SHAPES_WITH_WIDTH.includes(shapeType)) {
      currentShape.width = 1;
      currentShape.height = 1;
      currentShape.isClosed = true;
      currentShape.fill = fill;
    }
    if (shapeType == ShapeType.Star) {
      currentShape.outerRadius = currentShape.width / 2;
      currentShape.innerRadius = currentShape.width / 4;
    }
    if (shapeType == ShapeType.Line) {
      currentShape.points = [dupz(startPosition), dupz(startPosition)];
    }
    currentShape = initSegments(initPointsForShape(currentShape));
    setCurrentShape(currentShape);

    addItem(currentShape);
  };

  const onMouseMoveNew = ({ event, xy, items }) => {
    snapMouse(xy);
    if (!currentShape) return;

    if (SHAPES_WITH_WIDTH.includes(currentShape.shapeType)) {
      let newWidth = Math.abs(startPosition.x - xy.x);
      let newHeight = Math.abs(startPosition.y - xy.y);
      if (event.shiftKey) {
        newWidth = newHeight = Math.min(newWidth, newHeight);
      }
      currentShape.width = newWidth;
      currentShape.height = newHeight;
      currentShape.center = {
        x: (xy.x < startPosition.x ? xy.x : startPosition.x) + currentShape.width / 2,
        y: (xy.y < startPosition.y ? xy.y : startPosition.y) + currentShape.height / 2,
      };

      if (shapeType == ShapeType.Star) {
        currentShape.outerRadius = newWidth / 2;
        currentShape.innerRadius = newWidth / 4;
      }
    }
    if (currentShape.shapeType == ShapeType.Line) {
      currentShape.points = [dupz(startPosition), dupz(xy)];
    }
    currentShape = initSegments(initPointsForShape(currentShape));
    setCurrentShape(currentShape);

    modifyItem(currentShape);
  };
  const onMouseUpNew = ({}) => {
    if (!currentShape) return;

    // if the shape is too small, delete it
    if (SHAPES_WITH_WIDTH.includes(currentShape.shapeType) && currentShape.width > 0 && currentShape.height > 0 && currentShape.width < 3 && currentShape.height < 3) {
      deleteItems([currentShape.id]);
      init();
      return;
    }

    let it = new BezierItem({
      points: currentShape.points,
      id: currentShape.id,
      isClosed: true,
      segments: currentShape.segments,
      source: currentShape,
    });

    // it.box = getBoundingBox(svgRef, it);
    for (let p of ["sides", "outerRadius", "innerRadius", "shapeType", "width", "height", "group"]) {
      it[p] = currentShape[p];
    }
    modifyItem(it);

    init();
  };
  const onKeyDown = (e) => {
    if (e.key === "Escape") {
      init();
    }
  };

  return {
    onMouseDownNew,
    onMouseUpNew,
    onMouseMoveNew,
    onKeyDown,
    onToolSelect,
    onToolUnselect,
    name: [ShapeType.Rectangle, ShapeType.Circle, ShapeType.Polygon, ShapeType.Star].includes(shapeType) ? "shape" : "line",
  };
}

export default useShape;
