// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { initSegments } from "../utils/SegmentUtils";
import { pathOnMouseDown, pathOnMouseMove, pathOnMouseUp } from "./PathDrawingUtils";

function useBrush() {
  const { addItem, modifyItem } = useSVG();
  let [path, setPath] = useState(null);

  const onMouseDownNew = ({ xy }) => {
    pathOnMouseDown({
      xy,
      path,
      setPath: (p) => {
        addItem(initSegments(p));
        path = p;
        setPath(p);
      },
    });
  };

  const onMouseMoveNew = ({ xy }) => {
    pathOnMouseMove({
      xy,
      path,
      setPath: (p) => {
        modifyItem(initSegments(p));
        path = p;
        setPath(path);
      },
    });
  };

  const onMouseUpNew = ({ xy }) => {
    pathOnMouseUp({
      xy,
      path,
      setPath: (p) => {
        path = null;
        setPath(path);
      },
    });
  };

  return { onMouseDownNew, onMouseUpNew, onMouseMoveNew, name: "brush" };
}

export default useBrush;
