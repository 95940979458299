// @ts-nocheck

import { ShapeInfo, Intersection } from "kld-intersections";
import { SWEEP } from "../values/enums";
import { isSamePoint, pointsToPath } from "../utils";
import { findAngleAtPointInEllipse, findPointAtAngleInEllipse, isPointOnArc } from "../utils/ellipseUtils";
import { distanceToLine, generateRandomColor, isEqual, ppt, rand, sq } from "../utils/utils";
import { intersectEE, rotateVector } from "../utils/intersectEE";

class RootSegment {
  init(type, p1, p2, opt = {}) {
    this.type = type;
    if (!p1) return; // for separators
    this.p1 = { x: p1.x, y: p1.y };
    this.p2 = { x: p2.x, y: p2.y };
    this.kldSegment = this.makeKldSegment(); // also sets radiusX, radiusY, startRadians, endRadians, largeArcFlag, sweepFlag if it's an elliptic arc
    this.id = rand() + "-" + new Date().getTime();
    this.stroke = opt.stroke || generateRandomColor();
    this.center = opt.center;
  }
  update() {
    this.kldSegment = this.makeKldSegment(); // also sets radiusX, radiusY, startRadians, endRadians, largeArcFlag, sweepFlag if it's an elliptic arc
    return this;
  }
  isSeparator() {
    return false;
  }
  equals(seg) {
    console.error("not implemented");
  }
  intersects(segment) {
    const intersection = Intersection.intersect(this.kldSegment, segment.kldSegment);
    let points = intersection.points.map((p) => ({ x: p.x, y: p.y }));

    if (segment.type == "arc") {
      points = points.filter((p) => isPointOnArc(p, segment)); // kld bug fix: take only points that lie on arc
    }
    return points;
  }
  intersectsKLDLine(kldLine) {
    return Intersection.intersect(this.kldSegment, kldLine).points;
  }
  intersectsKLDPath(kldPath) {
    return Intersection.intersect(this.kldSegment, kldPath).points;
  }
  makeKldSegment() {
    console.error("not implemented");
  }
  getPathData() {
    console.error("not implemented");
  }
  isPointOnIt(pt, distanceEPS = 0.001) {
    console.error("not implemented");

    return false;
  }
  trim(end, k) {
    console.error("not implemented");
    return this;
  }

  // str() {
  //   return `${Math.round(this.p1.x)}, ${Math.round(this.p1.y)} -> ${Math.round(this.p2.x)}, ${Math.round(this.p2.y)} (${this.id})`;
  // }

  style() {
    return `background-color: ${this.stroke}; color: white; padding: 4px;`;
  }
}
export default RootSegment;
