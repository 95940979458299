// @ts-nocheck

import * as React from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Arrow from "@mui/icons-material/ArrowForwardIos";

const arrowStyle = {
	fontSize: 10,
};
function FinalMenuItem({ item }) {
	if (item.name == "separator") {
		return (
			<div
				style={{
					borderBottom: "1px solid lightgray",
					margin: "10px 0 0 0px",
					width: "100%",
				}}
			/>
		);
	}
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				gap: 10,
				width: "100%",

				margin: "10px 10px 0 15px",
			}}
		>
			<span>{item.name}</span>
			<span className="subtitle">{item.subtitle}</span>
			<span>{item.children && <Arrow style={arrowStyle} /> }</span>
		</div>
	);
}

export default function MenuListComposition({ items, onMenuClicked, open, setOpen, anchorEl, onMouseOver = () => {}, onMouseOut = () => {}, place = "bottom-start" }) {
	const handleClose = (event: Event | React.SyntheticEvent, action) => {
		// if (anchorEl && anchorEl.contains(event.target as HTMLElement)) {
		// 	return;
		// }

		setOpen(false);

		if (action) {
			onMenuClicked(action);
		}
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === "Escape") {
			setOpen(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	// const prevOpen = React.useRef(open);
	// React.useEffect(
	// 	() => {
	// 		if (prevOpen.current === true && open === false) {
	// 			anchorEl!.focus();
	// 		}

	// 		prevOpen.current = open;
	// 	},
	// 	[open]
	// );
	return (
		<Stack direction="row" spacing={2}>
			<div>
				<Popper open={open} anchorEl={anchorEl} role={undefined} placement={place} transition disablePortal>
					{({ TransitionProps, placement }) => (
						<Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom-start" ? "center top" : "left bottom" }} timeout={20}>
							<Paper square elevation={0} style={{ border: "1px solid lightgray" }}>
								<ClickAwayListener onClickAway={handleClose}>
									<MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
										{items.map((item, idx) => (
											<MenuItem key={idx} onClick={(e) => handleClose(e, item.action)} onMouseOver={(e) => onMouseOver(e, item)} onMouseOut={(e) => onMouseOut(e, item)} disabled={Boolean(!item.children && !item.action)} style={{ padding: 0 }}>
												<FinalMenuItem item={item} />
											</MenuItem>
										))}
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		</Stack>
	);
}
