// @ts-nocheck

import { Group } from "../Group";
import { BezierItem } from "../interfaces/BezierItem";
import { computePolygonPoints, initSegments, initSegmentsEllipse } from "../utils/SegmentUtils";
import { isSamePoint, rand } from "../utils";
import { parseDefs } from "./parseDefs";
import { arcToBezier } from "../utils/ArcToBezierUtils";
import { LinearGradient } from "./elements/LinearGradient";
import { COPY_PROPS } from "../values/constants";
import { applyInlineStyles, toFloats } from "./ParserUtils";
import { groupCollapsed } from "console";
import { TextItem } from "../interfaces/TextItem";
import { ImageItem } from "../interfaces/ImageItem";
import { PathItem } from "../interfaces/PathItem";
import { getNextPoint } from "./elements/getNextPoint";
import { Point } from "./elements/Point";
import { parseRect } from "./elements/Rectangle";
import { parseCircle, parseEllipse } from "./elements/Ellipse";
import { parsePolygonPoints } from "./elements/Points";
import { parseLine } from "./elements/Line";
import { parseText } from "./elements/Text";
import { ShapeItem } from "../interfaces/ShapeItem";
import { parseImage } from "./elements/Image";
import { addFontDef, camel } from "../utils/utils";
import { allGoogleFonts } from "../values/all-google-fonts";
const { parseSVG } = require("svg-path-parser");

const parse = require("inline-style-parser");
const parseInlineCSS = parse;

function reflect(pt, handle) {
  return new Point(2 * pt.x - handle.x, 2 * pt.y - handle.y);
}
function dup(pt) {
  return new Point(pt.x, pt.y);
}

/**********************************************************/

/**********************************************************/

function parseChildren(svgElement, mainGroupId, groups = {}, idGenerator) {
  const childElements = svgElement.children;
  let items = {},
    defs = [];

  function finalizeItem(it, child) {
    const attrs = child.getAttributeNames();
    for (let prop of attrs) {
      it[prop] = child.getAttribute(prop);
    }

    it = toFloats(applyInlineStyles(it, child));
    it.cssClasses = child.getAttribute("class") ? child.getAttribute("class").split(" ") : [];
    it.group = mainGroupId;
    items[it.id] = it;
    groups[mainGroupId].children.push(it.id);
  }

  for (let i = 0; i < childElements.length; i++) {
    const child = childElements[i];
    const itemType = child.tagName.toLowerCase();
    let item;
    if (itemType == "svg") {
      item = {
        type: "svg",
        id: idGenerator(itemType),
        innerHTML: child.innerHTML,
      };
      item.group = mainGroupId;
      groups[mainGroupId].children.push(item.id);

      // note: not parsing the children, they won't be recognized as items, they won't be editable
      item = finalizeItem(item, child);
      items = { ...items, item };
      // defs = [...defs]; // what if there are new fonts in this svg? since i'm rendering its innerHTML, it will work. but using this editor, it cannot be changed.
      // groups = { ...groups };
    } else if (itemType == "g") {
      let group = new Group("group-" + new Date().getTime() + "-" + rand());
      group.group = mainGroupId;

      groups[mainGroupId].children.push(group.id);
      groups[group.id] = group;

      // attach every attr to group
      for (let attr of child.attributes) {
        if (attr.name !== "id") {
          group[attr.name] = attr.value;
        }
      }
      group = toFloats(applyInlineStyles(group, child));
      let o = parseChildren(child, group.id, groups, idGenerator);
      if (!o) return;
      items = { ...items, ...o.items };
      defs = [...defs, ...o.defs];
      groups = { ...groups, ...o.groups };
    } else if (itemType == "path") {
      // let points = svgPathToPoints(svgElement.getAttribute("d"));
      // later, bezier item was being created
      item = {
        id: child.getAttribute("id") || idGenerator(itemType),
        type: "path",
        d: child.getAttribute("d"),
      };
      finalizeItem(item, child);
    } else if (itemType == "foreignobject") {
      let content = child.innerHTML;
      let xmlParser = new DOMParser();
      let doc = xmlParser.parseFromString(content, "image/svg+xml");
      let children,
        allP = true,
        pStyle;
      try {
        // TODO: remove this once this logic moves to parser.
        // basically I'm taking the first <p> style and applying it to the foreign object
        children = doc.childNodes[0].childNodes;
        for (let c of children) {
          if (c.tagName !== "p") {
            allP = false;
            break;
          } else if (!pStyle) {
            pStyle = styleToObject(c.getAttribute("style"));
          }
        }
      } catch (e) {
        console.log("Error parsing foreign object", e);
      }

      item = {
        id: child.getAttribute("id") || idGenerator(itemType),
        type: "foreignObject",
      };
      if (children && allP) {
        item.ps = Array.from(children).map((c) => c.textContent);

        item.style = pStyle;

        // if it's a google font, add it to defs
        if (item.style?.fontFamily && allGoogleFonts[item.style?.fontFamily]) {
          defs = addFontDef(item.style?.fontFamily, defs);
        }
      } else {
        // If it's an image from template, download and inline it
        // let foundStuff = false;
        // const fdoc = new DOMParser().parseFromString(content, "image/svg+xml");
        // if (fdoc.getElementsByTagName("div").length == 1) {
        //   const div = fdoc.getElementsByTagName("div")[0];
        //   const img = div.getElementsByTagName("img");
        //   if (img.length == 1) {
        //     item.imageDivStyle = styleToObject(div.getAttribute("style"));
        //     item.imageSrc = img[0].getAttribute("src");
        //     // fetch this image
        //   }
        // }
        // if (!foundStuff) {

        // }
        item.content = content;
      }

      finalizeItem(item, child);
    } else if (itemType == "style") {
      // just add it as item?
      item = {
        id: child.getAttribute("id") || idGenerator(itemType),
        type: "style",
        content: child.innerHTML,
      };
      finalizeItem(item, child);
      items[item.id] = item;
      item.group = mainGroupId;
      groups[mainGroupId].children.push(item.id);
    } else if (["rect", "ellipse", "circle", "line", "polyline", "polygon"].includes(itemType)) {
      item = new ShapeItem({
        id: child.getAttribute("id") || idGenerator(itemType),
        type: itemType,
        content: child.innerHTML,
      });
      finalizeItem(item, child);
    } else if (itemType == "defs") {
      defs = [...defs, ...parseDefs(child)];
    } else if (itemType == "lineargradient") {
      // linear gradient, but it is outside defs??
      const linearGradient = LinearGradient.from_svg(child);
      if (linearGradient) {
        defs.push(linearGradient);
      }
    } else if (itemType == "text") {
      item = parseText(child);
      finalizeItem(item, child);
    } else if (itemType == "image") {
      item = parseImage(child);
      finalizeItem(item, child);
    } else {
      console.error("SVG parsing not yet implemented for ", itemType);
    }
  }
  return { items, defs, groups };
}

function emptyResult(SVGElement, mainGroup) {
  return { items: {}, defs: [], groups: {}, mainGroupId: mainGroup.id, SVGElement };
}

export function parseSVGRaw(svgText, mainGroup, idGenerator) {
  const xmlDoc = new DOMParser().parseFromString(svgText, "image/svg+xml");
  const svgElement = xmlDoc.getElementsByTagName("svg")[0]; // FIXME: handles only one svg element

  if (!svgElement) {
    console.error("No SVG element found in", xmlDoc);
    return emptyResult(svgElement, mainGroup);
  }
  let initialGroups = {};
  mainGroup = mainGroup || new Group("group-" + new Date().getTime());
  initialGroups[mainGroup.id] = mainGroup;
  const o = parseChildren(svgElement, mainGroup.id, initialGroups, idGenerator);
  if (!o) {
    console.error("Error parsing children");
    return emptyResult(svgElement, mainGroup);
  }
  let { items, groups, defs } = o;
  // after parsing everything, go over items, and see if any has gradient or pattern fills.
  // If so, attach them directly
  // for (let id in items) {
  //   const item = items[id];
  //   if (item.fill && item.fill.startsWith("url(")) {
  //     // should match url('#id') or url(#id) or url("#id")
  //     const fillId = item.fill.match(/url\(['"]?#(.+?)['"]?\)/)[1];
  //     const fill = defs.find((x) => x.id == fillId);
  //     if (fill) {
  //       item.gradient = fill;
  //       // delete from defs
  //       defs = defs.filter((x) => x.id != fillId);
  //     }
  //   }
  // }

  const styles = xmlDoc.getElementsByTagName("style");
  // Array.from(styles).map((x) => x.outerHTML).join("\n")
  return { items, defs, groups, mainGroupId: mainGroup.id, svgElement, styles };
}

function styleToObject(attr) {
  let parsedList = parseInlineCSS(attr);
  let ss = {};
  for (let p of parsedList) {
    ss[camel(p.property)] = p.value;
  }
  return ss;
}
