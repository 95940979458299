// @ts-nocheck
import { Box, ClickAwayListener, IconButton, Menu, MenuItem, Popper, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { transform } from "../utils/transformUtils";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";

export const TopbarShape = ({ item }) => {
  useEffect(() => {}, [item]);

  if (!item) return;
  let { items, svgRef, setItems, selectedIds } = useSVG();

  let props = [
    { name: "width", min: 1, max: 500, step: 1 },
    { name: "height", min: 1, max: 500, step: 1 },
    { name: "angle", min: 0, max: 360, step: 1 },
  ];
  if (item.sides) {
    props.push({ name: "sides", min: 3, max: 20, step: 1 });
  }
  if (item.innerRadius) {
    props.push({ name: "innerRadius", min: 1, max: 500, step: 1 });
    props.push({ name: "outerRadius", min: 1, max: 500, step: 1 });
  }
  props = props.filter((prop) => item[prop.name] !== undefined);

  const getValue = (item, prop) => {
    if (prop.name == "angle") {
      let angle = item.rotation ? item.rotation.angle : 0;
      return Math.round(toDegree(angle));
    }
    return item[prop.name];
  };
  return (
    <>
      <FillColor item={item} />
      <FillColor item={item} isStroke={true} />
      <StrokeWidth item={item} />
      <Dash item={item} />

      {props.map((prop, pidx) => (
        <span key={pidx}>
          <TextField
            label={prop.name[0].toUpperCase() + prop.name.substr(1)}
            type="number"
            size="small"
            style={{ width: "80px" }}
            defaultValue={getValue(item, prop)}
            onChange={(e) => {
              let t = {};
              if (prop.name == "angle") {
                t.type = "rotate";
                t.angle = toRadians(parseFloat(e.target.value));
              } else if (prop.name == "width") {
                t.type = "scale";
                t.x = parseFloat(e.target.value) / item.width;
                t.y = 1;
              } else if (prop.name == "height") {
                t.type = "scale";
                t.x = 1;
                t.y = parseFloat(e.target.value) / item.height;
              } else if (["sides", "innerRadius", "outerRadius"].includes(prop.name)) {
                // TODO: fixme
                item[prop.name] = parseInt(e.target.value);
                item = initSegments(initPointsForShape(item));
              }
              if (t.type) {
                t.center = item.center;
                item = transform(item, t);
              }
              items[item.id] = item;
              setItems({ ...items });
            }}
          />
        </span>
      ))}
    </>
  );
};
function FillColor({ item, isStroke }) {
  const { items, setItems } = useSVG();
  return (
    <ColorSquare
      isStroke={isStroke}
      color={isStroke ? item.stroke : item.fill}
      onChange={(color) => {
        if (isStroke) item.stroke = color.hex;
        else item.fill = color.hex;
        items[item.id] = item;
        setItems({ ...items });
      }}
    />
  );
}

function StrokeWidth({ item }) {
  const { items, setItems } = useSVG();
  const [anchorEl, setAnchor] = useState(null);
  return (
    <>
      <IconButton onClick={(e) => setAnchor(e.currentTarget)}>
        <ShowChartIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem>
          <Slider
            style={{ width: "100px" }}
            value={item.strokeWidth}
            onChange={(e) => {
              item.strokeWidth = e.target.value;
              items[item.id] = item;
              setItems({ ...items });
            }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

const popperStyle = {
  backgroundColor: "white",
  border: "solid 1x gray",
};
const options = [[], [5], [2], [5, 10]];

function Line({ dashArray }) {
  return (
    <svg width="40px" height="30" className="dasharray-svg" style={{ paddingTop: 10, paddingBottom: 10 }}>
      <line x1="0" y1="5" x2="100" y2="5" stroke="black" stroke-width="2" stroke-dasharray={dashArray.join(", ")} />
    </svg>
  );
}
function DashPopper({ anchorEl, onClose, onClick }) {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose} style={popperStyle}>
        <Box sx={{ border: 1, p: 1, bgcolor: "background.paper" }}>
          {options.map((option, index) => {
            return (
              <div key={index} onClick={() => onClick(index)}>
                <Line dashArray={option} />
              </div>
            );
          })}
        </Box>
      </Popper>
    </ClickAwayListener>
  );
}
export function Dash({ item }) {
  const { items, setItems } = useSVG();
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = useRef(null);

  function onClick(index) {
    // this is to make the dash-change obvious to the user
    item.strokeWidth = item.strokeWidth || 2;
    item.stroke = item.stroke || "black";

    items[item.id].strokeDasharray = options[index].join(", ");
    setItems({ ...items });

    setAnchorEl(null);
  }

  const currentValue = item.strokeDasharray?.split(", ") || [];
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", gap: 3, justifyContent: "start", alignItems: "start" }}>
        <IconButton
          ref={ref}
          type="button"
          value="Dashed Line"
          onClick={() => {
            setAnchorEl(anchorEl ? null : ref.current);
          }}
        >
          <Line dashArray={currentValue} />
        </IconButton>
        {/* <input
          type="text"
          style={{ width: "100%" }}
          value={currentValue.join(" ")}
          onChange={(e) => {
            items[item.id].strokeDasharray = e.target.value;
            setItems({ ...items });
          }}
        /> */}
      </div>
      {anchorEl && <DashPopper anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onClick={onClick} />}
    </div>
  );
}
