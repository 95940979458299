// @ts-nocheck

import { COPY_PROPS, constants } from "../values/constants";
import BezierSegment from "../segments/BezierSegment";
import { SeperatorSegment } from "../segments/SeperatorSegment";
import { initSegments } from "../utils/SegmentUtils";
import { transformBezierPoint } from "../utils/bezierUtils";
import { rand } from "../utils/utils";

export class BezierItem {
  type;
  id;
  points;
  rawPoints;
  center;
  isClosed;
  static COPY_FIELDS = [
    // bezier
    "points",
    "rawPoints",
    "isClosed",
    "center",

    // shape
    "width",
    "height",
    "sides",
    "innerRadius",
    "outerRadius",
    "shapeType",

    // display fields should be taken from constants.COPY_PROPS

    // brush
    "smoothness",

    // when corner radius is used
    "display",

    "segments", // should be cloned no?
  ];

  constructor({ points, rawPoints, id, isClosed = false, segments = [], source = {} }) {
    this.type = BezierItem.type;
    this.id = id || rand() + "-" + new Date().getTime();
    this.points = points;
    this.rawPoints = rawPoints;
    this.isClosed = isClosed;
    this.center = this.getCenter();
    this.label = "Path";

    for (let prop of COPY_PROPS) {
      this[prop] = source[prop];
    }
    if (this.gradient) {
      this.gradient = { ...this.gradient }; // clone it
      this.gradient.id = "gradient-" + this.id;
      this.fill = "url(#" + this.gradient.id + ")";
    }
    this.segments = segments;
  }

  clone() {
    const it = new BezierItem({ points: this.points, rawPoints: this.rawPoints, isClosed: this.isClosed });
    for (let k of BezierItem.COPY_FIELDS) {
      it[k] = this[k];
    }
    for (let k of COPY_PROPS) {
      it[k] = this[k];
    }
    it.segments = [];
    for (let seg of this.segments) {
      it.segments.push(seg.clone());
    }
    return it;
  }

  mpTransform(transformation) {
    for (let i = 0; i < this.points.length; i++) {
      const oldPoint = this.points[i];
      this.points[i] = transformBezierPoint(this.points[i], transformation);
      for (let k in oldPoint) {
        if (k != "x" && k != "y" && k != "handles") {
          this.points[i][k] = oldPoint[k];
        }
      }
    }
    return this.update({ points: this.points });
  }

  update({ points }) {
    this.points = points;
    this.center = this.getCenter();
    this.segments = initSegments(this).segments;
    return this;
  }

  getCenter() {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (let i = 0; i < this.points.length; i++) {
      let point = this.points[i];
      if (!point) continue;
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    }
    return { x: minX + (maxX - minX) / 2, y: minY + (maxY - minY) / 2 };
  }
  static type = "bezierPath";
}
