// @ts-nocheck

import FileMenu from "./FileMenu";
import { useSVG } from "./SVGContext";
import Toolbar from "./Toolbar";
import TopBar from "./";
import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SVGContainer from "./SVGContainer";

import Text from "./tools/useText";
import Brush from "./tools/useBrush";
import Pointer from "./tools/usePointer";
import Shape from "./tools/useShape";
import Pan from "./tools/usePan";
import { ChartType, ShapeType } from "./values/enums";
import useEyedropper from "./tools/useEyedropper";
import useResizeCanvas from "./tools/useResizeCanvas";
import { TermsDialog } from "./dialogs/TermsDialog";
import { SettingsDialog } from "./dialogs/SettingsDialog";
import ShortcutHandler from "./hooks/ShortcutHandler";
import { BottomBar } from "./components/BottomBar";
import { FileUploader } from "./hooks/FileUploader";
import { GoogleFontFamilies } from "./values/GoogleFontFamilies";
import { constants } from "./values/constants";
import useChart from "./tools/useChart";
import { Topbar } from "./Topbar";
import RightPanel from "./RightPanel";
import LoggedoutTemplates, { LoggedOutTemplates } from "../home/LoggedOutTemplates";

function Editor() {
  const { userInfo } = useSVG();
  const { settings, setTool, fileManager, items, setItems, groups, selectedIds, svgRef } = useSVG();
  const provider = useSVG();
  const { openDialog, setOpenDialog } = useSVG();
  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);
  const [isNewFileDialogOpen, setIsNewFileDialogOpen] = useState(false);
  const appRef = useRef(null);

  let [width, setWidth] = useState(500);
  let [height, setHeight] = useState(500);

  // const [fontsUrl, setFontsUrl] = useState("");

  const fileRef = useRef(null);
  const imageRef = useRef(null);
  const startFileUpload = (type) => {
    const ref = type === "place" ? imageRef : fileRef;
    ref.current.value = ""; // Without this, clicking it 2nd time doesn't work
    ref.current.click();
  };

  const tools = {
    text: Text(provider, "text"),
    textPath: Text(provider, "textPath"),
    brush: Brush(provider),
    pointer: Pointer(provider),
    pan: Pan(provider),
    resizeCanvas: useResizeCanvas(provider),
    chart: {
      pie: useChart(ChartType.pie, provider),
      column: useChart(ChartType.column, provider),
      bar: useChart(ChartType.bar, provider),
    },
    shape: {
      rectangle: Shape(ShapeType.Rectangle, provider),
      ellipse: Shape(ShapeType.Circle, provider),
      polygon: Shape(ShapeType.Polygon, provider),
      star: Shape(ShapeType.Star, provider),
      line: Shape(ShapeType.Line, provider),
    },
  };
  useEffect(() => {
    setTool(tools.pointer);

    // // https://developers.google.com/fonts/docs/getting_started - you can't set a different "text" value
    // // for different font families, using a single <link> element
    // const text = constants.SAMPLE_TEXT.replaceAll(" ", "%20");
    // const fams = GoogleFontFamilies.map((font) => `family=${font.replaceAll(" ", "+")}&text=${text}`);
    // const url = `https://fonts.googleapis.com/css2?${fams.join("&")}`;
    // // console.log(url);
    // setFontsUrl(url);
    // // add this as a link
  }, []);

  const shortcutHandler = ShortcutHandler({ tools, startFileUpload });

  const onNewFileClicked = () => {
    if (items.length === 0) {
      setIsNewFileDialogOpen(true);
    } else {
      setIsSaveDialogOpen(true);
    }
  };

  const handleDialogClose = (p) => {
    setIsSaveDialogOpen(false);
    if (p == null) return;
    if (p) fileManager.save({ type: "svg", svgRef });
    else setIsNewFileDialogOpen(true);
  };

  if (!userInfo) return <LoggedoutTemplates />;
  return (
    <>
      {/* <link rel="stylesheet" href={fontsUrl} /> */}
      <div ref={appRef} data-theme={settings.theme.value} tabIndex="-1" className="App" onKeyDown={(event) => shortcutHandler.handleKeyDown({ event, items, groups, selectedIds })} onKeyUp={shortcutHandler.handleKeyUp}>
        {/* <FileMenu onNewFileClicked={onNewFileClicked} startFileUpload={startFileUpload} effectClicked={(type) => setOpenDialog(type)} offsetPathClicked={() => setOpenDialog("offsetPath")} setOpenDialog={setOpenDialog} /> */}

        <div className="FirstRow">
          {/* <Toolbar tools={tools} /> */}
          <RightPanel />
          <SVGContainer tools={tools} />
        </div>
      </div>

      <div className="dialogs">
        <SettingsDialog open={openDialog == "settings"} type={openDialog} onClose={() => setOpenDialog(null)} />
        <TermsDialog open={["privacy", "contact", "pricing"].includes(openDialog)} type={openDialog} onClose={() => setOpenDialog(null)} />

        <Dialog
          open={isSaveDialogOpen}
          onClose={() => handleDialogClose(null)}
          PaperProps={
            { style: { padding: 20 } } // Optionally handle dialog close
          }
        >
          <div>Do you want to save the current file?</div>
          <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
            <button onClick={() => handleDialogClose(true)}>Save</button>
            <button onClick={() => handleDialogClose(false)}>Don't Save</button>
          </div>
        </Dialog>

        <Dialog open={isNewFileDialogOpen} onClose={() => setIsNewFileDialogOpen(false)} PaperProps={{ style: { paddingTop: 20, paddingBottom: 20 } }}>
          <div style={{ paddingRight: 20, paddingLeft: 20, textAlign: "center" }}>Create New File</div>

          {/* horiz line */}
          <div style={{ borderBottom: "1px solid #ccc", marginTop: 20, marginBottom: 20 }} />

          <div style={{ display: "flex", justifyContent: "center", gap: 10, flexDirection: "column", paddingRight: 20, paddingLeft: 20 }}>
            <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
              <div>Width: </div>
              <div>
                <input type="number" value={width} onChange={(e) => setWidth(e.target.value)} /> px
              </div>
            </div>
            <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
              <div>Height: </div>
              <div>
                <input type="number" value={height} onChange={(e) => setHeight(e.target.value)} /> px
              </div>
            </div>

            <div>
              <button onClick={() => createNewFile()}>Create</button>
            </div>
          </div>
        </Dialog>
        <FileUploader fileRef={fileRef} imageRef={imageRef} />
      </div>
    </>
  );
}

export default Editor;
