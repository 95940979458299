// @ts-nocheck

import { dup } from "../../utils/utils";
import { applyInlineStyles, toFloats, parseInlineStyle, read } from "../ParserUtils";

const DEFAULTS = {
  type: { value: "radialGradient" }, // not part of SVG spec, this is for my use
  id: { value: null },
  gradientUnits: { value: "objectBoundingBox" },
  gradientTransform: { value: "identity" },
  href: { aliases: ["xlink:href"], value: null },
  spreadMethod: { value: "pad" },
  cx: { value: 50 },
  cy: { value: 50 },
  r: { value: 50 },
  //   fx: { value: 50 },
  //   fy: { value: 50 },
  //   fr: { value: 0 },
  stops: {
    value: [
      { stopColor: "#FF3357", offset: 0 },
      { stopColor: "#FF2A7C", offset: 100 },
    ],
  },
};
const DEFAULTS_STOP = {
  offset: { value: "0" },
  "stop-color": { value: "black", rename: "color" },
  "stop-opacity": { value: "1", rename: "opacity" },
};

export class RadialGradient {
  static from_item(svgRef, item, stops = DEFAULTS.stops) {
    const line = line_from_box(svgRef, item);
    let result = {
      stops,
      id: item.id,
      type: "RadialGradient",
      line,
      initialLine: {
        p1: dup(line.p1),
        p2: dup(line.p2),
      },
    };
    for (let key in DEFAULTS) {
      result[key] = result[key] || DEFAULTS[key];
    }
    return result;
  }
  static from_svg(elem) {
    if (elem.tagName !== "radialGradient") return null;

    let radialGradient = read(elem, DEFAULTS);
    radialGradient.stops = Array.from(elem.children).map((stop) => toFloats(applyInlineStyles(read(stop, DEFAULTS_STOP), stop)));

    radialGradient = toFloats(applyInlineStyles(radialGradient, elem));

    // remove x1, y1, x2, y2, and add line & initialLine
    // RadialGradient.line = {
    // 	p1: { x: parseFloat(radialGradient.x1), y: parseFloat(radialGradient.y1) },
    // 	p2: { x: parseFloat(radialGradient.x2), y: parseFloat(radialGradient.y2) },
    // };
    // radialGradient.initialLine = {
    // 	p1: dup(radialGradient.line.p1),
    // 	p2: dup(radialGradient.line.p2),
    // };
    return radialGradient;
  }
}

function line_from_box(svgRef, item) {
  const box = getBoundingBox(svgRef, item);
  return {
    p1: {
      x: box?.x + 10,
      y: box?.y + box?.height / 2,
    },
    p2: {
      x: box?.x + box?.width - 10,
      y: box?.y + box?.height / 2,
    },
  };
}
