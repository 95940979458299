// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { TextItem } from "../interfaces/TextItem";
import { initPointsForShape, initSegments, segmentsToPathData } from "../utils/SegmentUtils";
import { dup, rand } from "../utils/utils";
import { toBezier } from "../utils/PathUtils";
import { BezierItem } from "../interfaces/BezierItem";
import { ShapeType } from "../values/enums";
import { Group } from "../Group";
import { toChartItems, toColumnItems, toPieItems } from "../utils/ChartUtils";

function useChart(chartType) {
  let { addItem, modifyItem, modifyDef, setDefs, setRightPanel } = useSVG();

  function onToolSelect() {
    const data = [
      {
        label: "Item 1",
        value: 200,
      },
      {
        label: "Item 2",
        value: 400,
      },
      {
        label: "Item 3",
        value: 800,
      },
    ];
    let newItems = toChartItems(chartType, data);
    if (newItems.length > 0) {
      let group = new Group();
      group.chartData = data;
      group.chartType = chartType;
      for (let item of newItems) {
        addItem(item, "", group);
      }
    }
  }
  return {
    onToolSelect,
  };
}

export default useChart;
