// @ts-nocheck

import BezierSegment from "../segments/BezierSegment";
import { lineIntersection } from "./BezierSplitUtils";
import { dedupPoints, dup, isSamePoint } from "./utils";

// Function to calculate the angle between two points
function angle(x1, y1, x2, y2) {
	return Math.atan2(y2 - y1, x2 - x1);
}
// Function to calculate the offset point from a given point and angle
function calculateOffsetPoint(x, y, angle, dist) {
	const offsetX = x + dist * Math.cos(angle);
	const offsetY = y + dist * Math.sin(angle);
	return { x: offsetX, y: offsetY };
}
function getLine(x1, y1, x2, y2) {
	const eps = 0.001;
	// same point
	if (Math.abs(y1 - y2) < eps) {
		// horiz line
		return { m: 0, c: y1 };
	} else if (Math.abs(x1 - x2) < eps) {
		// vert line
		return { m: 100000000000, c: x1 };
	} else {
		const m = (y2 - y1) / (x2 - x1);
		const c = y1 - m * x1;
		return { m, c };
	}
}
export function onSameLine(p1, p2, p3, p4) {
	let pts = dedupPoints([p1, p2, p3, p4]);
	if (pts.length <= 2) return true;
	const { m: m1, c: c1 } = getLine(pts[0].x, pts[0].y, pts[1].x, pts[1].y);

	return Math.abs(pts[2].y - m1 * pts[2].x - c1) < 0.1;
}
// Function to compute the offset control points for a cubic bezier curve
function offsetCubicBezier(x1, y1, x2, y2, x3, y3, x4, y4, dist) {
	// check if all 4 lines point on a line
	const isALine = onSameLine({ x: x1, y: y1 }, { x: x2, y: y2 }, { x: x3, y: y3 }, { x: x4, y: y4 });
	if (isALine) {
		// find the perp angle to line
		const m = Math.atan2(y4 - y1, x4 - x1) - Math.PI / 2;
		// move point 1 and 4 by dist in perp angle to m
		const offsetPoint1 = calculateOffsetPoint(x1, y1, m, dist);
		const offsetPoint4 = calculateOffsetPoint(x4, y4, m, dist);
		return [offsetPoint1, offsetPoint1, offsetPoint4, offsetPoint4];
	}

	const firstToSecondAngle = angle(x1, y1, x2, y2);
	const thirdToFourthAngle = angle(x3, y3, x4, y4);

	const offsetPoint1 = calculateOffsetPoint(x1, y1, firstToSecondAngle - Math.PI / 2, dist);
	const offsetPoint4 = calculateOffsetPoint(x4, y4, thirdToFourthAngle - Math.PI / 2, dist);

	// TODO: handle case where one of the handles is the same as the point
	// TODO: what abt parallel handles?

	const secondToThirdAngle = angle(x2, y2, x3, y3);

	// Calculate the perpendicular angle
	let perpendicularAngle = secondToThirdAngle - Math.PI / 2;

	// if the handles intersect, add 180 degrees
	const doAnglesIntersect = lineIntersection({ x: x1, y: y1 }, { x: x2, y: y2 }, { x: x3, y: y3 }, { x: x4, y: y4 });
	if (doAnglesIntersect) {
		perpendicularAngle += Math.PI;
	}

	// Calculate offset points for second and third control points
	const offsetPoint2 = calculateOffsetPoint(x2, y2, perpendicularAngle, dist);
	const offsetPoint3 = calculateOffsetPoint(x3, y3, perpendicularAngle, dist);

	// Draw lines extending from offset points
	const extendedOffsetPoint2 = calculateOffsetPoint(offsetPoint2.x, offsetPoint2.y, secondToThirdAngle, 1000);
	const extendedOffsetPoint3 = calculateOffsetPoint(offsetPoint3.x, offsetPoint3.y, secondToThirdAngle + Math.PI, 1000);

	const o1 = calculateOffsetPoint(offsetPoint1.x, offsetPoint1.y, firstToSecondAngle, -1000);
	const o2 = calculateOffsetPoint(offsetPoint1.x, offsetPoint1.y, firstToSecondAngle, 1000);

	// find intersection between the two lines
	let intersection1 = lineIntersection({ x: o1.x, y: o1.y }, { x: o2.x, y: o2.y }, { x: extendedOffsetPoint2.x, y: extendedOffsetPoint2.y }, { x: extendedOffsetPoint3.x, y: extendedOffsetPoint3.y });
	if (!intersection1) {
		const d = `M ${x1}, ${y1} C ${x2}, ${y2} ${x3}, ${y3} ${x4}, ${y4}`;
		console.log(d);
		intersection1 = lineIntersection({ x: o1.x, y: o1.y }, { x: o2.x, y: o2.y }, { x: extendedOffsetPoint2.x, y: extendedOffsetPoint2.y }, { x: extendedOffsetPoint3.x, y: extendedOffsetPoint3.y });
	}

	const q1 = calculateOffsetPoint(offsetPoint4.x, offsetPoint4.y, thirdToFourthAngle, -1000);
	const q2 = calculateOffsetPoint(offsetPoint4.x, offsetPoint4.y, thirdToFourthAngle, 1000);

	let intersection2 = lineIntersection({ x: q1.x, y: q1.y }, { x: q2.x, y: q2.y }, { x: extendedOffsetPoint2.x, y: extendedOffsetPoint2.y }, { x: extendedOffsetPoint3.x, y: extendedOffsetPoint3.y });

	return [offsetPoint1, intersection1, intersection2, offsetPoint4];
}

export function offsetCubicBezierSegment(seg, dist) {
	const o = offsetCubicBezier(seg.p1.x, seg.p1.y, seg.p1.handles[1].x, seg.p1.handles[1].y, seg.p2.handles[0].x, seg.p2.handles[0].y, seg.p2.x, seg.p2.y, dist);
	for (let oo of o) {
		if (!oo) {
			console.error("could not offset", seg);
			// when does this even happen?
			return seg;
		}
	}
	const p1 = {
			x: o[0].x,
			y: o[0].y,
			handles: [dup(o[0]), dup(o[1])],
		},
		p2 = {
			x: o[3].x,
			y: o[3].y,
			handles: [dup(o[2]), dup(o[3])],
		};
	const res = new BezierSegment("bezier", p1, p2);
	res.id = seg.id + (dist > 0 ? "-plus" : "-minus");
	return res;
}
