// @ts-nocheck

import { useSVG } from "../SVGContext";
import SVGOverlayItem from "../SVGOverlayItem";
import { renameKeysToCamelcase } from "../svgparser/ParserUtils";
import { allGoogleFonts } from "../values/all-google-fonts";
import SVGItemView from "./SVGItemView";

export function Children({ children, overlay }) {
  const { items, groups } = useSVG();

  return children.map((id, index) => {
    if (("" + id).startsWith("group")) {
      return <SVGGroupView key={index} group={groups[id]} overlay={overlay} />;
    } else {
      if (overlay) {
        return <SVGOverlayItem key={index} item={items[id]} />;
      }
      return <SVGItemView key={index} item={items[id]} />;
    }
  });
}

export function SVGGroupView({ group, overlay }) {
  if (!group) return <g></g>;
  let groupProps = { ...group };
  for (let key of ["children", "group"]) {
    delete groupProps[key];
  }
  groupProps = renameKeysToCamelcase(groupProps);
  const children = Children({ children: group.children, overlay });
  return group.group ? <g {...groupProps}>{children}</g> : <>{children}</>;
}
