// @ts-nocheck

import { Group } from "../Group";
import { ROOT_GROUP_ID } from "../values/constants";

export function toSelectedObjects(selectedIds, items, groups) {
  let selectedGroupOrItems = [];
  for (let i of selectedIds) {
    if (items[i].group == ROOT_GROUP_ID) {
      // this means this item belongs to mainGroup
      selectedGroupOrItems.push(items[i]);
    } else {
      // this means this item belongs to a group other than mainGroup
      selectedGroupOrItems.push(groups[items[i].group]);
    }
  }
  return Array.from(new Set(selectedGroupOrItems));
}

export function useGroupManager({ selectedIds, items, setItems, groups, setGroups, id }) {
  function group() {
    // change selectedIds to selectedGroups
    let selectedGroupOrItems = toSelectedObjects(selectedIds, items, groups);

    let first = selectedGroupOrItems[0].group;

    // attach new group as child of old parent
    const newG = new Group(id("group"));
    newG.group = first;
    groups[newG.id] = newG;
    groups[first].children.push(newG.id);

    for (let it of selectedGroupOrItems) {
      // delete from old group
      let idx = groups[it.group].children.findIndex((x) => x == it.id);
      groups[it.group].children.splice(idx, 1);

      // add to new group
      it.group = newG.id;
      groups[newG.id].children.push(it.id);

      if (it.type == "group") {
        groups[it.id] = it;
      } else {
        items[it.id] = it;
      }
    }

    setItems({ ...items });
    setGroups({ ...groups });
  }

  function ungroup() {
    let gids = selectedIds.map((i) => items[i].group).map((x) => x);
    gids = Array.from(new Set(gids));
    if (gids.length != 1) {
      // show error dialog
      console.error("cannot ungroup");
      return;
    }
    if (gids[0] == ROOT_GROUP_ID) return;

    // remove from group and attach to grandparent group
    const gp = groups[groups[gids[0]].group];

    for (let i of selectedIds) {
      let idx = groups[gids[0]].children.findIndex((x) => x == i);
      groups[gids[0]].children.splice(idx, 1);

      items[i].group = gp.id;
      gp.children.push(i);
    }
    delete groups[gids[0]];
    gp.children = gp.children.filter((x) => x != gids[0]);
    groups[gp.id] = gp;

    setGroups({ ...groups });
    setItems({ ...items });
  }
  return { group, ungroup };
}
