// @ts-nocheck

import { rand } from "../utils/utils";

export class TextItem {
  static type = "text";
  constructor({ id = "text-" + rand(), x, y, fontSize, text, fontFamily, fill, stroke, strokeWidth, textAnchor, alignmentBaseline, opacity, pathId }) {
    this.type = TextItem.type;
    this.id = id;
    this.x = x;
    this.y = y;
    this.fontSize = fontSize;
    this.text = text;

    this.fontFamily = fontFamily;
    this.fill = fill;
    this.stroke = stroke;
    this.strokeWidth = strokeWidth;
    this.textAnchor = textAnchor;
    this.alignmentBaseline = alignmentBaseline;
    this.opacity = opacity;

    this.pathId = pathId;

    this.center = { x, y };
  }
  clone() {}

  mpTransform(t) {
    if (t.type == "rotate") {
    } else if (t.type == "scale") {
      this.fontSize *= Math.max(t.x, t.y);
    } else if (t.type == "translate") {
      if (this.x == undefined || isNaN(this.x)) {
        this.x = 0;
      }
      if (this.y == undefined || isNaN(this.y)) {
        this.y = 0;
      }
      this.x += t.x;
      this.y += t.y;
      this.center = {
        x: this.x,
        y: this.y,
      };

      for (let tspan of this.tspans) {
        if (tspan.x == undefined || isNaN(tspan.x)) {
          tspan.x = 0;
        }
        if (tspan.y == undefined || isNaN(tspan.y)) {
          tspan.y = 0;
        }
        tspan.x += t.x;
        tspan.y += t.y;
      }
    }
    return this;
  }
}
