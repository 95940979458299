// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { initSegments } from "../utils/SegmentUtils";
import { ShapeType } from "../values/enums";
import { transform } from "../utils/transformUtils";
import { getBoundingBox } from "../utils";

function useResizeCanvas() {
  let { setResizeRect, setItems, changeDimensions, setOutline, setMultiSelectBox } = useSVG();
  let [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const onMouseDownNew = ({ xy, z }) => {
    z.resizeRect = {};
    z.resizeRect.width = 1;
    z.resizeRect.height = 1;
    z.resizeRect.x = xy.x;
    z.resizeRect.y = xy.y;
    setResizeRect(z.resizeRect);

    startPosition = xy;
    setStartPosition(startPosition);
  };

  const onMouseMoveNew = ({ xy, z }) => {
    if (!z.resizeRect) return;

    let newWidth = Math.abs(startPosition.x - xy.x);
    let newHeight = Math.abs(startPosition.y - xy.y);

    z.resizeRect = {
      x: Math.min(startPosition.x, xy.x),
      y: Math.min(startPosition.y, xy.y),
      width: newWidth,
      height: newHeight,
    };
    setResizeRect(z.resizeRect);
  };

  const onMouseUpNew = ({ xy, items, z }) => {
    for (let i of Object.keys(items)) {
      items[i] = transform(items[i], { type: "translate", x: -z.resizeRect.x, y: -z.resizeRect.y });
    }
    setItems({ ...items });
    changeDimensions({ width: z.resizeRect.width, height: z.resizeRect.height });

    z.resizeRect = null;
    setResizeRect(null);
    setOutline(null);
    setMultiSelectBox(null);
  };

  const decorateSvg = ({ z }) => {
    if (!z.resizeRect) return null;
    console.log(z.resizeRect);
    return <rect x={z.resizeRect.x} y={z.resizeRect.y} width={z.resizeRect.width} height={z.resizeRect.height} stroke={"blue"} strokeWidth={2} fill="transparent" />;
  };

  return { onMouseDownNew, onMouseUpNew, onMouseMoveNew, name: "resizeCanvas", decorateSvg };
}

export default useResizeCanvas;
