// @ts-nocheck

import { useState } from "react";
import { ColorSquare } from "../ColorSquare";
import { useSVG } from "../SVGContext";
import ShapesMap from "../values/ShapesMap";
import { rand } from "../utils";
import { ROOT_GROUP_ID } from "../values/constants";

// NOTE: d values are parsed each time shapes are loaded
export function ShapesPanel({ close }) {
  const style = { padding: "10px", width: "100%", overflowY: "scroll" };
  const svgStyle = { margin: "10px", width: "60px" };
  const [fill, setFill] = useState("white");
  const [stroke, setStroke] = useState("black");
  const { items, groups, setItems, setGroups } = useSVG();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ColorSquare
          color={fill}
          onChange={(color) => {
            setFill(color.hex);
          }}
        />
        <ColorSquare
          color={stroke}
          onChange={(color) => {
            setStroke(color.hex);
          }}
        />
      </div>
      <div style={style}>
        {ShapesMap.map((dValues, index) => {
          return (
            <svg
              key={index}
              style={svgStyle}
              viewBox="-10 -10 100 100"
              onClick={() => {
                const item = {
                  type: "path",
                  d: dValues.join(" "),
                  id: "path-" + rand(),
                  x: 10,
                  y: 10,
                  fill,
                  stroke,
                };
                items[item.id] = item;
                groups[ROOT_GROUP_ID].children.push(item.id);
                setGroups({ ...groups });
                setItems({ ...items });
                console.log(dValues);
              }}
            >
              {dValues.map((d, i) => {
                return <path key={i} d={d} fill={fill} stroke={stroke} strokeWidth="2" />;
              })}
            </svg>
          );
        })}
      </div>
    </>
  );
}
