// @ts-nocheck
import { Box, ClickAwayListener, IconButton, Menu, MenuItem, Popper, Slider, TextField } from "@mui/material";
import { useSVG } from "../SVGContext";
import { transform } from "../utils/transformUtils";
import { toDegree, toRadians } from "../utils/utils";
import { useEffect, useRef, useState } from "react";
import { ColorSquare } from "../ColorSquare";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import { Icon } from "@material-ui/core";
import LineStyleIcon from "@mui/icons-material/LineStyle";

export const TopbarMain = ({}) => {
  return (
    <>
      <FillColor />
    </>
  );
};
function FillColor({}) {
  let { backgroundStyle, setBackgroundStyle } = useSVG();

  return (
    <ColorSquare
      color={backgroundStyle.backgroundColor || "white"}
      onChange={(color) => {
        setBackgroundStyle({
          backgroundColor: color.hex,
        });
      }}
    />
  );
}
