// @ts-nocheck

import { toSelectedObjects } from "../hooks/GroupManager";

export const arrange = ({ type, selectedIds, items, groups, setGroups, setSelectedIds }) => {
  const selectedObject = toSelectedObjects(selectedIds, items, groups)[0];
  if (!selectedObject) return;
  const group = groups[selectedObject.group];
  const idx = group.children.indexOf(selectedObject.id);
  group.children = group.children.filter((id) => id != selectedObject.id);

  if (type == "bringtofront") {
    // all the way to the front of this group
    group.children.push(selectedObject.id);
  }
  if (type == "bringforward") {
    // one step forward
    group.children.splice(idx + 1, 0, selectedObject.id);
  }
  if (type == "sendbackward") {
    // one step backward
    group.children.splice(idx - 1, 0, selectedObject.id);
  }
  if (type == "sendtoback") {
    // all the way to the back
    group.children.unshift(selectedObject.id);
  }
  setGroups({ ...groups });
};
