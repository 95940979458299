import { useEffect, useState } from "react";
import { useSVG } from "./SVGContext";
import TopbarText from "./topbar/TopbarText";
import { TopbarShape } from "./topbar/TopbarShape";
import { TopbarMain } from "./topbar/TopbarMain";
import { TopbarIcon } from "./topbar/TopbarIcon";

export function Topbar({ selectedIds }) {
  const [item, setItem] = useState(null);
  const { items } = useSVG();

  useEffect(() => {
    if (selectedIds.length === 1) {
      setItem(items[selectedIds[0]]);
    } else {
      setItem(null);
    }
  }, [selectedIds]);

  if (item?.ps) {
    return <TopbarText item={item} />;
  } else if (["rect", "ellipse", "circle", "line", "polyline", "polygon"].includes(item?.type)) {
    return <TopbarShape item={item} />;
  } else if (item?.type == "svg") {
    return <TopbarIcon item={item} />;
  } else {
    return <TopbarMain />;
  }
}
