// @ts-nocheck

import { useSVG } from "../SVGContext";

export function MultiselectBox({ multiSelectBox, selectedIds }) {
  const { tool, setCursor } = useSVG();
  if (!multiSelectBox) return null;

  const b = multiSelectBox;

  const points =
    selectedIds.length == 0 || (tool && tool.name != "pointer")
      ? []
      : [
          {
            x: b.x,
            y: b.y,
            cursor: "nw-resize",
          },
          {
            x: b.x + b.width / 2,
            y: b.y,
            cursor: "n-resize",
          },
          {
            x: b.x + b.width,
            y: b.y,
            cursor: "ne-resize",
          },
          {
            x: b.x + b.width,
            y: b.y + b.height / 2,
            cursor: "e-resize",
          },
          {
            x: b.x + b.width,
            y: b.y + b.height,
            cursor: "se-resize",
          },
          {
            x: b.x + b.width / 2,
            y: b.y + b.height,
            cursor: "s-resize",
          },
          {
            x: b.x,
            y: b.y + b.height,
            cursor: "sw-resize",
          },
          {
            x: b.x,
            y: b.y + b.height / 2,
            cursor: "w-resize",
          },
        ];
  return (
    <>
      <rect id="multi" x={multiSelectBox.x} y={multiSelectBox.y} width={multiSelectBox.width} height={multiSelectBox.height} fill="none" stroke="blue" />
      {points.map((p, i) => (
        <circle key={i} cx={p.x} cy={p.y} r="5" fill="blue" cursor={p.cursor} opacity={0.5} />
      ))}
    </>
  );
}
