// @ts-nocheck

import { TextItem } from "../interfaces/TextItem";
import { SVG_ATTRIBUTES } from "../values/SvgAttributes";

function toCamelCase(s) {
  return s.replace(/-./g, (x) => x.toUpperCase()[1]);
}
function TextView({ item: it }) {
  if (it.type !== TextItem.type) return null;

  const props = {
    id: it.id,
    mp_item_id: it.id,
  };

  for (let p in SVG_ATTRIBUTES) {
    if (it[p] != undefined && typeof it[p] != "function") {
      props[p] = it[p];
    }
    const q = toCamelCase(p);
    if (it[q] != undefined && typeof it[q] != "function") {
      props[p] = it[q];
    }
  }

  const texts = it.tspans
    ? it.tspans.map((t, i) => {
        let tprops = { x: t.x, y: t.y, dx: t.dx, dy: t.dy, key: i, mp_item_id: it.id };

        for (let p in tprops) if (tprops[p] === undefined) delete tprops[p];
        return <tspan {...tprops}>{t.text}</tspan>;
      })
    : it.text;

  return (
    <text {...props}>
      {/* if you don't set textPath's id to be text item's id here, selection with pointer doesn't work */}
      {it.pathId && (
        <textPath id={it.id} href={"#" + it.pathId}>
          {texts}
        </textPath>
      )}

      {!it.pathId && <>{texts}</>}
    </text>
  );
}
export default TextView;
