// @ts-nocheck

import { ShapeInfo, Intersection } from "kld-intersections";
import { isPointOnArc } from "../utils/ellipseUtils";
import RootSegment from "../tools/RootSegment";
import { pointsToPath } from "../utils";

class PathSegment extends RootSegment {
	constructor(type, p1, p2, opt = {}) {
		super();
		this.points = opt.points;
		this.d = pointsToPath(this.points);
		this.init(type, p1, p2, opt);
	}
	update() {
		this.d = pointsToPath(this.points);
		return super.update();
	}
	equals(seg) {
		if (this.type !== seg.type) return false;
		// TODO: only works when first point is M and others are L.
		// not when there are arcs or curves.
		if (this.points.length != seg.points.length) return false;
		let notSameForward = false,
			notSameBackward = false;
		for (let i = 0; i < this.points.length; i++) {
			if (!isSamePoint(this.points[i], seg.points[i])) {
				notSameForward = true;
			}
			if (!isSamePoint(this.points[this.points.length - 1 - i], seg.points[i])) {
				notSameBackward = true;
			}
			if (notSameForward && notSameBackward) return false;
		}
		return false;
	}
	intersects(segment) {
		let points = Intersection.intersect(this.kldSegment, segment.kldSegment).points.map((p) => ({ x: p.x, y: p.y }));
		if (segment.type == "arc") {
			points = points.filter((p) => isPointOnArc(p, segment)); // kld bug fix: take only points that lie on arc
		}
		return points;
	}
	makeKldSegment() {
		return ShapeInfo.path(this.d);
	}
	getPathData() {
		return this.d;
	}
}
export default PathSegment;
