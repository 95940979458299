// from https://gist.github.com/acodesmith/b4a17519eee1be5a9b17cddbe06d72fe
export const WebSafeFonts = {
  Arial: {
    category: "sans-serif",
    stack: ["Arial", "Arial", "Helvetica", '"Helvetica Neue"', "sans-serif"],
  },
  Calibri: {
    category: "sans-serif",
    stack: ["Calibri", "Candara", "Segoe", '"Segoe UI"', "Optima", "Arial", "sans-serif"],
  },
  "Century Gothic": {
    category: "sans-serif",
    stack: ['"Century Gothic"', "CenturyGothic", "AppleGothic", "sans-serif"],
  },
  "Gill Sans": {
    category: "sans-serif",
    stack: ['"Gill Sans"', '"Gill Sans MT"', "Calibri", "sans-serif"],
  },
  Tahoma: {
    category: "sans-serif",
    stack: ["Tahoma", "Verdana", "Segoe", "sans-serif"],
  },
  "Trebuchet MS": {
    category: "sans-serif",
    stack: ['"Trebuchet MS"', '"Lucida Grande"', '"Lucida Sans Unicode"', "Tahoma", "sans-serif"],
  },
  Verdana: {
    category: "sans-serif",
    stack: ["Verdana", "Geneva", "sans-serif"],
  },
  "Book Antiqua": {
    category: "serif",
    stack: ['"Book Antiqua"', "Palatino", '"Palatino Linotype"', '"Palatino LT STD"', "Georgia", "serif"],
  },
  Cambria: {
    category: "serif",
    stack: ["Cambria", "Georgia", "serif"],
  },
  Garamond: {
    category: "serif",
    stack: ["Garamond", "Baskerville", '"Baskerville Old Face"', '"Hoefler Text"', '"Times New Roman"', "serif"],
  },
  Georgia: {
    category: "serif",
    stack: ["Georgia", "Times", '"Times New Roman"', "serif"],
  },
  "Goudy Old Style": {
    category: "serif",
    stack: ['"Goudy Old Style"', "Garamond", '"Big Caslon"', '"Times New Roman"', "serif"],
  },
  "Lucida Bright": {
    category: "serif",
    stack: ['"Lucida Bright"', "Georgia", "serif"],
  },
  Palatino: {
    category: "serif",
    stack: ["Palatino", '"Palatino Linotype"', '"Book Antiqua"', "Georgia", "serif"],
  },
  Baskerville: {
    category: "serif",
    stack: ["Baskerville", '"Baskerville Old Face"', '"Hoefler Text"', "Garamond", '"Times New Roman"', "serif"],
  },
  TimesNewRoman: {
    category: "serif",
    stack: ["TimesNewRoman", '"Times New Roman"', "Times", "Baskerville", "Georgia", "serif"],
  },
  "Courier New": {
    category: "monospace",
    stack: ['"Courier New"', "Courier", '"Lucida Sans Typewriter"', '"Lucida Typewriter"', "Georgia", "monospace"],
  },
  "Lucida Sans Typewriter": {
    category: "monospace",
    stack: ['"Lucida Sans Typewriter"', '"Lucida Console"', "monaco", '"Bitstream Vera Sans Mono"', "monospace"],
  },
  Copperplate: {
    category: "fantasy",
    stack: ["Copperplate", '"Copperplate Gothic Light"', "fantasy"],
  },
  "Brush Script MT": {
    category: "script",
    stack: ['"Brush Script MT"', "cursive"],
  },
};

// from https://www.dreamhost.com/blog/best-google-fonts/
export const GoogleFontFamilies = [
  "Abril Fatface",
  "Alfa Slab One",
  "Anonymous Pro",
  "Antic Didone",
  "Azeret Mono",
  "Bebas Neue",
  "Berkshire Swash",
  "Caveat",
  "Cedarville Cursive",
  "Cinzel",
  "Comfortaa",
  "Cormorant",
  "Courier Prime",
  "Dancing Script",
  "Fira Sans",
  "Homemade Apple",
  "IBM Plex Mono",
  "Inconsolata",
  "Indie Flower",
  "Italiana",
  "JetBrains Mono",
  "La Belle Aurore",
  "Lato",
  "League Script",
  "Libre Baskerville",
  "Lobster",
  "Lora",
  "Merriweather",
  "Montserrat",
  "Noto Sans Mono",
  "Noto Serif",
  "Old Standard TT",
  "Open Sans",
  "Oswald",
  "PT Serif",
  "Pacifico",
  "Playfair Display",
  "Poppins",
  "Proza Libre",
  "Raleway",
  "Roboto",
  "Roboto Condensed",
  "Roboto Mono",
  "Roboto Slab",
  "Shadows Into Light",
  "Slabo",
  "Source Code Pro",
  "Space Mono",
  "Vollkorn",
  "Work Sans",
];
