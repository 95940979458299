// @ts-nocheck
import { useRef, useState } from "react";
import RightClickMenu from "./RightClickMenu";
import RightPanel from "./RightPanel";
import { useSVG } from "./SVGContext";
import SVGDef from "./views/SvgDefView";
import { getCoordinatesInSVG } from "./utils";
import { findTargetInSvgForFakeMouseEvent } from "./utils/utils";
import { SvgGrid } from "./tools/SvgGrid";
import { ROOT_GROUP_ID } from "./values/constants";
import { SVGGroupView } from "./views/SVGGroupView";
import DivWithEvents from "./components/DivWithEvents";
import { MultiselectBox } from "./components/MultiselectBox";
import SVGItemView from "./views/SVGItemView";
import { Topbar } from "./Topbar";
import { MousePosition } from "./MousePosition";

const SVGContainer = ({ tools }) => {
  const z = useSVG();

  // animation gotcha: scope should be given to something as a ref
  const { scope, viewBox, tool, items, groups, defs, mainGroup, snapLines, showGrid, fill, stroke, selectedIds, resizeRect, selectedCorners, outline, setOutline, setIntersections, setLines, mouseReceivers, svgDecorators, animationOpen, svgWidthPx, svgHeightPx, backgroundStyle } = useSVG();
  const { intersections, multiSelectBox } = useSVG();
  const { mousePos, setMousePos } = useSVG();
  const overlayRef = useRef<SVGSVGElement>(null);
  const { width, height } = useSVG();
  const { region, grayRegion, lines } = useSVG();
  const { svgRef } = useSVG();
  const { smartLines } = useSVG();
  const { cursor, setCursor } = useSVG();

  /*********** right click menu ********/
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [showMenu, setShowMenu] = useState(false);

  const handleRightClick = (event) => {
    // BUG: right click is also going to onMouseDown (or onMouseUp) -- try right clicking when shape tool is on
    event.preventDefault(); // Prevent default browser right-click menu
    event.stopPropagation(); // Prevent right-click from bubbling up to the window
    setShowMenu(true);
    setMenuPosition({ x: event.pageX, y: event.pageY });
  };
  /************************************/

  const fakeEvent = (cb, event, items) => {
    let ncb = cb + "New";
    if (!tool) return;

    if (!tool[ncb] && !tool[cb]) return;
    let xy = getCoordinatesInSVG(svgRef, viewBox, width, height, event);
    // let target;
    // target = findTargetInSvgForFakeMouseEvent(event, svgRef, items);
    let target = event.target;
    try {
      for (let m of mouseReceivers) {
        if (m[ncb] && m[ncb]({ event, xy, target, items, groups, defs, selectedIds, fill, stroke, resizeRect, cursor, multiSelectBox, selectedCorners, viewBox, z, history: z.history, outline })) {
          return;
        }
      }
      tool[ncb] && tool[ncb]({ event, xy, target, items, groups, defs, selectedIds, fill, stroke, resizeRect, cursor, multiSelectBox, selectedCorners, viewBox, z, history: z.history, outline });
    } catch (e) {
      console.error(e);
    }
  };

  function genArcParams(c) {
    const rx = 50 * Math.random() + 50,
      ry = 150 * Math.random() + 50;
    const startRad = Math.random() * Math.PI * 3,
      endRad = Math.random() * 3 * Math.PI;

    const p1 = {
        x: c.x + rx * Math.cos(startRad),
        y: c.y + ry * Math.sin(startRad),
      },
      p2 = {
        x: c.x + rx * Math.cos(endRad),
        y: c.y + ry * Math.sin(endRad),
      };
    const largeArc = Math.random() > 0.5 ? 1 : 0,
      sweep = Math.random() > 0.5 ? 1 : 0;
    const rotationDegree = 30;
    return [p1.x, p1.y, rx, ry, rotationDegree, largeArc, sweep, p2.x, p2.y];
  }

  const onOverlayMouseDown = (event) => {
    setShowMenu(false);
    fakeEvent("onMouseDown", event, items);

    // let arcParams = genArcParams(getCoordinatesInSVG(svgRef, viewBox, width, height, event));
    // // arcParams = [196.00952487506584, 152.19871821464255, 90.66852437155785, 171.8468510518674, 0, 0, 0, 333.6143607955809, 324.12860862685216];
    // // arcParams = [154.03272195725077, 121.78404571880469, 58.36718355388197, 81.74393233507647, 0, 0, 1, 263.3002708917379, 143.44189091849233];
    // // arcParams = [194.6752541698167, 302.97148421043767, 69.41042418937896, 131.90032321616533, 30, 1, 0, 222.18088699743862, 323.2881487300765];
    // // arcParams = [242.5949007407558, 196.96431380542424, 74.42310220570164, 50.40744911401938, 30, 1, 1, 158.9288278920918, 270.72432636773857];
    // const d = "M " + arcParams.slice(0, 2).join(" ") + " A " + arcParams.slice(2).join(" ");
    // const arc = { type: "path", d };

    // let arcParams2 = [...arcParams];
    // arcParams2[4] = 0;

    // let nItems = [...items, arc];
    // let sequence = [];
    // const bezierItem = arcToBezier(...arcParams, sequence);
    // if (bezierItem) {
    // 	nItems = [...nItems, bezierItem];
    // 	setItems([...nItems]);
    // } else {
    // 	console.log("nothing");
    // }
    // animateSequence(sequence, nItems, setItems, setIntersections, setLines);
  };
  const onOverlayMouseMove = (event) => {
    // mouseCoords = getCoordinatesInSVG(svgRef, viewBox, width, height, event);

    // setMousePos({
    //   x: event.nativeEvent.offsetX,
    //   y: event.nativeEvent.offsetY,
    // });
    fakeEvent("onMouseMove", event, items);
  };
  const onOverlayMouseUp = (event) => {
    fakeEvent("onMouseUp", event, items);
  };
  const onOverlayMouseOut = (event) => {
    fakeEvent("onMouseOut", event, items);
  };
  const onOverlayTouchMove = (event) => {
    fakeEvent("onTouchMove", event, items);
  };
  const onOverlayTouchUp = (event) => {
    fakeEvent("onTouchUp", event, items);
  };
  const onOverlayTouchDown = (event) => {
    fakeEvent("onTouchDown", event, items);
  };
  const eventHandlers = {
    onMouseDown: onOverlayMouseDown,
    onMouseMove: onOverlayMouseMove,
    onMouseUp: onOverlayMouseUp,
    onMouseOut: onOverlayMouseOut,
    onTouchStart: onOverlayTouchDown,
    onTouchMove: onOverlayTouchMove,
    onTouchEnd: onOverlayTouchUp,
    onTouchCancel: onOverlayTouchUp,
    onContextMenu: handleRightClick,
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          flex: "1",
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "stretch",
          alignItems: "stretch",
        }}
      >
        <div className="topbar">
          <Topbar selectedIds={selectedIds} />
        </div>

        <DivWithEvents className="SVGContainer" style={{ backgroundColor: "lightgray", cursor, flex: 1 }} {...eventHandlers}>
          <svg key="svg1" xmlns="http://www.w3.org/2000/svg" tabIndex="0" className="Board" ref={svgRef} viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`} style={backgroundStyle} height="100%" width="100%">
            <g>
              {/* <rect className="whiteBackgroundRect" fill="white" opacity={1} x={0} y={0} width={width} height={height} /> */}

              <defs>
                {/* unattached defs */}
                {defs.map((def, index) => (
                  <SVGDef key={index} def={def} />
                ))}
              </defs>
              {/* proper items */}
              <SVGGroupView group={groups[ROOT_GROUP_ID]} />
            </g>

            {!animationOpen && (
              <g id="overlay">
                <defs>
                  <pattern type="overlay" id="dotPattern" patternUnits="userSpaceOnUse" width="3" height="3">
                    <circle cx="2.5" cy="2.5" r="0.5" fill="gray" />
                  </pattern>
                </defs>

                <MultiselectBox multiSelectBox={multiSelectBox} selectedIds={selectedIds} />

                <SVGGroupView group={groups[ROOT_GROUP_ID]} overlay={true} />

                {svgDecorators && svgDecorators.map((decorator, index) => decorator.decorateSvg({ z }))}

                {outline && <path id="hover-outline" d={outline.d} fill="none" stroke={outline.stroke} strokeWidth="2" transform={outline.transform} />}

                {snapLines.map((line, index) => (
                  <line key={index} x1={line.p1.x} y1={line.p1.y} x2={line.p2.x} y2={line.p2.y} stroke="purple" strokeWidth={1} opacity={0.7} />
                ))}
                {showGrid && <SvgGrid width={width} height={height} />}
              </g>
            )}
          </svg>

          {animationOpen && <AnimationLayers />}
        </DivWithEvents>
      </div>

      {/* <MousePosition /> */}
      {showMenu && <RightClickMenu x={menuPosition.x} y={menuPosition.y} setShowMenu={setShowMenu} />}
    </>
  );
};

export default SVGContainer;
