// @ts-nocheck
import jsPDF from "jspdf";
import { DEFTYPES } from "../values/enums";
import saveSvgAsPng from "../saveSvgAsPng";
const prettier = require("prettier/standalone");
const parserHtml = require("prettier/parser-html");

async function formatHtml(html) {
  try {
    const formattedHtml = await prettier.format(html, {
      parser: "html",
      plugins: [parserHtml], // Include the HTML parser plugin
    });
    return formattedHtml;
  } catch (error) {
    console.error("Error formatting HTML:", error);
    return html; // Return the original HTML if formatting fails
  }
}

function FileManager() {
  function ext(filename, ext) {
    return filename.replace(/\.[^/.]+$/, "") + "." + ext;
  }
  function getPNG(params, callback) {
    let fontUrls = [];
    if (!params.defs) {
      console.error("No defs provided for PNG conversion. Fonts won't show.");
    } else {
      fontUrls = params.defs
        .map((def) => {
          if (def.type == DEFTYPES.IMPORTURL) return def.url;
        })
        .map((x) => x);
    }
    Promise.all(fontUrls.map((url) => fetchCSS(url).then((cssText) => embedFonts(cssText)))).then((combinedCss) => {
      let style = document.createElement("style");
      style.innerHTML = combinedCss.join("\n");
      params.svgRef.current.appendChild(style);

      getSVGHTML(params.svgRef).then((svgData) => {
        const url = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData))); //URL.createObjectURL(svg);
        const img = new Image();
        const scale = 6;
        img.setAttribute("crossOrigin", "Anonymous");
        img.onload = () => {
          const canvas = document.createElement("canvas");
          canvas.width = params.width * scale;
          canvas.height = params.height * scale;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, params.width * scale, params.height * scale);

          canvas.toBlob((blob) => {
            callback(blob, params);
            // delete the style tag
            style.remove();
          }, "image/png");
        };
        img.onerror = (err) => {
          console.error("Image loading error", err);
          // delete the style tag
          style.remove();
        };
        img.src = url;
      });
    });
  }

  async function getSVG(params) {
    let svgData = getSVGHTML(params.svgRef);
    const tags = ["pattern", "circle", "ellipse", "line", "path", "rect", "polygon", "polyline", "text", "image", "g"];
    tags.forEach((tag) => {
      const regex = new RegExp(`<${tag} type="overlay"[\\s\\S]*?<\\/${tag}>`, "g");
      svgData = svgData.replace(regex, "");
    });
    const formatted = await formatHtml(svgData);
    return formatted;
  }
  async function saveAsSvgFile(params) {
    let svgData = await getSVG(params);
    const blob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
    startDownload(blob, params.fileName);
  }

  function saveAsJsonFile(params) {
    let { items, width, height } = params;
    items = items.map((x) => ({ ...x, tempSegments: undefined }));
    const json = JSON.stringify({ items, width, height });
    const blob = new Blob([json], {
      type: "application/json",
    });
    startDownload(blob, params.fileName);
  }

  const saveAsPngFile = (params) => {
    getPNG(params, function (blob, p) {
      startDownload(blob, p.filename, params.defs);
    });
  };

  const saveAsPdf = (params) => {
    const svgData = getSVGHTML(params.svgRef);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = params.width;
      canvas.height = params.height;
      ctx.drawImage(img, 0, 0, params.width, params.height);

      const pdf = new jsPDF();
      pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0);
      pdf.save(ext(params.filename, "pdf"));
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const getSVGHTML = async (svgRef) => {
    // TODO after I get my own image urls without CORS issues
    // Download every image and replace the src with the data url
    const images = svgRef.current.querySelectorAll("img");
    let promises = [];

    for (let img of images) {
      const src = img.getAttribute("src");

      const promise = fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              img.src = reader.result;
              resolve();
            };
            reader.readAsDataURL(blob);
          });
        })
        .catch((e) => console.error("Error fetching image", e));

      promises.push(promise);
    }

    await Promise.all(promises);

    // BUG FIX: you have to manually close <img> tags
    let html = svgRef.current.outerHTML;
    html = html.replace(/<img(.*?)>/g, "<img$1 />");
    html = html.replace(/&nbsp;/g, " ");

    // parse this using a parser, in order to remove the overlay
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "image/svg+xml");

    // delete a <g> with id overlay
    const overlay = doc.querySelector("#overlay");
    if (overlay) {
      overlay.remove();
    }
    html = new XMLSerializer().serializeToString(doc);
    return html;
  };

  const saveAsWebp = async (params) => {
    const svgData = await getSVGHTML(params.svgRef);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = params.width;
      canvas.height = params.height;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        startDownload(blob, params.filename);
      }, "image/webp");
    };
    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  const saveSvgAsHtmlString = (svgData) => `<!DOCTYPE html>
	<html lang="en">
	<head>
		<meta charset="UTF-8">
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<title>SVG as HTML</title>
	</head>
	<body>
		${svgData}
	</body>
	</html>`;

  async function saveAsHtml(params, fileName = "download.html") {
    const svgData = await getSVGHTML(params.svgRef);
    const htmlContent = saveSvgAsHtmlString(svgData);
    const blob = new Blob([htmlContent], { type: "text/html" });
    startDownload(blob, fileName);
  }

  function startDownload(blob, fileName) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const save = (params) => {
    if (params.type == "svg") {
      saveAsSvgFile(params);
    } else if (params.type == "json") {
      saveAsJsonFile(params);
    } else if (params.type == "png") {
      saveAsPngFile(params);
    } else if (params.type == "pdf") {
      saveAsPdf(params);
    } else if (params.type == "webp") {
      saveAsWebp(params);
    } else if (params.type == "html") {
      saveAsHtml(params);
    }
  };

  const load = (params, init) => {
    if (params.type === "json") {
      init(JSON.parse(params.json));
    } else if (params.type === "jsonURL") {
      fetch(url)
        .then((response) => response.json())
        .then(init);
    } else if (params.type == "jsonURLFromURLParams") {
      const urlParams = new URLSearchParams(window.location.search);
      const jsonUrl = urlParams.get("jsonUrl");
      if (jsonUrl) {
        load({ type: "jsonURL", url: jsonUrl }, init);
      }
    }
  };

  return { save, load, getPNG, getSVG };
}
export default FileManager;

function fetchCSS(url) {
  return fetch(url).then(
    function (res) {
      return res.text();
    },
    function (e) {
      console.log(e);
    },
  );
}

function embedFonts(cssText) {
  var fontLocations = cssText.match(/https:\/\/[^)]+/g);
  var fontLoadedPromises = fontLocations.map(function (location) {
    return new Promise(function (resolve, reject) {
      fetch(location)
        .then(function (res) {
          return res.blob();
        })
        .then(function (blob) {
          var reader = new FileReader();
          reader.addEventListener("load", function () {
            // Side Effect
            cssText = cssText.replace(location, this.result);
            resolve([location, this.result]);
          });
          reader.readAsDataURL(blob);
        })
        .catch(reject);
    });
  });
  return Promise.all(fontLoadedPromises).then(function () {
    return cssText;
  });
}
