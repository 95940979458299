// @ts-nocheck
import { ClickAwayListener } from "@mui/material";
import { SketchPicker } from "react-color";

const customSwatches = ["transparent", "#D0021B", "#F5A623", "#F8E71C", "#8B572A", "#7ED321", "#417505", "#BD10E0", "#9013FE", "#4A90E2", "#50E3C2", "#B8E986", "#000000", "#4A4A4A", "#9B9B9B", "#FFFFFF"];

function MySketchPicker({ onChangeComplete, color, setShowPicker, addClickAway = true }) {
  if (!addClickAway) {
    return (
      <div style={{ position: "relative", zIndex: 2 }}>
        <SketchPicker color={color} onChangeComplete={onChangeComplete} presetColors={customSwatches} />
      </div>
    );
  }
  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowPicker(false);
      }}
    >
      <div style={{ position: "relative", zIndex: 2 }}>
        <SketchPicker color={color} onChangeComplete={onChangeComplete} presetColors={customSwatches} />
      </div>
    </ClickAwayListener>
  );
}

export default MySketchPicker;
