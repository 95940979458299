// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { initSegments } from "../utils/SegmentUtils";
import { transform } from "../utils/transformUtils";
import { toolbarTools } from "../Toolbar";

function noModifier(e) {
  return !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey;
}
function ShortcutHandler({ tools, startFileUpload }) {
  const { setItems, setGroups, setSelectedIds, setMultiSelectBox, tool, setTool, undo, redo, saveAsSvgFile, zoomIn, zoomOut, cut, copy, paste, history, deleteItems } = useSVG();

  let [isCtrlDown, setCtrlDown] = useState(false);

  const handleKeyDown = ({ event, items, groups, selectedIds }) => {
    // GOTCHA: when export's text field is being typed, if I don't do this check for App/Body here
    // the text field's onChange gets canceled here.
    // If onKeyDown, as it bubbles up, is canceled, then onChange of textfield won't work.
    const act = document.activeElement;
    if ((act?.getAttribute("class") == "App" || act?.getAttribute("class") == "Board" || act?.tagName == "BODY") && handleKeyDownInternal({ event, items, groups, selectedIds })) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleKeyDownInternal = ({ event, items, groups, selectedIds }) => {
    // console.log(document.activeElement);
    // console.log("Shortcuthandler handleKeyDown: ", event.key, event.keyCode, event.ctrlKey, event.metaKey, event.shiftKey, event.altKey);
    if (event.key == "ArrowUp" || event.key == "ArrowDown" || event.key == "ArrowLeft" || event.key == "ArrowRight") {
      const selectedIndex = selectedIds[0];
      if (selectedIndex != null) {
        const t = { type: "translate", x: 0, y: 0 };
        let item = items[selectedIndex];
        if (event.key === "ArrowUp") {
          t.y = -1;
        }
        if (event.key === "ArrowDown") {
          t.y = 1;
        }
        if (event.key === "ArrowLeft") {
          t.x = -1;
        }
        if (event.key === "ArrowRight") {
          t.x = 1;
        }
        item = transform(item, t);
        items[selectedIndex] = initSegments(item);
        setItems({ ...items });
        return true;
      }
    }

    for (let t of toolbarTools) {
      // FIXME: ctrl+a shouldn't select direct pointer
      if (t.shortCut && t.shortCut(event)) {
        if (t.name !== "shape") {
          setTool(tools[t.name]);
          return true;
        }
      }
    }

    // for now, handle shapes here
    if (noModifier(event)) {
      if (event.key == "l") {
        setTool(tools["shape"]["rectangle"]);
      }
      if (event.key == "m") {
        setTool(tools["shape"]["circle"]);
        return true;
      }
    }

    // undo
    if ((event.ctrlKey || event.metaKey) && event.key === "z") {
      history.undo(items);
      return true;
    }
    // redo
    if ((event.ctrlKey || event.metaKey) && event.key === "y") {
      redo(items);
      return true;
    }

    // save file
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      saveAsSvgFile();
      return true;
    }

    // select all
    if ((event.ctrlKey || event.metaKey) && event.key === "a") {
      setSelectedIds(Object.keys(items));
      return true;
    }

    // zooming
    if ((event.ctrlKey || event.metaKey) && (event.key === "+" || event.key === "=")) {
      zoomIn();
      return true;
    }
    if ((event.ctrlKey || event.metaKey) && event.key === "-") {
      zoomOut();
      return true;
    }

    // Check if the Delete key was pressed
    if (event.key === "Delete" || event.key === "Backspace" || event.keyCode === 46) {
      deleteItems(selectedIds);
      return true;
    }

    // cut
    if ((event.ctrlKey || event.metaKey) && event.key === "x") {
      cut();
      return true;
    }

    // copy
    if ((event.ctrlKey || event.metaKey) && event.key === "c") {
      copy();
      return true;
    }

    // paste
    if ((event.ctrlKey || event.metaKey) && event.key === "v") {
      paste();
      return true;
    }

    // open file
    if ((event.ctrlKey || event.metaKey) && event.key === "o") {
      console.log("starting file upload");
      startFileUpload("open");
      return true;
    }

    if (event.key === "Control") {
      isCtrlDown = true;
      setCtrlDown(isCtrlDown);
    }

    if (tool && tool.onKeyDown) {
      tool.onKeyDown(event, items, selectedIds); // FIXME: only if this key was so far not handled
      return true;
    }
  };

  const handleKeyUp = () => {
    isCtrlDown = false;
    setCtrlDown(isCtrlDown);
  };
  return { handleKeyDown, handleKeyUp };
}
export default ShortcutHandler;
