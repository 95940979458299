import { TextItem } from "../../interfaces/TextItem";

export function parseText(child) {
  let item = new TextItem({
    text: "",
  });
  item.tspans = [];
  for (let c of child.children) {
    if (c.tagName == "tspan" || c.tagName == "text") {
      let tspan = {
        text: c.textContent,
      };
      if (c.getAttribute("x")) {
        tspan.x = c.getAttribute("x");
      }
      if (c.getAttribute("y")) {
        tspan.y = c.getAttribute("y");
      }
      if (c.getAttribute("dx")) {
        tspan.dx = c.getAttribute("dx");
      }
      if (c.getAttribute("dy")) {
        tspan.dy = c.getAttribute("dy");
      }
      item.tspans.push(tspan);
    }
  }
  return item;
}
