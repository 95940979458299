// @ts-nocheck

import { useSVG } from "../SVGContext";

export function SvgGrid({}) {
  const clr = "#e4e4e4";
  const { svgRef, width, height } = useSVG();
  const lines = [];
  const inc = 50;
  for (let i = 0; i < width; i += inc) {
    lines.push(<line key={i} x1={i} y1={0} x2={i} y2={height} stroke={clr} strokeWidth="1" />);
    lines.push(
      <text
        key={i + "text1"}
        x={i}
        y={10}
        fontSize="8"
        fill="gray"
        style={{
          userSelect: "none",
        }}
      >
        {i}
      </text>,
    );
  }
  for (let i = 0; i < height; i += inc) {
    const xx = 0;
    const yy = i;
    lines.push(<line key={"h" + i} x1={xx} y1={yy} x2={xx + width} y2={yy} stroke={clr} strokeWidth="1" />);
    lines.push(
      <text
        key={i + "text2"}
        x={xx + 2}
        y={yy - 2}
        fontSize="8"
        fill="gray"
        style={{
          userSelect: "none",
        }}
      >
        {i}
      </text>,
    );
  }
  return (
    <g>
      {lines}
      <line x1={0} y1={0} x2={width} y2={0} stroke={clr} strokeWidth="1" />
      <line x1={0} y1={0} x2={0} y2={height} stroke={clr} strokeWidth="1" />
      <line x1={0} y1={height} x2={width} y2={height} stroke={clr} strokeWidth="1" />
      <line x1={width} y1={0} x2={width} y2={height} stroke={clr} strokeWidth="1" />
    </g>
  );
}
