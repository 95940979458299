import { stringStyleToJsonStyle } from "../renderer/svgparser/ParserUtils";

export function testParserUtils() {
  const cases = [
    ["position: absolute;", { position: "absolute" }],
    ["position: absolute; fill: red;", { position: "absolute", fill: "red" }],
    ["position: absolute;fill: rgb(12,12,12);", { position: "absolute", fill: "rgb(12,12,12)" }],
  ];
  for (const [input, expected] of cases) {
    const result = stringStyleToJsonStyle(input);
    if (JSON.stringify(result) !== JSON.stringify(expected)) {
      console.error(`stringStyleToJsonStyle failed: input=${input}, expected=${JSON.stringify(expected)}, result=${JSON.stringify(result)}`);
    }
  }
}
