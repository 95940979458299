import { Button, IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useSVG } from "../renderer/SVGContext";
import { useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";

const style = {
  padding: "10px 20px",

  display: "flex",
  flexDirection: "row",
  gap: 5,
  justifyContent: "space-between",
  alignItems: "center",

  borderBottom: "solid 2px #c9c8c8",
};
const subStyle = {
  display: "flex",
  flexDirection: "row",
  gap: 15,
  justifyContent: "start",
  alignItems: "center",
};
export function LoggedinBar() {
  const { userInfo } = useSVG();

  return (
    <div style={style}>
      <div style={subStyle}>
        <Link underline="none" variant="button" href="/home">
          Home
        </Link>
        <Link underline="none" variant="button" href="/templates">
          Templates
        </Link>
        <Link underline="none" variant="button" href="/designs">
          Designs
        </Link>
      </div>
      <div style={subStyle}>
        <Save />
        <Download />

        <Button variant="contained">Upgrade</Button>
        {!userInfo && (
          <Link underline="none" variant="button" href="https://api.movingpencils.com/login">
            Login
          </Link>
        )}
        {userInfo && <ProfileButton userInfo={userInfo} />}
      </div>
    </div>
  );
}

function ProfileButton({ userInfo }) {
  const [anchorEl, setAnchor] = useState(null);
  const [initials, setInitials] = useState("");

  useEffect(() => {
    if (userInfo) {
      setInitials(userInfo.name?.charAt(0) || userInfo.email?.charAt(0));
    }
  }, [userInfo]);

  const logout = () => {
    // redirect to api.movingpencils.com/logout
    window.location.href = "https://api.movingpencils.com/logout";
  };

  return (
    <>
      <IconButton
        style={{ marginLeft: 10 }}
        variant="contained"
        onClick={(e) => {
          setAnchor(anchorEl ? null : e.currentTarget);
        }}
      >
        {initials}
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        {userInfo.email && <MenuItem>{userInfo.email}</MenuItem>}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
}
function Download() {
  const { fileManager, svgRef, width, height, defs } = useSVG();
  const [anchorEl, setAnchor] = useState(null);

  function download(format) {
    fileManager.save({
      type: format,
      filename: "download." + format,
      svgRef,
      width,
      height,
      defs,
    });
    setAnchor(null);
  }
  return (
    <>
      <IconButton variant="outline" onClick={(e) => setAnchor(anchorEl ? null : e.currentTarget)}>
        <DownloadIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchor(null)}>
        <MenuItem onClick={() => download("svg")}>SVG</MenuItem>
        <MenuItem onClick={() => download("png")}>PNG</MenuItem>
        <MenuItem onClick={() => download("pdf")}>PDF</MenuItem>
      </Menu>
    </>
  );
}
function Save() {
  const { saveDesign } = useSVG();

  return (
    <IconButton
      variant="outline"
      onClick={() =>
        saveDesign(function () {
          console.log("saved!");
        })
      }
    >
      <SaveIcon />
    </IconButton>
  );
}
