// @ts-nocheck

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Tests } from "./tests/Tests";
import { App } from "./App";
import EditorContainer from "./EditorContainer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoggedoutTemplates from "./home/LoggedOutTemplates";
import Editor from "./renderer/Editor";
import { SVGProvider } from "./renderer/SVGContext";
import { LoggedinBar } from "./home/LoggedinBar";
import "./renderer/css/App.css";
import { BottomBar } from "./renderer/components/BottomBar";
import "./renderer/css/App.css";
import { Designs } from "./Designs";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <SVGProvider>
      <Router basename="/">
        <Routes>
          {["/home", "/"].map((path, index) => (
            <Route
              key={index}
              path={path}
              element={
                <>
                  <LoggedinBar />
                  <Editor />
                  <BottomBar />
                </>
              }
            />
          ))}

          <Route
            path="/templates"
            element={
              <>
                <LoggedinBar />
                <LoggedoutTemplates />
                <BottomBar />
              </>
            }
          />
          <Route
            path="/designs"
            element={
              <>
                <LoggedinBar />
                <Designs />
                <BottomBar />
              </>
            }
          />
          <Route path="/tests" element={<Tests />} />
        </Routes>
      </Router>
    </SVGProvider>
  </React.StrictMode>,
);

function sendToGoogleAnalytics({ id, name, value, delta }) {
  // Assumes the global `gtag()` function exists, see: https://developers.google.com/analytics/devguides/collection/gtagjs
  window.gtag("event", name, {
    event_category: "Web Vitals",
    value: Math.round(name === "CLS" ? delta * 1000 : delta), // ensure values are in milliseconds
    event_label: "G-3WJ3LQFWH8", // use the id as the event label
    non_interaction: true, // mark as non-interactive to avoid affecting bounce rate
  });
}
reportWebVitals(sendToGoogleAnalytics);
