import { ListSubheader, MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import WebFont from "webfontloader";

export function FontsList({ onChange, fonts, index }) {
  useEffect(() => {
    // maybe once these are loaded, you can keep loading a few more?
    WebFont.load({
      google: {
        families: fonts,
      },
    });
  }, [fonts]);

  return (
    <Select size="small" id="select" value={index} onChange={onChange} sx={{ padding: "2px 4px" }}>
      {fonts.map((font, idx) => {
        if (font.type === "divider") {
          return <ListSubheader sx={{ textAlign: "center", fontWeight: "bold", fontSize: "1.1rem" }}>{font}</ListSubheader>;
        }
        return (
          <MenuItem key={font} value={idx}>
            <div style={{ justifyContent: "space-between", width: "100%", fontFamily: font }}>{font}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
}
