import { ImageItem } from "../../interfaces/ImageItem";

export function parseImage(child) {
  let props = {};
  for (let attr of child.attributes) {
    let key = attr.name;
    key = key.replace("xlink:", "");
    props[key] = attr.value;
  }
  let item = new ImageItem({
    ...props,
  });

  return item;
}
