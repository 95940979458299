// @ts-nocheck

import { LinearGradient } from "./elements/LinearGradient";
import { RadialGradient } from "./elements/RadialGradient";
import { parseLinearGradient } from "./parseLinearGradient";

function parseImport(elem) {
  // check if elem has a style
  if (elem.tagName !== "style") return;
  const style = elem.textContent;
  const matches = style.match(/@import url\((.*)\);/);
  if (matches && matches[1]) {
    matches[1] = matches[1].replace(/['"]+/g, "");
    return { type: "import", url: matches[1] };
  }
}
export function parseDefs(svgElement) {
  if (svgElement.tagName !== "defs") return;
  const defs = [];
  const childElements = svgElement.children;
  for (let i = 0; i < childElements.length; i++) {
    const child = childElements[i];

    let elem = LinearGradient.from_svg(child) || RadialGradient.from_svg(child) || parseImport(child);
    if (elem) {
      defs.push(elem);
    }
  }
  return defs;
}
