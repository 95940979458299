// @ts-nocheck

import ArcSegment from "../segments/ArcSegment";
import BezierSegment from "../segments/BezierSegment";
import LineSegment from "../segments/LineSegment";
import PathSegment from "../segments/PathSegment";
import { SeperatorSegment } from "../segments/SeperatorSegment";

export function createSegment(type, p1, p2, args) {
  if (type === "line") {
    return new LineSegment(type, p1, p2, args);
  }
  if (type === "arc") {
    return new ArcSegment(type, p1, p2, args);
  }
  if (type === "path") {
    return new PathSegment(type, p1, p2, args);
  }
  if (type == "bezier") {
    return new BezierSegment(type, p1, p2, args);
  }
  if (type == SeperatorSegment.type) {
    return new SeperatorSegment();
  }
  console.log("Unknown segment type: ", type);
}
