// @ts-nocheck

import { BezierItem } from "../interfaces/BezierItem";
import BezierSegment from "../segments/BezierSegment";
import { splitBezierCurveV2 } from "./BezierSplitUtils";
import { arrangeSegmentsForMergedPath, initEllipsePoint, segmentsToPathData } from "./SegmentUtils";
import { segmentsToPoints } from "./bezierUtils";
import { rotateVector } from "./intersectEE";
import { transform } from "./transformUtils";
import { dup, isEqual, normalize, ppt, toDegree, toRadians } from "./utils";

export function arcToBezier(x1, y1, rx, ry, xAxisRotation, largeArc, sweep, x2, y2, sequence = [], isClosed = false) {
  let c = computeArcCenter(x1, y1, rx, ry, xAxisRotation, largeArc, sweep, x2, y2);
  let params = {
    c,
    rx,
    ry,
  };

  let angle = toRadians(xAxisRotation);

  let startRad = normalize(Math.atan2((y1 - c.y) * rx, (x1 - c.x) * ry));
  let endRad = normalize(Math.atan2((y2 - c.y) * rx, (x2 - c.x) * ry));

  startRad = normalize(startRad - angle);
  endRad = normalize(endRad - angle);
  let start = { x: c.x + rx * Math.cos(startRad), y: c.y + ry * Math.sin(startRad) };
  let end = { x: c.x + rx * Math.cos(endRad), y: c.y + ry * Math.sin(endRad) };
  x1 = start.x;
  y1 = start.y;
  x2 = end.x;
  y2 = end.y;

  let direction = sweep == 0 || sweep == "0" ? -1 : 1;
  const pi = Math.PI;
  let rads = [0, pi / 2, pi, (3 * pi) / 2, startRad, endRad];

  // dedup rads
  rads = Array.from(new Set(rads));
  // sort rads
  rads.sort((a, b) => a - b);

  const eps = 0.0001;
  let idxStartRad = rads.findIndex((x) => Math.abs(x - startRad) < eps);
  let idxEndRad = rads.findIndex((x) => Math.abs(x - endRad) < eps);
  let segments = [];

  if (Math.abs(idxStartRad - idxEndRad) == 1) {
    // they are in the same quadrant
    const mn = Math.min(idxStartRad, idxEndRad),
      mx = Math.max(idxStartRad, idxEndRad);
    let previ = (mx + 4) % 5;
    let nexti = (mn + 1) % 5;
    while (nexti == idxEndRad || nexti == idxStartRad) {
      nexti = (nexti + 1) % 5;
    }
    while (previ == idxEndRad || previ == idxStartRad) {
      previ = (previ + 4) % 5;
    }

    let arc = getQuarterArc(rads[previ], rads[nexti], params).arc; // FIXME: missing direction?
    const o = splitBezierCurveV2(arc, start);
    let o2 = idxStartRad < idxEndRad ? o.curve2 : o.curve1;
    o2 = splitBezierCurveV2(o2, end);
    o2 = idxStartRad < idxEndRad ? o2.curve1 : o2.curve2;
    // segments.push(o2);
    console.log("no segment for this case?");
  } else {
    // they are in different quadrants
    let seg1, seg2;
    for (let i = idxStartRad; i != idxEndRad; ) {
      let ni = (i + direction + rads.length) % rads.length;

      if (i == idxStartRad) {
        let previ, nexti;
        if (isEqual(startRad, 0) || isEqual(startRad, pi / 2) || isEqual(startRad, pi) || isEqual(startRad, (3 * pi) / 2)) {
          previ = i;
        } else {
          previ = (i - direction + rads.length) % rads.length;
        }
        nexti = (i + direction + rads.length) % rads.length;

        let q = getQuarterArc(rads[previ], rads[nexti], params, direction);
        let arc = q?.arc;

        const o = splitBezierCurveV2(arc, { x: x1, y: y1 });
        seg1 = o.curve2;
        if (q.flipped) {
          seg1 = o.curve1;
        }
        segments.push(seg1);
      } else if (ni == idxEndRad) {
        let previ, nexti;
        previ = (ni - direction + rads.length) % rads.length;
        if (isEqual(endRad, 0) || isEqual(endRad, pi / 2) || isEqual(endRad, pi) || isEqual(endRad, (3 * pi) / 2)) {
          nexti = ni;
        } else {
          nexti = (ni + direction + rads.length) % rads.length;
        }
        let q = getQuarterArc(rads[previ], rads[nexti], params, direction);
        let arc = q?.arc;

        const o = splitBezierCurveV2(arc, { x: x2, y: y2 });
        seg2 = o.curve1;
        if (q.flipped) {
          seg2 = o.curve2;
        }
        segments.push(seg2);
        break;
      } else {
        let arc = getQuarterArc(rads[i], rads[ni], params, direction).arc;
        segments.push(arc);
      }
      i = ni;
    }
  }
  const oldLen = segments.length;
  segments = arrangeSegmentsForMergedPath(segments)[0]?.segments;
  if (!segments) return;

  if (segments.length != oldLen) {
    console.log(arguments);
  }
  let item = new BezierItem({ points: segmentsToPoints(segments, isClosed) });
  item.segments = segments;
  item.center = item.getCenter();
  item.strokeWidth = 1;
  if (xAxisRotation) {
    item = transform(item, { type: "rotate", angle: angle + 0.1, center: c });
  }
  const arcD = `M ${x1} ${y1} A ${rx} ${ry} ${xAxisRotation} ${largeArc} ${sweep} ${x2} ${y2}`;

  return item;
}
function findQuadPoint(rad) {
  const eps = 0.0001;
  rad = normalize(rad);
  const rads = [(3 * Math.PI) / 2, 0, Math.PI / 2, Math.PI];
  for (let i = 0; i < rads.length; i++) {
    if (Math.abs(rads[i] - rad) < eps) {
      return i;
    }
  }
  return -1;
}
function getQuarterArc(startRad, endRad, params, direction) {
  let p1 = findQuadPoint(startRad),
    p2 = findQuadPoint(endRad);
  if (p1 == -1 || p2 == -1) {
    console.error("Error!");
    return null;
  }
  let pt1 = initEllipsePoint(p1, params);
  let pt2 = initEllipsePoint(p2, params);
  let flipped = false;
  if (p2 < p1 || (p2 == 3 && p1 == 0)) {
    let tmp = pt1;
    pt1 = pt2;
    pt2 = tmp;

    if (direction == 1) {
      pt1.handles = [pt1.handles[1], pt1.handles[0]];
      pt2.handles = [pt2.handles[1], pt2.handles[0]];
    }
    flipped = true;
  }

  return {
    arc: new BezierSegment("bezier", pt1, pt2),
    flipped,
  };
}

function computeArcCenter(x1, y1, rx, ry, xAxisRotation, largeArc, sweep, x2, y2) {
  const phi = toRadians(xAxisRotation);

  const dx = (x1 - x2) / 2;
  const dy = (y1 - y2) / 2;
  const x1_prime = Math.cos(phi) * dx + Math.sin(phi) * dy;
  const y1_prime = -Math.sin(phi) * dx + Math.cos(phi) * dy;

  const rx_sq = rx * rx;
  const ry_sq = ry * ry;
  const x1_prime_sq = x1_prime * x1_prime;
  const y1_prime_sq = y1_prime * y1_prime;

  let radicant = (rx_sq * ry_sq - rx_sq * y1_prime_sq - ry_sq * x1_prime_sq) / (rx_sq * y1_prime_sq + ry_sq * x1_prime_sq);
  radicant = Math.max(radicant, 0);
  const coef = (largeArc !== sweep ? 1 : -1) * Math.sqrt(radicant);

  const cx_prime = coef * ((rx * y1_prime) / ry);
  const cy_prime = coef * -((ry * x1_prime) / rx);

  const cx = Math.cos(phi) * cx_prime - Math.sin(phi) * cy_prime + (x1 + x2) / 2;
  const cy = Math.sin(phi) * cx_prime + Math.cos(phi) * cy_prime + (y1 + y2) / 2;

  return { x: cx, y: cy };
}
