// @ts-nocheck
import React, { memo, useEffect } from "react";
import { Tab, Tabs, Box, IconButton, Tooltip } from "@mui/material";
import BrushDetails from "./details/BrushDetails";
import GeometryDetails from "./details/GeometryDetails";
import ImageDetails from "./details/ImageDetails";
import { useSVG } from "./SVGContext";
import LayersGroup from "./LayersGroup";
import LayersIcon from "@mui/icons-material/Layers";
import LibraryIcon from "@mui/icons-material/AccountBalance";
import HistoryIcon from "@mui/icons-material/History";
import ExportIcon from "@mui/icons-material/ExitToApp";
import ApperanceIcon from "@mui/icons-material/Palette";
import AlignIcon from "@mui/icons-material/FormatAlignLeft";
import { DefsPanel } from "./DefsPanel";
import DefsIcon from "@mui/icons-material/Texture";
import StrokeIcon from "@mui/icons-material/ShowChart";
import GradientEditor from "./details/GradientEditor";
import DataIcon from "@mui/icons-material/Dataset";
import LayerList from "./LayerList";
import AlignPanel from "./details/AlignPanel";
import { Library } from "./details/Library";
import { DataPanel } from "./details/DataPanel";
import ExportDetails from "./dialogs/ExportDetails";
import FontIcon from "@mui/icons-material/Title";
import FillOpacityDetails from "./details/FillOpacityDetails";
import ChartDetails from "./details/ChartDetails";
import ChartIcon from "@mui/icons-material/BarChart";
import StrokeDetails from "./details/StrokeDetails";
import { cap } from "./utils/utils";
import IconSearch from "./library/IconSearch";
import TemplateSearch from "./library/TemplateSearch";
import { ShapesPanel } from "./library/ShapesPanel";

function History() {
  const { historyList, setHistoryList } = useSVG();
  return (
    <div>
      {historyList
        .slice(0)
        .reverse()
        .map((item, index) => (
          <div key={index}>{item.msg}</div>
        ))}
    </div>
  );
}

const RightPanel = memo(() => {
  const { selectedIds, rightPanel, setRightPanel, label, items } = useSVG();

  const panels = [
    // {
    //   name: "align",
    //   icon: <AlignIcon />,
    //   tooltip: label("Align"),
    // },
    // {
    //   name: "layers",
    //   icon: <LayersIcon />,
    //   tooltip: label("Objects"),
    // },
    {
      name: "shapes",
      icon: <LibraryIcon />,
      tooltip: label("Shapes"),
    },
    {
      name: "templates",
      icon: <LibraryIcon />,
      tooltip: label("Templates"),
    },
    {
      name: "icons",
      icon: <LibraryIcon />,
      tooltip: label("Icons"),
    },
    // {
    //   name: "history",
    //   icon: <HistoryIcon />,
    //   tooltip: label("History"),
    // },
  ];

  const panelStyle = {
    display: rightPanel ? "block" : "none",
    width: "300px",

    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    borderRight: "solid 2px rgb(201, 200, 200)",
  };

  const panel = panels.find((it) => it.name === rightPanel);

  return (
    <>
      <div
        className="RightPanelToolbar toolbar-column"
        style={{
          backgroundColor: "rgb(249 249 249)",
          justifySelf: "flex-end",
        }}
      >
        {panels.map(
          (it, index) =>
            !it.disabled && (
              <div key={index}>
                {
                  <Tooltip key={"tooltip_" + index} title={it.tooltip + (it.disabled ? " - Coming Soon" : "")} placement="right-end" TransitionProps={{ timeout: 0 }}>
                    <IconButton
                      className={"rightpanel-button " + (it.name === rightPanel ? "selected" : "")}
                      disableRipple
                      disabled={it.disabled}
                      style={{
                        opacity: it.disabled ? 0.1 : 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,

                        width: "100%",
                      }}
                      onClick={() => {
                        setRightPanel(rightPanel == it.name ? null : it.name);
                      }}
                    >
                      {it.icon}
                      <span
                        style={{
                          fontSize: "0.5em",
                        }}
                      >
                        {cap(it.name)}
                      </span>
                    </IconButton>
                  </Tooltip>
                }
              </div>
            ),
        )}
      </div>

      {rightPanel && (
        <div key={selectedIds.join(",") + rightPanel} className="RightPanelDetails" style={panelStyle}>
          {rightPanel == "templates" && <TemplateSearch />}
          {rightPanel == "icons" && <IconSearch />}
          {rightPanel == "shapes" && <ShapesPanel />}
        </div>
      )}
    </>
  );
});

export default RightPanel;
