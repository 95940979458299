// @ts-nocheck

import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

const Eyedropper = createSvgIcon(
    <svg viewBox="0 0 512 511.991" >
        <path d="M432.081 190.621l53.305-53.304c32.671-32.671 36.702-82.099 4.046-114.754l-.001-.001c-32.655-32.655-82.097-28.608-114.754 4.047l-53.304 53.304L304.16 62.7c-26.726-26.726-67.128 13.679-40.403 40.403l15.277 15.275c-77.89 77.885-93.102 93.088-191.926 191.912-33.533 33.533-27.587 78.622-47.833 112.04-8.719 14.393-17.943 20.481-29.155 31.692-13.352 13.354-13.513 35.043-.354 48.202l.002.001c13.159 13.159 34.848 12.999 48.201-.355 11.353-11.352 15.735-18.791 29.776-27.952 33.317-21.73 79.775-14.854 113.956-49.035 98.982-98.977 113.035-113.039 191.918-191.919l15.272 15.272c26.724 26.725 67.129-13.676 40.403-40.403l-17.213-17.212zM275.284 322.599c-44.743-10.923-96.383 10.319-136.526-35.253l154.623-154.623 85.889 85.89-103.986 103.986z" fillRule="evenodd" clipRule="evenodd" stroke="currentColor" fill="currentColor" />
    </svg>,
    "eyedropper"
);

export default Eyedropper;
