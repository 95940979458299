import React, { memo, useEffect, useState } from "react";
import { TextField, IconButton, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSVG } from "../SVGContext";
import LazyImage from "./LazyImage";
import { TEMPLATE_CATEGORIES } from "../values/TemplateCategories";

const TemplateSearch = memo(() => {
  const { loadRawSVG, getHeaders } = useSVG();
  let [category, setCategory] = useState();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = async () => {
    try {
      const cat = category || "Infographics";
      const response = await axios.get(`https://api.movingpencils.com/templates?category=${cat.toLowerCase()}&q=${query}`, { headers: getHeaders() });
      setResults([...response.data]);
    } catch (error) {
      setResults([]);
      console.error("Error fetching search results:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const onClick = async (id, title) => {
    // fetch detail from https://api.movingpencils.com/template?id=${id}
    try {
      const response = await axios.get(`https://api.movingpencils.com/template?id=${id}`, { headers: getHeaders() });
      // FIXME: if the previous request is pending, cancel it

      loadRawSVG(response.data.svg, { title, templateId: id });
    } catch (error) {
      console.error("Error fetching template detail:", error);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", padding: "0 20px" }}>
        <Autocomplete
          fullWidth
          freeSolo
          options={TEMPLATE_CATEGORIES}
          inputValue={query}
          onInputChange={(event, newValue) => {
            if (TEMPLATE_CATEGORIES.includes(newValue)) {
              category = newValue;
              setCategory(category);
              setQuery(newValue);
              handleSearch();
            } else {
              setQuery(newValue);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth variant="outlined" onKeyPress={handleKeyPress} placeholder="Search Templates" />}
        />

        <IconButton onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      </div>

      <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", overflowY: "scroll" }}>
        {results.map((result, j) => (
          <div
            key={j}
            style={{
              width: "100%",
              height: "fit-content",
              position: "relative",
              margin: "20px 20px 0 20px",
            }}
          >
            <LazyImage
              key={result.id}
              src={`https://s3.amazonaws.com/thumbnails.venngage.com/template/${result.id}.png`}
              alt={result.title}
              style={{ width: "100%", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", border: "1px solid #e0e0e0" }}
              onClick={() => {
                onClick(result.id, result.title);
              }}
            />
          </div>
        ))}
      </div>
    </>
  );
});
export default TemplateSearch;
