import React, { useState, useEffect, useRef, useCallback } from "react";

const LazyImage = ({ src, alt, style, onClick, svg, minHeight = 200 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef();

  const handleIntersection = useCallback((entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });
    if (imageRef.current) {
      observer.observe(imageRef.current);
    }
    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [handleIntersection]);

  return (
    <div
      ref={imageRef}
      style={{
        width: "100%",
        height: "fit-content",
        padding: 5,
      }}
    >
      {isVisible && !svg && <img src={src} alt={alt} style={style} onClick={onClick} />}
      {isVisible && svg && <div dangerouslySetInnerHTML={{ __html: svg }} alt={alt} style={style} onClick={onClick} />}
    </div>
  );
};

export default LazyImage;
