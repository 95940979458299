import axios from "axios";

export const createDesignServer = async (designData, headers, cb) => {
  try {
    const response = await axios.post(
      "https://api.movingpencils.com/designs",
      {
        design_json: designData,
      },
      {
        headers: headers,
      },
    );
    console.log("Design saved successfully!");
    cb(response.data);
  } catch (error) {
    console.log("Error creating design.");
    console.error(error);
  }
};

export const updateDesignServer = async (designId, designData, headers, cb) => {
  try {
    const response = await axios.put(
      `https://api.movingpencils.com/designs/${designId}`,
      {
        design_json: designData,
      },
      {
        headers: headers,
      },
    );
    console.log("Design updated successfully!");
    cb(response.data);
  } catch (error) {
    console.log("Error updating design.");
    console.error(error);
  }
};
