// @ts-nocheck
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RectangleIcon from "@mui/icons-material/Rectangle";
import CircleIcon from "@mui/icons-material/Circle";
import HexagonIcon from "@mui/icons-material/Hexagon";
import StarIcon from "@mui/icons-material/Star";
import HeartIcon from "@mui/icons-material/Favorite";
import LineIcon from "@mui/icons-material/HorizontalRule";
// import Line from "./icons/Line";
import Arrow from "@mui/icons-material/ArrowRight";
import { ShapeType } from "../values/enums";

const shapesArrowStyle = {
  position: "absolute",
  bottom: 0,
  right: "-6px",
  transform: "rotate(45deg) scale(0.7)",
};

const ShapesDropdown: React.FC = ({ onShapeSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedShape, setSelectedShape] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShapeSelect = (shape: string) => {
    setSelectedShape(shape);
    handleClose();
    onShapeSelect(shape);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <RectangleIcon style={{ width: "90%" }} />
        <Arrow style={shapesArrowStyle} />
      </IconButton>
      <Menu id="shapes-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleShapeSelect(ShapeType.Rectangle)}>
          <RectangleIcon className="shapesmenu-icon" /> Rectangle
        </MenuItem>
        <MenuItem onClick={() => handleShapeSelect(ShapeType.Circle)}>
          <CircleIcon className="shapesmenu-icon" /> Circle
        </MenuItem>
        <MenuItem onClick={() => handleShapeSelect(ShapeType.Polygon)}>
          <HexagonIcon className="shapesmenu-icon" /> Polygon
        </MenuItem>
        <MenuItem onClick={() => handleShapeSelect(ShapeType.Star)}>
          <StarIcon className="shapesmenu-icon" /> Star
        </MenuItem>
        <MenuItem onClick={() => handleShapeSelect(ShapeType.Line)}>
          <LineIcon className="shapesmenu-icon" /> Line
        </MenuItem>
        <MenuItem onClick={() => handleShapeSelect()}>
          <HeartIcon className="shapesmenu-icon" /> More Shapes
        </MenuItem>
      </Menu>
    </>
  );
};

export default ShapesDropdown;
