// @ts-nocheck
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RectangleIcon from "@mui/icons-material/Rectangle";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import CircleIcon from "@mui/icons-material/Circle";
import HexagonIcon from "@mui/icons-material/Hexagon";
import StarIcon from "@mui/icons-material/Star";
import HeartIcon from "@mui/icons-material/Favorite";
import LineIcon from "@mui/icons-material/HorizontalRule";
import Arrow from "@mui/icons-material/ArrowRight";
import { ChartType } from "../values/enums";

const typesArrowStyle = {
  position: "absolute",
  bottom: 0,
  right: "-6px",
  transform: "rotate(45deg) scale(0.7)",
};

const ChartsDropDown: React.FC = ({ onChartSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedChart, setSelectedChart] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type: string) => {
    setSelectedChart(type);
    handleClose();
    onChartSelect(type);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <BarChartIcon style={{ width: "90%" }} />
        <Arrow style={typesArrowStyle} />
      </IconButton>
      <Menu id="types-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleSelect(ChartType.column)}>
          <BarChartIcon className="typesmenu-icon" /> Column
        </MenuItem>
        <MenuItem onClick={() => handleSelect(ChartType.bar)}>
          <BarChartIcon className="typesmenu-icon" sx={{
            transform: "rotate(90deg)",
          }}/> Bar
        </MenuItem>
        <MenuItem onClick={() => handleSelect(ChartType.pie)}>
          <PieChartIcon className="typesmenu-icon" /> Pie
        </MenuItem>
      </Menu>
    </>
  );
};

export default ChartsDropDown;
