import React, { useState, useEffect } from "react";
import "../renderer/css/Gallery.css";
import LazyImage from "../renderer/library/LazyImage";

function cleanAltText(altText) {
  if (!altText) return "";
  return altText.replace(/\[.*\]/, "").replace(/r\/infographics -/i, "");
}

const Gallery = ({ data, onClick }) => {
  const itemsPerPage = 10;
  const [loadedItems, setLoadedItems] = useState(itemsPerPage);
  const [columns, setColumns] = useState(3);

  const handleScroll = () => {
    const bottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    if (bottom) {
      setLoadedItems((prevLoadedItems) => prevLoadedItems + itemsPerPage);
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setColumns(1);
    } else if (window.innerWidth <= 1024) {
      setColumns(2);
    } else {
      setColumns(3);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getColumns = () => {
    const columnArray = Array.from({ length: columns }, () => []);
    Object.entries(data)
      .slice(0, loadedItems)
      .forEach(([id, props], index) => {
        columnArray[index % columns].push(
          <div key={id} className="gallery-item" onClick={() => onClick(id)}>
            <LazyImage
              src={`https://s3.amazonaws.com/thumbnails.venngage.com/template/${props.id}.png`}
              alt={cleanAltText(props.title)}
              style={{ width: "100%", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", border: "1px solid #e0e0e0" }}
              onClick={() => {
                onClick(props.id);
              }}
            />

            <div className="alt-text" style={{ cursor: "pointer" }}>
              {"Use Template"}
            </div>
          </div>,
        );
      });
    return columnArray;
  };

  return (
    <div className="gallery-container" style={{ overflowY: "scroll" }}>
      <div className="gallery-grid">
        {getColumns().map((columnItems, index) => (
          <div key={index} className="gallery-column">
            {columnItems}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
