// @ts-nocheck
let debug = false;

export const ROOT_GROUP_ID = "group-root";

export const COPY_PROPS = ["fill", "gradient", "stroke", "strokeWidth", "strokeOpacity", "strokeDasharray", "strokeLinecap", "strokeLinejoin", "strokeMiterlimit", "opacity"];

export const constants = {
  debug: debug,
  shapeBuilder: debug
    ? { dRad: 0.05, d: 0.1, animationInterval: 700, bisectorLineDist: 1000 }
    : {
        dRad: 0.001,
        d: 0.1,
        animationInterval: 700,
        bisectorLineDist: 1000,
      },
  pathFinder: { extraPointsDist: 5 },

  SAMPLE_TEXT: "Text",
};

export const RIGHT_PANEL_WIDTH_COLLAPSED = 50;
export const RIGHT_PANEL_WIDTH_EXPANDED = 220;

export const animatableProps = [
  {
    name: "Opacity",
    action: "opacity",
    defaultValue: 1,
    type: "number",
  },
  {
    name: "X",
    action: "x",
    defaultValue: 0,
    type: "number",
  },
  {
    name: "Y",
    action: "y",
    defaultValue: 0,
    type: "number",
  },
  {
    name: "Rotate",
    action: "rotate",
    type: "number",
  },
  {
    name: "Move Along Path",
    action: "moveAlongPath",
    type: "percentage",
  },
];

// // @ts-nocheck
// let debug = true;

// export const constants = {
// 	debug: debug,
// 	shapeBuilder: debug
// 		? { dRad: 0.05, d: 0.1, animationInterval: 700, bisectorLineDist: 1000 }
// 		: {
// 				dRad: 0.001,
// 				d: 0.1,
// 				animationInterval: 400,
// 				bisectorLineDist: 1000,
// 		  },
// 	pathFinder: { extraPointsDist: 1, isPointOnItTolerance: 1 },
// };
