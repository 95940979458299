// @ts-nocheck
import React, { useEffect, useState, useCallback } from "react";
import { Container, CircularProgress } from "@mui/material";
import { useSVG } from "./SVGContext";
import { cachedSVGs } from "./TemplatesCache";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const cats = {
  infographics: ["infographics"],
  "social-media": ["facebook", "instagram", "linkedin", "pinterest", "tumblr", "twitter", "youtube"],
  logos: ["graphic-logo", "text-logo"],
  other: ["twitch"],
};
const categories = ["facebook", "graphic-logo", "instagram", "linkedin", "pinterest", "text-logo", "tumblr", "twitch", "twitter", "youtube"];

const fetchSVGs = async (category, page) => {
  const response = await fetch(`https://movingvectors.s3.amazonaws.com/templates/${category}_${page}.json`);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.svgs;
};

const templateButtonDim = 70;
const templateCategoryButton = {
  width: templateButtonDim,
  height: templateButtonDim,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #ccc",
  borderRadius: 5,
  marginBottom: 10,
};

function toReadableString(catName) {
  return catName.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
}

const TemplateCategories = ({ onClose }) => {
  const { loadRawSVG } = useSVG();
  const [svgData, setSvgData] = useState({ ...cachedSVGs });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [iframeWidth, setIframeWidth] = useState(0);
  const [iframeHeight, setIframeHeight] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const gridRef = React.useRef(null);
  const w = 500;

  const handleClick = async (category) => {
    if (category === "blank") {
      onClose();
      return;
    }
    if (!svgData[category]) {
      setPage(0);
      await fetchAndSetSVGs(category, 0);
    }
    setSelectedCategory(category);
  };

  function getIframeDimensions(rawSvg) {
    const s = rawSvg.match(/viewBox="([^"]*)"/);
    let x, y, width, height;
    if (!s) {
      width = rawSvg.match(/width="([^"]*)"/)[1];
      height = rawSvg.match(/height="([^"]*)"/)[1];
    } else {
      const viewbox = s[1];
      [x, y, width, height] = viewbox.split(" ");
    }
    const aspectRatio = +width / +height;
    const h = w / aspectRatio;
    return { width: +w, height: +h };
  }

  useEffect(() => {
    // go over the cache and fix the dimensions of each svg
    for (const category in svgData) {
      for (const key in svgData[category]) {
        let rawSvg = svgData[category][key];
        const dims = getIframeDimensions(rawSvg);
        rawSvg = `<style> body { overflow: hidden; margin: 0 }</style>` + rawSvg;
        svgData[category][key] = rawSvg.replace(/width="[^"]*"/, "").replace(/height="[^"]*"/, "");
      }
      setSvgData({ ...svgData });
    }
  }, []);

  const fetchAndSetSVGs = async (category, page) => {
    setLoading(true);
    try {
      const svgs = await fetchSVGs(category, page);

      const dims = getIframeDimensions(svgs[Object.keys(svgs)[0]]);

      setIframeWidth(dims.width);
      setIframeHeight(dims.height);

      for (const key in svgs) {
        // delete width and height
        // svgs[key] = svgs[key].replace(/width="[^"]*"/, `width="${dims.width}px"`).replace(/height="[^"]*"/, `height="${dims.height}px"`);
        // add <style> body { overflow: hidden; margin: 0 }</style>
        svgs[key] = `<style> body { overflow: hidden; margin: 0 }</style>` + svgs[key];
        svgs[key] = svgs[key].replace(/width="[^"]*"/, "").replace(/height="[^"]*"/, "");
      }

      setSvgData((prevSvgData) => ({
        ...prevSvgData,
        [category]: {
          ...prevSvgData[category],
          ...svgs,
        },
      }));
    } catch (error) {
      console.error("Error fetching SVGs:", error);
    }
    setLoading(false);
  };

  const loadMoreSVGs = useCallback(async () => {
    if (selectedCategory) {
      const nextPage = page + 1;
      await fetchAndSetSVGs(selectedCategory, nextPage);
      setPage(nextPage);
    }
  }, [page, selectedCategory]);

  const handleScroll = () => {
    if (gridRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = gridRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && !loading) {
        loadMoreSVGs();
      }
    }
  };

  const onClick = (e) => {
    const iframe = e.target.parentElement.querySelector("iframe");
    const svg = iframe.contentDocument.querySelector("svg");
    const rawSvg = new XMLSerializer().serializeToString(svg);
    loadRawSVG(rawSvg);
    onClose();
  };

  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (gridRef.current) {
        gridRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Container sx={{ overflowY: "auto", paddingTop: 2, height: "80vh" }} ref={gridRef}>
      {!selectedCategory &&
        Object.keys(cats).map((mainCat) => (
          <div>
            <div style={{ mainCatStyle }}>{toReadableString(mainCat)}</div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(" + templateButtonDim + "px, 1fr))",
                gap: 10,
              }}
            >
              {cats[mainCat].map((cat) => (
                <div key={cat}>
                  <div style={templateCategoryButton} onClick={() => handleClick(cat)}>
                    <a style={{ fontSize: "2rem", cursor: "pointer" }}>+</a>
                    <div style={{ fontSize: "0.8rem", cursor: "pointer" }}>{toReadableString(cat)}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      {selectedCategory && (
        <span style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: 5, padding: 5 }}>
          {<ArrowBackIcon />}
          <a onClick={() => setSelectedCategory(null)} style={{ cursor: "pointer", fontWeight: "bold" }}>
            {toReadableString(selectedCategory)}
          </a>
        </span>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(" + w + "px, 1fr))",
          marginTop: "30px",
          gap: 10,
          maxHeight: "70vh",
        }}
      >
        {selectedCategory &&
          svgData[selectedCategory] &&
          Object.keys(svgData[selectedCategory]).map((key, index) => {
            let dims;
            if (iframeHeight) {
              dims = { width: iframeWidth + "px", height: iframeHeight + "px" };
            } else {
              dims = getIframeDimensions(svgData[selectedCategory][key]);
              dims.width = dims.width + 30 + "px";
              dims.height = dims.height + 30 + "px";
            }
            return (
              <div key={index} style={{}}>
                <div className="iframe-edit-container">
                  <a
                    className="iframe-edit"
                    onClick={onClick}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                  ></a>
                  <iframe style={{ ...dims, border: "none" }} srcDoc={svgData[selectedCategory][key]} title={`${selectedCategory}-${index}`} />
                </div>
              </div>
            );
          })}

        {loading && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <CircularProgress />
          </div>
        )}
      </div>
    </Container>
  );
};

export default TemplateCategories;

const mainCatStyle = {};
