// @ts-nocheck

import { ShapeInfo } from "kld-intersections";
import { COPY_PROPS, constants } from "../values/constants";
import { animateSequence } from "./DebugUtils";
import { arrangeSegmentsForMergedPath, initSegments, segmentizeUsingIntersections } from "./SegmentUtils";
import { findEnclosureV3 } from "./ShapeBuilderUtils";
import { segmentsToPoints } from "./bezierUtils";
import { convertSvgCoordsToAbsolute, copyProps, dedupPoints, pointAt, toDegree, toRadians } from "./utils";
import { BezierItem } from "../interfaces/BezierItem";

export const pathfind = async ({ type, items, groups, selectedIds, svgRef, setSelectedIds, setItems, setGroups, setIntersections, deleteItems, setMultiSelectBox, idGenerator }) => {
  if (selectedIds.length != 2) return;
  type = type.replace("pathfinder_", "");
  let sequence = [];

  let a = items[selectedIds[0]],
    b = items[selectedIds[1]];
  if (selectedIds[0] > selectedIds[1]) {
    let tmp = a;
    a = b;
    b = tmp;
  }
  if (a.shapeBuilderIgnore || !a.segments || b.shapeBuilderIgnore || !b.segments) return;

  items = segmentizeUsingIntersections(items, selectedIds).items;
  a = items[selectedIds[0]];
  b = items[selectedIds[1]];

  function insertIfNecessary(arr, it) {
    if (arr.findIndex((x) => x.equals(it)) == -1) {
      arr.push(it);
    }
    return arr;
  }
  const enc = findEnclosureV3(items, selectedIds, svgRef, [], setIntersections);
  const finder = enc.finder;
  const translatedSegments = enc.translatedSegments;

  // Gotcha: findEnclosureV3 changes item.tempSegments[] by attaching translatedSegments to it.
  // How to make it persistent? Idk. That's why I'm doing setItems here.
  setItems({ ...items });

  let ints = gatherPointsAfterSegmentize([a, b], sequence);
  let common = [],
    item1Only = [],
    item2Only = [],
    outside = [];
  let commonPoints = [],
    item1OnlyPoints = [],
    item2OnlyPoints = [],
    outsidePoints = [];
  for (let i = 0; i < ints.length; i++) {
    const { x, y } = ints[i];
    const isInsideA = isPointInsideItem(svgRef, { x, y }, a);
    const isInsideB = isPointInsideItem(svgRef, { x, y }, b);
    const intRoot = finder.findEnclosureRoot(ints[i], translatedSegments);
    if (isInsideA && isInsideB) {
      common = insertIfNecessary(common, intRoot);
      commonPoints.push(ints[i]);
    }
    if (isInsideA && !isInsideB) {
      item1Only = insertIfNecessary(item1Only, intRoot);
      item1OnlyPoints.push(ints[i]);
    }
    if (isInsideB && !isInsideA) {
      item2Only = insertIfNecessary(item2Only, intRoot);
      item2OnlyPoints.push(ints[i]);
    }
    if (!isInsideA && !isInsideB) {
      outside = insertIfNecessary(outside, intRoot);
      outsidePoints.push(ints[i]);
    }
  }
  common = common.map((x) => finder.children(x).map((x) => x.segment));
  item1Only = item1Only.map((x) => finder.children(x).map((x) => x.segment));
  item2Only = item2Only.map((x) => finder.children(x).map((x) => x.segment));
  outside = outside.map((x) => finder.children(x).map((x) => x.segment));

  for (let c of [...common, ...item1Only, ...item2Only, ...outside]) {
    // for (let c of [...item1Only]) {
    c.arranged = arrangeSegmentsForMergedPath(c)[0];
    if (!c.arranged.isClosed) {
      console.log("arrangement seems to have failed");
      // return;
    }
  }

  let itemsToAdd = [];
  if (type === "unite") {
    itemsToAdd = [...outside];
  } else if (type === "minusFront") {
    // TODO: item2 is entirely within item1 is unhandled
    itemsToAdd = [...item1Only];
  } else if (type === "divide") {
    itemsToAdd = [...common, ...item1Only, ...item2Only];
  } else if (type === "exclude") {
    itemsToAdd = [...item1Only, ...item2Only];
  } else if (type === "intersect") {
    itemsToAdd = [...common];
  }

  itemsToAdd = itemsToAdd.filter((x) => x.arranged.isClosed);

  if (itemsToAdd.length > 0) {
    let newSelectedIds = [];
    for (let source of itemsToAdd) {
      const points = segmentsToPoints(source.arranged.segments, source.arranged.isClosed);
      let item = new BezierItem({ points, isClosed: source.arranged.isClosed, id: idGenerator.id("path") });
      item.segments = source.arranged.segments;
      item = copyProps(item, a);

      item.group = a.group;
      groups[item.group].children.push(item.id);

      items[item.id] = item;
      newSelectedIds.push(item.id);
    }

    deleteItems(selectedIds);

    setItems({ ...items });
    setGroups({ ...groups });
    setSelectedIds([...newSelectedIds]);
  }
  // animateSequence(sequence, items, setItems, setIntersections, setLines);
};
export function isPointOnSegment(translatedSegments, pt, sequence) {
  const ts = translatedSegments;

  for (let angle = 0; angle < 360; angle += 80) {
    const angleRadians = toRadians(angle);
    const p0 = pointAt(pt, 15, angleRadians),
      p1 = pointAt(pt, -15, angleRadians);
    const line0 = ShapeInfo.line({ p1: pt, p2: p0 }),
      line1 = ShapeInfo.line({ p1: pt, p2: p1 });
    const int00 = ts[0].intersectsKLDLine(line0).length > 0,
      int01 = ts[0].intersectsKLDLine(line1).length > 0,
      int10 = ts[1].intersectsKLDLine(line0).length > 0,
      int11 = ts[1].intersectsKLDLine(line1).length > 0;

    if (int00 && !int01 && int11 && !int10) {
      return true;
    }
    if (!int00 && int01 && !int11 && int10) {
      return true;
    }
  }
  return false;
}
export function gatherPointsAfterSegmentize(items, sequence) {
  // note: this happens after segmentizeUsingIntersections
  let points = [],
    allSegments = [];
  for (let it of items) {
    allSegments = [...allSegments, ...it.tempSegments.map((x) => x.segment).filter((x) => !x.isSeparator())];
  }
  for (let s of allSegments) {
    points = [...points, ...constructExtraPoints(s.p1, items), ...constructExtraPoints(s.p2, items)];
  }
  points = dedupPoints(points);

  // ignore points that lie ON segments
  points = points.filter((p) => !allSegments.find((s) => isPointOnSegment(s.translatedSegments, p, sequence)));

  return points;
}
export function filterPointsInsideItems(svgRef, points, items) {
  return points.filter((p) => {
    for (let it of items) {
      if (isPointInsideItem(svgRef, p, it)) return true;
    }
    return false;
  });
}
export function constructExtraPoints(p) {
  // find points around this point, at 30deg intervals
  let points = [];
  for (let angle = 0; angle < 360; angle += 77) {
    const angleRadians = (angle * Math.PI) / 180;
    const x = p.x + Math.cos(angleRadians) * constants.pathFinder.extraPointsDist;
    const y = p.y + Math.sin(angleRadians) * constants.pathFinder.extraPointsDist;
    points.push({ x, y });
  }
  return points;
}

function isPointInFill(svgRef, point, item) {
  const domItem = svgRef.current.getElementById(item.id);
  try {
    const pointObj = new DOMPoint(point.x, point.y);
    return domItem.isPointInFill(pointObj);
  } catch (e) {
    // Fallback for browsers that don't support DOMPoint as an argument
    const pointObj = svgRef.current.createSVGPoint();
    pointObj.x = point.x;
    pointObj.y = point.y;
    return domItem.isPointInFill(pointObj);
  }
}
function isPointInsideItem(svgRef, pt, item) {
  return isPointInFill(svgRef, pt, item); // this also seems imprecise, have to check more
  let { x, y } = convertSvgCoordsToAbsolute(svgRef, pt);
  const elems = document.elementsFromPoint(x, y); // imprecise
  for (let elem of elems) {
    const id = elem.getAttribute("id");
    if (item.id == id) return true;
  }
  return false;
}
