// @ts-nocheck
import { useState } from "react";
import { useSVG } from "../SVGContext";
import { Cursor } from "../values/enums";
import { getBoundingBox, isSamePoint, updateBoxes } from "../utils";
import Smartguide from "./Smartguide";
import { transform, transformPoint } from "../utils/transformUtils";
import { duplicate } from "../utils/ClipboardUtils";
import useMultiSelector from "./useMultiSelector";
import { getBoundingBoxOfDomElement, normalize, toDegree, toRadians } from "../utils/utils";
import { PointsOverlays } from "../components/PointsOverlays";
import { findAncestralTransform, getRotateCursor, getScaleCursor, getUnmovingPoint, isBottomRightOfSomeBoundingBox } from "../utils/PointerUtils";

function usePointer(provider) {
  const { setItems, svgRef, commands, setCursor, setOutline, setMultiSelectBox, snapItem, setSelectedIds, setReplacingIcon } = provider;

  // const multiSelector = useMultiSelector();

  let [dragging, setDragging] = useState(null);
  let [mouseDownPoint, setMouseDownPoint] = useState({ x: 0, y: 0 });
  let [mouseMovePoint, setMouseMovePoint] = useState({ x: 0, y: 0 });
  let [mouseWasDown, setMouseWasDownInternal] = useState(false);

  const setMouseWasDown = (v) => {
    mouseWasDown = v;
    setMouseWasDownInternal(v);
  };
  const onToolUnselect = () => {
    setOutline(null);
  };
  const onMouseDownNew = ({ event, xy, target, items, groups, selectedIds, cursor, outline, multiSelectBox }) => {
    setMouseWasDown(true);

    let sc = getScaleCursor(xy, selectedIds, multiSelectBox);
    if (sc) {
      if (sc == Cursor.ResizeN || sc == Cursor.ResizeS) {
        dragging = "scaleY";
      } else if (sc == Cursor.ResizeE || sc == Cursor.ResizeW) {
        dragging = "scaleX";
      } else if (sc == Cursor.ResizeNE || sc == Cursor.ResizeSW || sc == Cursor.ResizeNW || cursor == Cursor.ResizeSE) {
        dragging = "scale";
      }
      cursor = sc;
      setCursor(sc);
    } else if (getRotateCursor(xy, items, selectedIds, multiSelectBox)) {
      dragging = "rotate";
    } else {
      // if (
      //   multiSelector.onMouseDownNew({
      //     event,
      //     xy,
      //     target,
      //     items,
      //     groups,
      //     selectedIds,
      //     cursor,
      //     outline,
      //     multiSelectBox,
      //   })
      // ) {
      //   return;
      // }

      if (outline) {
        // set selection will happen when mouse is up
        return;
      }

      dragging = "translate";
    }
    setDragging(dragging);

    mouseDownPoint = xy;
    setMouseDownPoint(xy);

    mouseMovePoint = xy;
    setMouseMovePoint(xy);
  };

  const onMouseMoveNew = ({ event, xy, target, items, groups, defs, selectedIds, cursor, multiSelectBox, z }) => {
    if (!mouseWasDown) {
      let itemId;
      while (target) {
        itemId = target.getAttribute("mp_item_id");
        if (itemId) break;
        target = target.parentElement;
      }
      if (itemId) {
        let box = getBoundingBoxOfDomElement(svgRef, target);
        z.outline = {
          d: `M${box.x} ${box.y} h${box.width} v${box.height} h${-box.width} Z`,
          stroke: "blue",
          transform: findAncestralTransform(target),
          mp_item_id: target.getAttribute("mp_item_id"),
        };
        setOutline(z.outline);
      } else {
        z.outline = null;
        setOutline(null);
      }
      return;
    }
    dragging = "translate";
    setDragging(dragging);

    // if (
    //   multiSelector.onMouseMoveNew({
    //     event,
    //     xy,
    //     target,
    //     items,
    //     groups,
    //     selectedIds,
    //     cursor,
    //     multiSelectBox,
    //   })
    // ) {
    //   return;
    // }

    if (dragging) {
      snapItem({ xy, items });

      let transformation,
        minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;
      let rotateCenter = { x: 0, y: 0 };
      for (let i of selectedIds) {
        const it = items[i];

        rotateCenter.x += it.center?.x;
        rotateCenter.y += it.center?.y;
        const box = it.box;
        if (!box) continue;
        minX = Math.min(minX, box.x);
        minY = Math.min(minY, box.y);
        maxX = Math.max(maxX, box.x + box.width);
        maxY = Math.max(maxY, box.y + box.height);
      }
      rotateCenter.x /= selectedIds.length;
      rotateCenter.y /= selectedIds.length;
      let box = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
      if (minX > 1000000 || minY > 1000000 || maxX < -1000000 || maxY < -1000000) {
        box = null;
      }

      for (let i = 0; i < selectedIds.length; i++) {
        let selectedId = selectedIds[i];
        let item = items[selectedId];
        if (item.locked) continue;

        if (dragging.startsWith("scale")) {
          let signX = 1,
            signY = 1;
          if (cursor == Cursor.ResizeN || cursor == Cursor.ResizeNW || cursor == Cursor.ResizeNE) {
            signY = -1;
          }
          if (cursor == Cursor.ResizeW || cursor == Cursor.ResizeNW || cursor == Cursor.ResizeSW) {
            signX = -1;
          }
          let center = getUnmovingPoint(box, cursor);
          if (!center) {
            console.log("cannot transform");
            continue;
          }

          let xDiff = 1 + (signX * (xy.x - mouseMovePoint.x)) / 200;
          let yDiff = 1 + (signY * (xy.y - mouseMovePoint.y)) / 200;
          if (dragging == "scaleX") yDiff = 1;
          if (dragging == "scaleY") xDiff = 1;
          if (event.shiftKey) xDiff = yDiff = Math.max(xDiff, yDiff);
          transformation = { type: "scale", x: xDiff, y: yDiff, center };
        } else if (dragging == "rotate") {
          // GOTCHA & TODO: if this is a text path, then that path has to be translated/rotated
          // if (item.pathId) {
          //   item = defs.find((d) => d.id == item.pathId);
          // }

          let angle1 = Math.atan2(mouseMovePoint.y - rotateCenter.y, mouseMovePoint.x - rotateCenter.x);
          let angle2 = Math.atan2(xy.y - rotateCenter.y, xy.x - rotateCenter.x);
          let angle = (angle2 - angle1) / 2; // divide by something to rotate slowly

          if (event.shiftKey) {
            angle = toDegree(angle);
            angle = (angle + 405) % 360;
            angle = Math.round(angle / 45) * 45;
            angle = toRadians(angle);
          }

          transformation = { type: "rotate", center: rotateCenter, angle };
        } else if (dragging == "translate") {
          // GOTCHA & TODO: if this is a text path, then that path has to be translated/rotated
          // if (item.pathId) {
          //   item = defs.find((d) => d.id == item.pathId);
          // }
          transformation = {
            type: "translate",
            x: xy.x - mouseMovePoint.x,
            y: xy.y - mouseMovePoint.y,
          };
        }
        if (item.x != undefined && item.y != undefined) {
          let pt = transformPoint({ x: item.x, y: item.y }, transformation);
          item.x = pt.x;
          item.y = pt.y;
          items[item.id] = item;
        }
        // item = commands.Transform({ selectedId, transformation, items });
      }
      setItems({ ...items });
      setOutline(null);
      multiSelectBox = null;
      setMultiSelectBox(null);

      mouseMovePoint = xy;
      setMouseMovePoint(xy);
      return;
    }
    // here we set cursors
    if (isBottomRightOfSomeBoundingBox(xy, multiSelectBox)) {
      cursor = Cursor.scaleSE;
      setCursor(Cursor.scaleSE);
      return;
    }
    const rotateCursor = getRotateCursor(xy, items, selectedIds, multiSelectBox);
    if (rotateCursor) {
      cursor = rotateCursor;
      setCursor(rotateCursor);
    } else if (cursor != Cursor.Default) {
      cursor = Cursor.Default;
      setCursor(Cursor.Default);
    }
  };
  const onMouseUpNew = ({ event, xy, target, items, groups, selectedIds, cursor, multiSelectBox, z }) => {
    // if (
    //   !multiSelector.onMouseUpNew({
    //     event,
    //     xy,
    //     target,
    //     items,
    //     groups,
    //     selectedIds,
    //     cursor,
    //     multiSelectBox,
    //   })
    // ) {
    if (z.outline) {
      // set selection
      setSelectedIds([z.outline.mp_item_id]);

      // clear replacing icon (turned on when an svg is clicked and "replace" button is clicked)
      setReplacingIcon(null);

      setMouseWasDown(false);
      return;
    }
    if (mouseWasDown && Array.from(target?.classList).includes("Board")) {
      setSelectedIds([]);
      setMouseWasDown(false);
      return;
    }
    if (dragging == "scale" || dragging == "rotate" || dragging == "translate") {
      items = updateBoxes(svgRef, items, selectedIds);
      setItems({ ...items });
    }
    // }
    dragging = null;
    setDragging(dragging);

    setMouseWasDown(false);
  };
  return {
    onMouseMoveNew,
    onMouseDownNew,
    onMouseUpNew,
    onToolUnselect,
    isPointer: true,
    name: "pointer",
  };
}

export default usePointer;
