// @ts-nocheck
import { ShapeInfo } from "kld-intersections";
import RootSegment from "../tools/RootSegment";
import { dedupPoints, dup, dupz, generateRandomColor, isSamePoint, ppt, pptz, rand } from "../utils/utils";
import { bezierIntersections, splitBezierCurve } from "../utils/BezierSplitUtils";
import { onSameLine } from "../utils/bezierOffsetingUtils";

class BezierSegment extends RootSegment {
  constructor(type, p1, p2, opt = {}) {
    super(type, p1, p2, opt);
    if (isSameBezierPoint(p1, p2)) {
      console.error("when is a segment a point? ");
    }
    this.type = type;
    this.p1 = dupz(p1);
    this.p2 = dupz(p2);
    this.kldSegment = this.makeKldSegment(); // also sets radiusX, radiusY, startRadians, endRadians, largeArcFlag, sweepFlag if it's an elliptic arc

    this.points = [dup(p1), dup(p1.handles[1]), dup(p2.handles[0]), dup(p2)];

    this.id = rand() + "-" + new Date().getTime();
    this.stroke = opt.stroke || generateRandomColor();

    // GOTCHA: this has to stay in sync with RootSegment's init
    this.isStraightLine = onSameLine(this.p1, this.p1.handles[1], this.p2.handles[0], this.p2);
  }

  cut(p) {
    // cut will create 2 independent segments, with a corner at the intersection
    let o = splitBezierCurve(this.p1, this.p1.handles[1], this.p2.handles[0], this.p2, p);

    const p1 = dupz(this.p1),
      p2 = dupz(this.p2);
    p1.handles[1] = dup(o.curve1[1]);
    p2.handles[0] = dup(o.curve2[2]);

    let int1 = dup(p),
      int2 = dup(p);
    int1.handles = [dup(o.curve1[2]), dup(int1)];
    int2.handles = [dup(int2), dup(o.curve2[1])];
    return [new BezierSegment("bezier", dupz(p1), int1), new BezierSegment("bezier", int2, dupz(p2))];
  }

  intersects(segment: any) {
    if (segment.type !== "bezier") return super.intersects(segment);
    const pts = bezierIntersections(this.points, segment.points);
    if (!pts) return [];
    return dedupPoints(
      pts.map((p) => ({ x: p.x, y: p.y })),
      (a, b) => isSamePoint(a, b, 0.01),
    );
  }

  equals(seg) {
    if (this.type !== seg.type) return false;
    let thisPts = [this.p1, this.p1.handles[1], this.p2.handles[0], this.p2];
    let otherPts = [seg.p1, seg.p1.handles[1], seg.p2.handles[0], seg.p2];
    return thisPts.every((p, i) => isSamePoint(p, otherPts[i])) || thisPts.every((p, i) => isSamePoint(p, otherPts[3 - i]));
  }

  makeKldSegment() {
    return ShapeInfo.cubicBezier({ p1: this.p1, p2: this.p1.handles[1], p3: this.p2.handles[0], p4: this.p2 });
  }

  getPathData() {
    const a = this.p1,
      b = this.p1.handles[1],
      c = this.p2.handles[0],
      d = this.p2;

    return `M${f(a.x)},${f(a.y)} C ${f(b.x)} ${f(b.y)}, ${f(c.x)} ${f(c.y)}, ${f(d.x)} ${f(d.y)}`;
    // return `M${this.p1.x},${this.p1.y} C ${this.p1.handles[1].x} ${this.p1.handles[1].y}, ${this.p2.handles[0].x} ${this.p2.handles[0].y}, ${this.p2.x} ${this.p2.y}`;
  }

  str() {
    return `${pptz(this.p1)} -> ${pptz(this.p2)}`;

    // return `${ppt(this.p1)}, ${ppt(this.p1.handles[1])} -> ${ppt(this.p2.handles[0])}, ${ppt(this.p2)}`;
  }

  clone() {
    return new BezierSegment(this.type, dupz(this.p1), dupz(this.p2), { stroke: this.stroke });
  }
}
export default BezierSegment;

function isSameBezierPoint(p1, p2) {
  return isSamePoint(p1, p2) && isSamePoint(p1.handles[0], p2.handles[0]) && isSamePoint(p1.handles[1], p2.handles[1]);
}
const f = (x) => x.toFixed(2);
