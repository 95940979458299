// @ts-nocheck
import { useEffect, useState } from "react";
import { useSVG } from "../SVGContext";
import { Cursor } from "../values/enums";
import { getZoomLevel } from "../utils/utils";

function usePan() {
  let { svgRef, setCursor, setViewBox, toSvgUnits } = useSVG();
  let [isDragging, setIsDragging] = useState(false);
  let [prev, setPrev] = useState({ x: 0, y: 0 });
  let [debounceTimeout, setDebounceTimeout] = useState(null);

  const startDrag = (xy, viewBox) => {
    isDragging = true;
    setIsDragging(true);

    prev = xy;
    setPrev(prev);
  };

  const drag = (xy, viewBox) => {
    if (!isDragging) return;

    const z = getZoomLevel(svgRef);
    let dx = z * (xy.x - prev.x);
    let dy = z * (xy.y - prev.y);

    prev = xy;
    setPrev(prev);

    let x = viewBox.x - dx;
    let y = viewBox.y - dy;

    viewBox = { x, y, width: viewBox.width, height: viewBox.height };
    setViewBox(viewBox);
    // scroll SVGContainer, which is svgRef.current's parent
    // let svgContainer = svgRef.current.closest(".SVGContainer");
    // if (svgContainer) {
    //   svgContainer.scrollLeft -= dx;
    //   svgContainer.scrollTop -= dy;
    // }
  };

  const stopDrag = (xy, viewBox) => {
    prev = { x: 0, y: 0 };
    setPrev(prev);

    isDragging = false;
    setIsDragging(false);
  };

  const onMouseMoveNew = ({ e, xy, viewBox, z }) => {
    setCursor(Cursor.Grab);
    drag(xy, viewBox);
  };

  const onMouseDownNew = ({ e, xy, viewBox, z }) => {
    startDrag(xy, viewBox);
  };

  const onMouseUpNew = ({ event, xy, viewBox, target, items, ignore, selectedIndices, z }) => {
    stopDrag(xy, viewBox);
  };

  return { onMouseMoveNew, onMouseDownNew, onMouseUpNew, isPan: true, name: "pan" };
}

export default usePan;
