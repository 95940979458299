// @ts-nocheck

import { Dialog } from "@mui/material";
import { useSVG } from "../SVGContext";

export function SettingsDialog({ type, open, onClose }) {
    const { settings, setSettings } = useSVG();
    
    const style = {
        padding: "20px 20px 20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "10px"
    };

    function save() {
        onClose();
    }
    function setSelectedOption(key, value) {
        let nSettings = { ...settings };
        nSettings[key].value = value;
        setSettings(nSettings);
    }
    const settingsKeys = Object.keys(settings);

	return (
		<Dialog open={open} onClose={onClose}>
			<div style={{ background: "lightgray", padding: "15px", textAlign: "center", fontSize: "1.2em" }}>Settings</div>
			<div style={style}>
                {settingsKeys.map((key) => (
                    key != "language" && (
                    <div key={key} style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center"}}>
                        <div style={{ width: "100px" }}>{
                            key.charAt(0).toUpperCase() + key.slice(1)
                        }:</div>
                        <div>
                            <select value={settings[key].value}  onChange={(e) => setSelectedOption(key, e.target.value)}>
                                {settings[key].values.map((value) => (
                                    <option key={value} value={value} >{value}</option>
                                ))}
                            </select>
                        </div>
                    </div>)
                ))}
                
            </div>
            <button style={{width: "100%", marginTop: "20px", padding: "10px", border: "none"}} onClick={save}>Save</button>
		</Dialog>
	);
}
