// @ts-nocheck

import { D, pointsToPath, toDegree } from "./utils";

// Ramer-Douglas-Peucker algorithm
function simplifyDouglasPeucker(points, tolerance) {
	if (points.length <= 2) {
		return points;
	}

	// Find the point with the maximum distance
	let maxDistance = 0;
	let index = 0;
	const end = points.length - 1;
	for (let i = 1; i < end; i++) {
		const distance = perpendicularDistance(points[i], points[0], points[end]);
		if (distance > maxDistance) {
			maxDistance = distance;
			index = i;
		}
	}

	// If the max distance is greater than the tolerance, recursively simplify
	if (maxDistance > tolerance) {
		const leftPart = simplifyDouglasPeucker(points.slice(0, index + 1), tolerance);
		const rightPart = simplifyDouglasPeucker(points.slice(index), tolerance);
		return leftPart.slice(0, -1).concat(rightPart);
	} else {
		return [points[0], points[end]];
	}
}

// Calculate the perpendicular distance from a point to a line
function perpendicularDistance(point, lineStart, lineEnd) {
	const { x: x1, y: y1 } = lineStart;
	const { x: x2, y: y2 } = lineEnd;
	const { x, y } = point;

	const numerator = Math.abs((y2 - y1) * x - (x2 - x1) * y + x2 * y1 - y2 * x1);
	const denominator = Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));

	return numerator / denominator;
}
export function setSmoothPathData(item) {
	if (!item.smoothness) {
		item.d = pointsToPath(item.rawPoints, !!item.isClosed);
		item.smoothedPoints = [];
		item.smooth_d = "";
		return item;
	}
	
	item.type = "bezierPath";
	item.points = toBezier(item.rawPoints, item.smoothness);
	return item;
}
function angle(p, q) {
	let a = Math.atan2(q.y - p.y, q.x - p.x);
	while (a < 0) a += 2 * Math.PI;
	return a;
}

function dup(pt) { return { x: pt.x, y: pt.y }; }

export function toBezier(rawPoints, smoothness) {
	if (!smoothness) {
		return rawPoints.map(x => ({
			x: x.x,
			y: x.y,
			handles: [dup(x), dup(x)],
		}))
	}
	const smoothedPoints = simplifyDouglasPeucker(rawPoints, smoothness);
	let bezPoints = [];
	for (let i = 0; i < smoothedPoints.length; i += 1) {
		const prevP = smoothedPoints[i - 1];
		const nextP = smoothedPoints[i + 1];
		const p = smoothedPoints[i];
		let h, sizeOfHandle;
		if (!nextP || !prevP) {
			h = [dup(p), dup(p)];
		} else {
			let ab = angle(p, prevP);
			let cb = angle(p, nextP);
			let diff = Math.max(cb, ab) - Math.min(cb, ab);

			const bisect = Math.min(cb, ab) + diff / 2;
			const tangent = bisect + Math.PI / 2;
			// convert diff from 0 to 2PI to 0 to 1
			let diffNormalized = diff;
			if (diffNormalized > Math.PI) {
				diffNormalized = diffNormalized - Math.PI;
			}
			diffNormalized = diff / (2 * Math.PI);
			sizeOfHandle = 20 + 20 * diffNormalized;

			const h1 = { x: smoothedPoints[i].x + sizeOfHandle * Math.cos(tangent), y: smoothedPoints[i].y + sizeOfHandle * Math.sin(tangent) };
			const h2 = { x: smoothedPoints[i].x - sizeOfHandle * Math.cos(tangent), y: smoothedPoints[i].y - sizeOfHandle * Math.sin(tangent) };
			if (D(prevP, h1) < D(prevP, h2)) {
				h = [h1, h2];
			} else {
				h = [h2, h1];
			}
		}
		bezPoints.push({ x: smoothedPoints[i].x, y: smoothedPoints[i].y, handles: h, text: sizeOfHandle?.toFixed(2) });
	}
	return bezPoints;
}
