// @ts-nocheck
import { COPY_PROPS, constants } from "../values/constants";
import { BezierItem } from "../interfaces/BezierItem";
import { initSegments } from "./SegmentUtils";
import { segmentsToPoints } from "./bezierUtils";
import { transform } from "./transformUtils";

export const writeToClipboard = (json) => {
  navigator.clipboard.writeText(json).catch((error) => {
    console.error("Failed to write to clipboard:", error);
  });
};

export const readFromClipboard = () => {
  return navigator.clipboard.readText().catch((error) => {
    console.error("Failed to read from clipboard:", error);
  });
};

// cut
export function cut({ items, addItems, selectedIds, deleteItems }) {
  writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));

  deleteItems(selectedIds);
  return true;
}

// copy
export async function copy({ asFormat, items, svgRef, width, height, selectedIds, fileManager }) {
  if (asFormat == "json") {
    writeToClipboard(JSON.stringify(selectedIds.map((i) => items[i])));
  } else if (asFormat == "svg") {
    let copiedItems = await fileManager.getSVG({ svgRef });
    writeToClipboard(copiedItems);
  } else if (asFormat == "png") {
    fileManager.getPNG({ svgRef, width, height }, (pngBlob) => {
      const data = [new ClipboardItem({ "image/png": pngBlob })];
      return navigator.clipboard.write(data);
    });
  }
  return true;
}

// paste
export function paste({ addItems }) {
  readFromClipboard()
    .then((json) => {
      let sourceItems = JSON.parse(json);

      let copiedItems = sourceItems.map((source) => {
        if (!source.points) {
          source.points = segmentsToPoints(source.segments, source.isClosed);
        }

        let item = new BezierItem({ points: source.points, rawPoints: source.rawPoints, isClosed: source.isClosed, source: source });
        item = initSegments(item);
        for (let k of BezierItem.COPY_FIELDS) {
          item[k] = source[k];
        }
        for (let k of COPY_PROPS) {
          item[k] = source[k];
        }

        // FIXME: do gradient copying carefully

        item.center = item.center || item.getCenter();

        item = transform(item, { type: "translate", x: 20, y: 20 });
        return item;
      });
      addItems(copiedItems); // TODO: if group is absent, create it
    })
    .catch((e) => {
      console.error("Could not paste", e);
    });
  return true;
}
