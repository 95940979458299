// @ts-nocheck

import RootSegment from "../tools/RootSegment";

export class SeperatorSegment extends RootSegment {
  constructor() {
    super(SeperatorSegment.type);
    this.type = SeperatorSegment.type;
  }

  getPathData() {
    return "Z";
  }
  isSeparator() {
    return true;
  }
  clone() {
    return new SeperatorSegment();
  }

  str() {
    return "sep";
  }

  static type = "seperator";
}
