// @ts-nocheck
import { constants } from "../values/constants";
import { SWEEP, ShapeType } from "../values/enums";
import ArcSegment from "../segments/ArcSegment";
import BezierSegment from "../segments/BezierSegment";
import LineSegment from "../segments/LineSegment";
import PathSegment from "../segments/PathSegment";
import { SeperatorSegment } from "../segments/SeperatorSegment";
import RootSegment from "../tools/RootSegment";
import Segment from "../tools/Segment";
import { splitBezierCurve, splitBezierCurveV2 } from "./BezierSplitUtils";
import { offsetCubicBezierSegment } from "./bezierOffsetingUtils";
import { createSegment } from "./createSegment";
import { transformPoint } from "./transformUtils";
import { dedupPoints, dedupSegments, dup, dupz, isEqual, isSamePoint } from "./utils";

export function initSegments(item, dedupConsecutivePoints = true) {
  if (!item) return;
  const first = item.segments && item.segments[0];
  let isFirstASegment = first instanceof RootSegment;
  if (first && !isFirstASegment) {
    // if segments are not of type RootSegment, convert them to RootSegment
    // this is needed when we load items from json
    item.segments = item.segments.map((s) => createSegment(s.type, s.p1, s.p2, s));
    return item;
  }
  if (!item.points) {
    console.error("What to do with item without points?");
    return item;
  }
  // for all points, set handles if they're not set
  for (let i = 0; i < item.points.length; i++) {
    let p = item.points[i];
    if (p && !p.handles) {
      p.handles = [dup(p), dup(p)];
    }
  }
  // if 2 consecutive points are identical, remove them
  if (dedupConsecutivePoints) {
    for (let i = 0; i < item.points.length; i++) {
      if (!item.points[i]) continue;
      if (isSamePoint(item.points[i], item.points[i + 1])) {
        item.points.splice(i, 1);
        i--;
      }
    }
  }

  item.segments = [];
  let prev;
  // find the last point that is displayed and is not null (Z)
  for (let i = item.points.length - 1; i >= 0; i--) {
    if (!item.points[i] || item.points[i].display == false) continue;
    if (item.isClosed) {
      prev = item.points[i];
      break;
    }
  }
  let restart = true,
    firstPoint;
  for (let i = 0; i < item.points.length; i++) {
    let p = item.points[i];
    if (!p) continue;
    if (!item.points[i + 1] && i + 1 < item.points.length) {
      // this means Z is next, but some points are still remaining
      // the first point of the section that ends here -- that point's handles[0] and this point's handles[1] should be the same
      restart = true;
      if (firstPoint) {
        firstPoint.handles[0] = p.handles[1];
        firstPoint.prev = p;
        if (isSamePoint(firstPoint, p, 0.1)) {
          // TODO: should probably combine into same point?
        }
      }
      continue;
    }
    if (restart) {
      firstPoint = p;
    }
    restart = false;
  }

  // NOTE: if item is closed, then that doesn't matter here because that should reflect in the points no?
  // That is, the last point should be the same as the first point

  for (let i = 0; i < item.points.length; i++) {
    let p = item.points[i];
    if (!p) {
      item.segments.push(new SeperatorSegment());
      continue;
    }
    if (p.display == false) continue;

    if (p.prev || prev) {
      const s = createSegment("bezier", p.prev || prev, p);
      if (!s) {
        console.log("NULL SEGMENT");
      }
      item.segments.push(s);
    }
    prev = p;
  }
  return item;
}
export function unSegmentize(items) {
  for (let i = 0; i < items.length; i++) {
    delete items[i].tempSegments;
  }
  return items;
}
export function segmentizeUsingIntersections(items, selectedIndices, sequence, setIntersections) {
  for (let i of selectedIndices) {
    if (items[i].shapeBuilderIgnore || !items[i].segments) continue;

    const item1 = items[i];
    items[i].tempSegments = [];
    for (let ki = 0; ki < item1.segments.length; ki++) {
      if (item1.segments[ki].isSeparator()) continue;

      let ints = [];
      for (let j of selectedIndices) {
        if (i == j || items[j].shapeBuilderIgnore || !items[j].segments) continue;

        const item2 = items[j];
        for (let kj = 0; kj < item2.segments.length; kj++) {
          if (item2.segments[kj].isSeparator()) continue;

          let intsLocal = item1.segments[ki].intersects(item2.segments[kj]);
          ints = ints.concat(intsLocal);
          // sequence.push({ type: "segments", v: [item1.segments[ki], item2.segments[kj]] });
          // sequence.push({ type: "points", v: intsLocal });
          // sequence.push({ type: "clear" });
        }
      }
      ints = dedupPoints(ints, (x, y) => isSamePoint(x, y, 0.1));

      // this is because we want to sort the intersections along the path, and when there's an int that's same as the 0th or last
      // point, bec 0th/last points have handles and ints don't, we need to remove them
      // push dupz(item1.segments[ki].p1) to 0th pos
      ints.unshift(dupz(item1.segments[ki].p1));
      // push dupz(item1.segments[ki].p2) to last pos
      ints.push(dupz(item1.segments[ki].p2));

      // ints = [p1, all intersections on this segment from all other segments, p2]

      // intersections = intersections.concat(ints); // debugging

      if (ints.length == 2) {
        items[i].tempSegments.push({ segment: item1.segments[ki], parentSegment: item1.segments[ki] });
        continue;
      }

      // here is where we remove duplicates of 0th and last points
      if (isSamePoint(ints[0], ints[1])) {
        ints.splice(1, 1);
      }
      if (isSamePoint(ints[ints.length - 1], ints[ints.length - 2])) {
        ints.splice(ints.length - 2, 1);
      }

      // ints are item1.segments[ki]'s intersections, so their type should be the same as item1.segments[ki]
      ints = sortPointsAlongPathData(ints, item1.segments[ki].getPathData());

      if (item1.segments[ki].type == "bezier") {
        const endIdx = ints.length - 1;
        let startIdx = 0;
        for (let k = 1; k < ints.length - 1; k++) {
          const start = ints[startIdx];
          ints[k].handles = ints[k].handles || [ints[k], ints[k]];

          let o = splitBezierCurve(start, start.handles[1], ints[endIdx].handles[0], ints[endIdx], ints[k]);
          ints[startIdx].handles[1] = dup(o.curve1[1]);
          ints[endIdx].handles[0] = dup(o.curve2[2]);

          ints[k].handles[0] = dup(o.curve1[2]);
          ints[k].handles[1] = dup(o.curve2[1]);
          startIdx = k;
        }
      }
      for (let k = 0; k < ints.length - 1; k++) {
        let seg = createSegment(item1.segments[ki].type, ints[k], ints[k + 1]);
        items[i].tempSegments.push({ segment: seg, parentSegment: item1.segments[ki] });
      }
    }
  }
  return { sequence, items };
}

export function arrangeSegmentsForMergedPath(segments) {
  const EPS = 0.001;
  if (segments.length == 0) return [{ segments: [], path: "" }];

  segments = dedupSegments(segments);

  let next = Array.from({ length: segments.length }, () => -1);

  function fixThings(i, visited) {
    if (visited.has(i)) return;
    visited.add(i);

    const p2 = segments[i].p2;
    for (let j = 0; j < segments.length; j++) {
      if (segments[j].isSeparator() || visited.has(j) || next[j] != -1) continue;
      // if this segment starts at p2, mark it as next
      if (isSamePoint(p2, segments[j].p1, EPS)) {
        next[i] = j;
        fixThings(j, visited);
      }
      // if this segment also ends at p2, flip it, and mark it as next
      if (isSamePoint(p2, segments[j].p2, EPS)) {
        segments = swapEnds(segments, j);
        next[i] = j;
        fixThings(j, visited);
      }
    }
    if (next[i] == undefined && isSamePoint(segments[i].p2, segments[first].p1, EPS)) {
      next[i] = first; // set the next segment as the first one
    }
  }

  let v = new Set();

  const findFirst = (exclude) => {
    // first, find an endpoint that does not have another segment starting/ending there
    let commonEnds = new Set();
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].isSeparator() || exclude.has(i)) continue;
      let ends = [segments[i].p1, segments[i].p2];
      for (let j = 0; j < segments.length; j++) {
        if (segments[j].isSeparator() || i == j || exclude.has(j)) continue;
        let ends2 = [segments[j].p1, segments[j].p2];
        for (let e of ends) {
          for (let f of ends2) {
            if (isSamePoint(e, f, EPS)) {
              commonEnds.add(e);
            }
          }
        }
      }
    }
    let someFirst = null;
    for (let i = 0; i < segments.length; i++) {
      if (segments[i].isSeparator() || exclude.has(i)) continue;
      someFirst = i;
      if (!commonEnds.has(segments[i].p1)) {
        return i;
      } else if (!commonEnds.has(segments[i].p2)) {
        swapEnds(segments, i);
        return i;
      }
    }
    return someFirst;
  };

  let visited = new Set(),
    f = findFirst(visited),
    enclosures = [];
  while (f != null) {
    fixThings(f, v);
    let orderedSegments = [];
    let i = f;
    while (1) {
      if (visited.has(i) || !segments[i]) break;
      visited.add(i);
      orderedSegments.push(segments[i]);
      const n = next[i];
      if (n != -1 && isSamePoint(segments[i].p2, segments[n].p1, EPS)) {
        i = n;
      } else {
        break;
      }
    }
    f = findFirst(visited);

    const isClosed = orderedSegments.length >= 2 && isSamePoint(orderedSegments[0].p1, orderedSegments[orderedSegments.length - 1].p2, EPS);
    enclosures.push({
      segments: orderedSegments,
      path: segmentsToPathData(orderedSegments), // used to show gray region while hovering (shapebuilder)
      isClosed,
    });
  }
  return enclosures;
}
export function findCommonSegments(set1, set2) {
  let commonSegments = [];
  for (let i = 0; i < set1.length; i++) {
    for (let j = 0; j < set2.length; j++) {
      if (set1[i].equals(set2[j])) {
        commonSegments.push(set1[i]);
        commonSegments.push(set2[j]);
      }
    }
  }
  return commonSegments;
}
export function subtractSegments(set1, set2) {
  return set1.filter((x) => set2.filter((y) => y.equals(x)).length == 0);
}
export function segmentsToPathData(orderedSegments) {
  let d = "",
    first = true;

  for (let i = 0; i < orderedSegments.length; i++) {
    if (!orderedSegments[i]) {
      console.log("null segment");
      continue;
    }
    if (orderedSegments[i].isSeparator()) {
      d += "Z";
      first = true;
      continue;
    }
    let p = orderedSegments[i]
      .getPathData()
      // .replace("Z", "")
      .split(" ");
    if (!first) {
      p = p.slice(1); // remove M from all except first path
    }
    first = false;
    // console.log(p.join(" "));
    d += p.join(" ") + " ";
  }
  return d;
}
export function swapEndsOfSegment(old) {
  // if it's a line, swap p1,p2
  if (old.type == "line") {
    return new LineSegment("line", old.p2, old.p1, {});
  }
  // if it's an arc, flip sweepFlag & p1,p2
  else if (old.type == "arc") {
    return new ArcSegment("arc", old.p2, old.p1, {
      center: old.center,
      width: old.width,
      height: old.height,
      rotation: old.rotation,
      sweepFlag: old.sweepFlag == SWEEP.CLOCKWISE ? SWEEP.ANTICLOCKWISE : SWEEP.CLOCKWISE,
    });
  } else if (old.type == "path") {
    if (old.points) {
      return new PathSegment("path", old.p2, old.p1, {
        points: old.points.reverse(),
      });
    } else {
      console.log("not implemented: reversing path without supplying points");
    }
  } else if (old.type == "bezier") {
    let p1 = dupz(old.p2),
      p2 = dupz(old.p1);
    let tmp = p1.handles[1];
    p1.handles[1] = dup(p1.handles[0]);
    p1.handles[0] = dup(tmp);

    tmp = p2.handles[0];
    p2.handles[0] = dup(p2.handles[1]);
    p2.handles[1] = dup(tmp);

    return new BezierSegment("bezier", p1, p2);
  } else {
    console.error("oops not implemented");
  }
}

export function swapEnds(segments, i) {
  const res = swapEndsOfSegment(segments[i]);
  if (res) {
    segments[i] = res;
  }
  return segments;
}

export function sortPointsAlongPathData(inputPoints, d) {
  // d is the path data
  let points = [...inputPoints];
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", d);
  path.setAttribute("opacity", "0");
  const pathLength = path.getTotalLength();
  for (let j = 0; j < pathLength; j += 2) {
    const point = path.getPointAtLength(j);
    for (let k = 0; k < points.length; k++) {
      if (isSamePoint(point, points[k], 1)) {
        points[k].pathLength = j;
      }
    }
  }
  points.sort((a, b) => {
    return a.pathLength - b.pathLength;
  });

  // delete path
  path.remove();
  return points;
}
export const initPointsForShape = (shape) => {
  if (shape.shapeType == ShapeType.Rectangle) {
    shape = computePolygonPoints(shape);
  } else if (shape.shapeType == ShapeType.Circle) {
    shape = initSegmentsEllipse(shape);
  } else if (shape.shapeType == ShapeType.Polygon) {
    shape = computePolygonPoints(shape);
  } else if (shape.shapeType == ShapeType.Star) {
    shape.points = computeStarPoints(shape);
  } else if (shape.shapeType == ShapeType.Line) {
  } else if (shape.shapeType == ShapeType.Arc) {
    shape = computeArcPoints(shape);
  }
  if (shape.points) {
    shape.points = shape.points.map((p) => {
      const h = p.handles || [
        { x: p.x, y: p.y },
        { x: p.x, y: p.y },
      ];
      return { x: p.x, y: p.y, handles: h, isCorner: isSamePoint(p, h[0]) && isSamePoint(p, h[1]) };
    });
  }
  return shape;
};

// idx can be 0, 1, 2 or 3, to mean top, top-right, bottom or bottom-left
export function initEllipsePoint(idx, params) {
  const { c, rx, ry } = params;
  const d = (4 / 3) * Math.tan(Math.PI / 8);
  const diffX = d * rx,
    diffY = d * ry;

  let p = { x: c.x, y: c.y };
  if (idx == 0) p.y -= ry;
  if (idx == 1) p.x += rx;
  if (idx == 2) p.y += ry;
  if (idx == 3) p.x -= rx;

  let h = [
    {
      x: p.x,
      y: p.y,
    },
    {
      x: p.x,
      y: p.y,
    },
  ];
  if (idx == 0) {
    h[0].x -= diffX;
    h[1].x += diffX;
  }
  if (idx == 1) {
    h[0].y -= diffY;
    h[1].y += diffY;
  }
  if (idx == 2) {
    h[0].x += diffX;
    h[1].x -= diffX;
  }
  if (idx == 3) {
    h[0].y += diffY;
    h[1].y -= diffY;
  }
  return { x: p.x, y: p.y, handles: h };
}

export function initSegmentsEllipse(item) {
  item.points = [];
  const params = {
    c: item.center,
    rx: item.width / 2,
    ry: item.height / 2,
  };
  for (let i = 0; i < 4; i++) {
    item.points.push(initEllipsePoint(i, params));
  }
  item.segments = item.points.map(
    (p, j) =>
      new BezierSegment("bezier", p, item.points[(j + 1) % item.points.length], {
        width: item.width,
        center: item.center,
        height: item.height,
      }),
  );

  return item;
}
export function computePolygonPoints(item) {
  // scale is handled by modifying width & height
  // translate is handled by modifying center
  // rotation is handled by setting item.rotation.angle & item.center
  item.rotation = item.rotation || { angle: 0, center: item.center };

  if (item.sides == 4) {
    // just manually compute the points
    let points = [
      { x: item.center.x - item.width / 2, y: item.center.y - item.height / 2 },
      { x: item.center.x + item.width / 2, y: item.center.y - item.height / 2 },
      { x: item.center.x + item.width / 2, y: item.center.y + item.height / 2 },
      { x: item.center.x - item.width / 2, y: item.center.y + item.height / 2 },
    ];
    // apply rotation manually
    for (let i = 0; i < points.length; i++) {
      let x = points[i].x - item.center.x;
      let y = points[i].y - item.center.y;
      points[i].x = x * Math.cos(item.rotation.angle) - y * Math.sin(item.rotation.angle) + item.center.x;
      points[i].y = x * Math.sin(item.rotation.angle) + y * Math.cos(item.rotation.angle) + item.center.y;
    }
    item.points = points;
    return item;
  }
  var points = [];
  for (var i = 0; i < item.sides; i++) {
    var angle = (2 * Math.PI * i) / item.sides;
    angle -= item.sides == 4 ? (3 * Math.PI) / 4 : 0;
    angle += item.rotation.angle;

    var x = item.center.x + (item.width * Math.cos(angle)) / 2;
    var y = item.center.y + (item.height * Math.sin(angle)) / 2;

    points.push({ x, y });
  }
  item.points = points;
  return item;
}

export function computeStarPoints(item) {
  item.rotation = item.rotation || { angle: 0, center: item.center };
  var points = [];
  for (let i = 0; i < item.sides * 2; i++) {
    const r = i % 2 === 0 ? item.outerRadius : item.innerRadius;
    let angle = (Math.PI / item.sides) * i;
    angle += item.rotation.angle;
    const x = item.center.x + Math.cos(angle) * r;
    const y = item.center.y + Math.sin(angle) * r;
    points.push({ x, y });
  }

  return points;
}
export function getSmoothPathData(item) {
  let pathData = `M ${item.smoothedPoints[0][0]} ${item.smoothedPoints[0][1]}`; // Move to the first point
  for (let i = 1; i < item.smoothedPoints.length; i += 3) {
    const p1 = item.smoothedPoints[i];
    const p2 = item.smoothedPoints[i + 1];
    const p3 = item.smoothedPoints[i + 2];
    if (!p2) {
      pathData += `L ${p1[0]} ${p1[1]}`; // Line to the first point
    } else if (!p3) {
      pathData += `Q ${p1[0]} ${p1[1]}, ${p2[0]} ${p2[1]}`; // Quadratic Bezier curve
    } else {
      pathData += `C ${p1[0]} ${p1[1]}, ${p2[0]} ${p2[1]}, ${p3[0]} ${p3[1]}`; // Cubic Bezier curve
    }
  }
  return pathData;
}
