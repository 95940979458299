// @ts-nocheck

import { BezierItem } from "./interfaces/BezierItem";
import { transform } from "./utils/transformUtils";
import { toDegree } from "./utils/utils";

function setfillOrGrad({ item, fill, gradient }) {
  if (fill) {
    item.fill = fill;
    item.gradient = null;
  } else {
    item.gradient = gradient;
    item.fill = "url(#" + gradient.id + ")";
  }
  return item;
}

export function Commands({ setItems, history, animationOpenFn }) {
  function fill({ fill, gradient, items, selectedIds }) {
    let prevs = [];
    for (let i of selectedIds) {
      const prev = items[i].fill;
      prevs.push(prev);
      items[i] = setfillOrGrad({ item: items[i], fill, gradient });
    }
    setItems({ ...items });

    history.addUndo(() => {
      for (let i of selectedIds) {
        items[i].fill = prevs.shift();
      }
      setItems({ ...items });
    });
    return items;
  }

  function Transform({ items, selectedId, transformation }) {
    const item = items[selectedId];
    if (item.locked) return;

    let it = item.clone ? item.clone() : { ...item };

    items[selectedId] = transform(item, transformation);

    setItems({ ...items });

    let last = history.getHistoryList()[history.getHistoryList().length - 1];
    if (last && last.fn && last.fn.selectedId === selectedId && last.fn.transformationType === transformation.type) {
      return;
    }

    let oldItems = { ...items };
    let fn = () => {
      items = oldItems;
      for (let k of BezierItem.COPY_FIELDS) {
        items[selectedId][k] = it[k];
      }

      setItems({ ...items });
    };
    fn.selectedId = selectedId;
    fn.transformationType = transformation.type;

    history.addUndo(fn);
  }

  return { fill, Transform };
}
