// @ts-nocheck

import { constants } from "../values/constants";
import BezierSegment from "../segments/BezierSegment";
import { approximateTForPointOnBezier, bezierPoint, splitBezierCurve, splitBezierCurveWithT } from "./BezierSplitUtils";
import { EnclosureFinder } from "./EnclosureFinder";
import { segmentizeUsingIntersections } from "./SegmentUtils";
import { findClosest } from "./bezierCornerUtils";
import { offsetCubicBezierSegment } from "./bezierOffsetingUtils";
import { normalAngleAtT, tangentAngleAtT } from "./bezierUtils";
import { findAngleAtPointInEllipse } from "./ellipseUtils";
import { transformSegment } from "./transformUtils";
import { computeRadialAngle, shouldTranslateAwayFromCenter, signedDistance } from "./translatePointUtils";
import { isSamePoint, D, dedupSegments, dedupFloats, ppt, dup, dupz, toDegree, normalize, time, pointAt, toRadians, dedupPoints } from "./utils";
const { ShapeInfo, Intersection } = require("kld-intersections");

export function isSameSegmentSet(s1, s2) {
  if (!s1 || !s2 || s1.length != s2.length) {
    return false;
  }
  s1 = Array.from(s1);
  s2 = Array.from(s2);

  return s1.every((s, i) => s.equals(s2[i]));
}

function hasSegment(segments, segment) {
  for (let i = 0; i < segments.length; i++) {
    if (segments[i].segment.equals(segment)) return true;
  }
  return false;
}
export function findEnclosureV3(items, selectedIds, svgRef, sequence, setIntersections) {
  let its = selectedIds.map((i) => items[i]).filter((x) => !x.shapeBuilderIgnore);
  its = segmentizeUsingIntersections(
    its,
    its.map((_, i) => i),
    sequence,
    setIntersections,
  ).items;
  let tempSegments = [];
  for (let it of its) {
    tempSegments = [...tempSegments, ...it.tempSegments.map((x) => x.segment)];
  }
  for (let t1 of tempSegments) {
    for (let t2 of tempSegments) {
      if (isSamePoint(t1.p1, t2.p1) && isSamePoint(t1.p2, t2.p2)) continue;

      let angle, p;
      if (isSamePoint(t1.p1, t2.p1)) {
        angle = angleAtB(t1.p2, t1.p1, t2.p2);
        p = t1.p1;
      } else if (isSamePoint(t1.p2, t2.p2)) {
        angle = angleAtB(t1.p1, t1.p2, t2.p1);
        p = t1.p2;
      } else if (isSamePoint(t1.p1, t2.p2)) {
        angle = angleAtB(t1.p2, t1.p1, t2.p1);
        p = t1.p1;
      } else if (isSamePoint(t1.p2, t2.p1)) {
        angle = angleAtB(t1.p1, t1.p2, t2.p2);
        p = t1.p2;
      }
      if (angle == undefined) continue;
      const line = ShapeInfo.line({
        p1: pointAt(p, -10, angle),
        p2: pointAt(p, 10, angle),
      });
      t1.lines = t1.lines || [];
      t1.lines.push({
        p,
        line,
      });
      t2.lines = t2.lines || [];
      t2.lines.push({
        p,
        line,
      });
    }
  }

  let translatedSegments = [];
  for (let it of its) {
    for (let tt of it.tempSegments) {
      const t = tt.segment;
      let t1 = offsetCubicBezierSegment(t, constants.shapeBuilder.d),
        t2 = offsetCubicBezierSegment(t, -constants.shapeBuilder.d);

      for (let lineAndPoint of t.lines) {
        if (isSamePoint(lineAndPoint.p, t.p1)) {
          // keep from intersection to p2
          let ints = t1.intersectsKLDLine(lineAndPoint.line);
          if (ints.length > 0) {
            t1 = trimIfItReducesSizeV2(t1, 0, ints);
          }

          ints = t2.intersectsKLDLine(lineAndPoint.line);
          if (ints.length > 0) {
            t2 = trimIfItReducesSizeV2(t2, 0, ints);
          }
        } else {
          // keep from intersection to p1
          let ints = t1.intersectsKLDLine(lineAndPoint.line);
          if (ints.length > 0) {
            t1 = trimIfItReducesSizeV2(t1, 1, ints);
          }

          ints = t2.intersectsKLDLine(lineAndPoint.line);
          if (ints.length > 0) {
            t2 = trimIfItReducesSizeV2(t2, 1, ints);
          }
        }
      }
      t1.isTranslatedSegment = true;
      t2.isTranslatedSegment = true;
      translatedSegments = [...translatedSegments, t1, t2];
      t.translatedSegments = [t1, t2];
    }
  }

  const finder = new EnclosureFinder(tempSegments, translatedSegments, (pts) => filterPointsInsideItems(svgRef, pts, items), sequence);
  return {
    translatedSegments,
    finder,
    sequence,
  };
}
export function findSomeTranslatedSegment(xy, segments) {
  for (let angle = 0; angle < 360; angle += 22) {
    const { x: x2, y: y2 } = pointAt(xy, 10000, toRadians(angle));
    const line = ShapeInfo.line(xy.x, xy.y, x2, y2);
    let closest;

    // find the closest translated segment that intersects with this line
    let minDistance = Number.MAX_VALUE;
    for (let j = 0; j < segments.length; j++) {
      let ints = segments[j].intersectsKLDLine(line);
      for (let point of ints) {
        const distance = D(point, xy);
        if (distance < minDistance) {
          minDistance = distance;
          closest = segments[j];
        }
      }
    }
    if (closest) return closest;
  }
}

export function findClosestSegmentsV2(pt, segments, isValidSegment) {
  let closestSegments = [],
    lines = [];
  for (let angle = 0; angle < 360; angle += 22) {
    // for each line drawn in some angle from clicked point
    const { x: x2, y: y2 } = pointAt(pt, 10000, toRadians(angle));
    // const x2 = pt.x + Math.cos((angle * Math.PI) / 180) * 10000;
    // const y2 = pt.y + Math.sin((angle * Math.PI) / 180) * 10000;
    const line = ShapeInfo.line(pt.x, pt.y, x2, y2);

    // find the closest translated segment that intersects with this line
    let minDistance = Number.MAX_VALUE;
    let closest = null,
      bestLine;
    for (let j = 0; j < segments.length; j++) {
      let ints = segments[j].intersectsKLDLine(line);
      for (let point of ints) {
        const distance = Math.sqrt(Math.pow(point.x - pt.x, 2) + Math.pow(point.y - pt.y, 2));
        if (distance < minDistance) {
          minDistance = distance;
          closest = segments[j];
          bestLine = { p1: pt, p2: point };
        }
      }
    }
    if (isValidSegment(closest)) {
      closestSegments.push(closest);
      lines.push(bestLine);
    }
  }

  // if for any point, both translations of the same tempSegment shows up, this point is an invalid point
  let ids = Array.from(new Set(closestSegments.map((x) => x.id)));
  ids = ids.map((x) => x.replace("plus", "").replace("minus", ""));
  const unique = [...new Set(ids)];
  if (ids.length != unique.length) return { closestSegments: [] };

  return { closestSegments, lines };
}
export function findEnclosingSegmentsV2(xy, items, selectedIds, addAdjacentSegmentsWithoutIntersections, setIntersections) {
  let sequence = [];

  let o = findClosestSegments(xy, items, selectedIds, sequence);
  let segments = o.segments;
  sequence = o.sequence;

  let enclosingSegments = [];
  for (let i = 0; i < segments.length; i++) {
    if (hasSegment(enclosingSegments, segments[i].segment)) continue;
    enclosingSegments.push(segments[i]);
    // uncomment to see which segments are enclosing - part 1
    // sequence.push({ type: "point", v: segments[i].pt });
    // sequence.push({ type: "line", v: segments[i].line });
    // sequence.push({ type: "point", v: segments[i].closestIntersection });
    // sequence.push({ type: "segment", v: segments[i].segment });
  }
  const allSegs = allSegments(items);
  segments = enclosingSegments;

  while (segments.length > 0) {
    let nSegments = [];
    for (const q of segments) {
      const bef = time();

      const segment = items[q.itemId].tempSegments[q.segmentId].segment;
      let o = translateTowardsPoint(segment, q.pt, q.closestIntersection, allSegs, items, selectedIds, sequence);

      const newSegment = o.newSegment;
      o.sequence = sequence;
      if (newSegment.untrimmed) {
        // if it was never trimmed, then don't use it to generate new points from which to draw lines to find more enclosing segments
        // this is a design choice. it seems like untrimmed segments almost always cause points to escape outside the region
        continue;
      }

      // sequence.push({ type: "segment", v: newSegment });

      o = findClosestSegments(newSegment.p1, items, selectedIds, sequence);
      let s1 = o.segments;
      sequence = o.sequence;

      o = findClosestSegments(newSegment.p2, items, selectedIds, sequence);
      let s2 = o.segments;
      sequence = o.sequence;

      const newlyFound = s1.concat(s2);

      for (let i = 0; i < newlyFound.length; i++) {
        if (hasSegment(enclosingSegments, newlyFound[i].segment)) continue;
        // uncomment to see which segments are enclosing - part 2
        // sequence.push({ type: "point", v: newlyFound[i].pt });
        // sequence.push({ type: "line", v: newlyFound[i].line });
        // sequence.push({ type: "point", v: newlyFound[i].closestIntersection });
        // sequence.push({ type: "segment", v: newlyFound[i].segment });

        nSegments.push(newlyFound[i]);
        enclosingSegments.push(newlyFound[i]);
      }
      console.log(time() - bef);
    }
    segments = nSegments;
  }

  let adj = [];
  // this thing causes bugs when there are overlapping segments
  // if (addAdjacentSegmentsWithoutIntersections) {
  // 	// if segment's endpoint is just a corner (as opposed to an intersection with another segment), then add it
  // 	for (let q of enclosingSegments) {
  // 		let adjLocal = getAdjacentSegments(items, q.itemId, q.segmentId, true).concat(getAdjacentSegments(items, q.itemId, q.segmentId, false));
  // 		adjLocal = adjLocal.filter((x) => !hasSegment(enclosingSegments, x));
  // 		adj = [...adj, ...adjLocal];
  // 	}
  // 	adj = dedupSegments(adj);
  // }

  enclosingSegments = enclosingSegments.map((x) => x.segment).concat(adj);

  return { enclosingSegments, sequence };
}
// function getAdjacentSegments(items, itemId, segmentId, forward) {
// 	let adj = [];
// 	const ts = items[itemId].tempSegments;
// 	let i = segmentId;
// 	while (1) {
// 		const segment = ts[i].segment,
// 			parent = ts[i].parentSegment;

// 		adj.push(segment);

// 		if (forward && !isSamePoint(segment.p2, parent.p2)) break;
// 		if (!forward && !isSamePoint(segment.p1, parent.p1)) break;

// 		// go forward or backward, all around the perimeter of item, until you come back to this segment
// 		if (forward) {
// 			i = (i + 1) % ts.length;
// 		} else {
// 			i = (i - 1 + ts.length) % ts.length;
// 		}
// 		if (i == segmentId) break;
// 	}
// 	return adj;
// }
function translateTowardsPoint(segment, pt, intersection, segments, items, selectedIds, sequence) {
  // Check which side of the segment pt is on
  let newSegment;

  if (segment.type == "bezier") {
    // create 2 newSegment options. Choose the one that's closest to the
    // pt when you draw a line from pt to intersection
    const seg1 = offsetCubicBezierSegment(segment, constants.shapeBuilder.d);
    const seg2 = offsetCubicBezierSegment(segment, -constants.shapeBuilder.d);
    newSegment = seg1;
    // draw line from pt to intersection
    const line = ShapeInfo.line(pt.x, pt.y, intersection.x, intersection.y);

    // find which segment it cuts
    const intersects1 = seg1.intersectsKLDLine(line).length > 0;

    if (intersects1) {
      newSegment = seg1;
    } else {
      newSegment = seg2;
    }
  } else {
    console.error("translateTowardsPoint not implemented for segment type: ", segment.type);
    return null;
  }
  let eps = [segment.p1, segment.p2];
  for (let k = 0; k < 2; k++) {
    // For each endpoint, draw 2X lines, bisecting X intersections. If newSegment cuts any of these lines, trim it.
    let angles = findAnglesAtEndpoint(segments, segment, k);

    angles = angles.concat(angles.map((x) => x + Math.PI));
    let ints = [];
    for (let ang of angles) {
      // for each bisecting angle, find intersection point of newSegment with the line drawn at bisecting angle
      const d = constants.shapeBuilder.bisectorLineDist;
      const p1 = {
          x: eps[k].x + d * Math.cos(ang),
          y: eps[k].y + d * Math.sin(ang),
        },
        p2 = {
          x: eps[k].x - d * Math.cos(ang),
          y: eps[k].y - d * Math.sin(ang),
        };
      const line = ShapeInfo.line(p1.x, p1.y, p2.x, p2.y);
      ints = [...ints, ...newSegment.intersectsKLDLine(line)];
    }
    // will work only for bezier
    // trim only if it reduces the segment length -- if multiple bisecting lines intersect, choose the one that trims the newSegment the most
    newSegment = trimIfItReducesSizeV2(newSegment, k, ints);
  }
  if (isSamePoint(eps[0], newSegment.p1) && isSamePoint(eps[1], newSegment.p2)) {
    newSegment.untrimmed = true;
  }
  return { sequence, newSegment };
}
function findClosestSegments(pt, items, selectedIds, sequence) {
  let closestSegments = [],
    chosenLine;
  for (let angle = 0; angle < 360; angle += 21) {
    // for each line drawn in some angle from clicked point
    const x2 = pt.x + Math.cos((angle * Math.PI) / 180) * 10000;
    const y2 = pt.y + Math.sin((angle * Math.PI) / 180) * 10000;
    const line = ShapeInfo.line(pt.x, pt.y, x2, y2);

    // find the closest segment that intersects with this line
    let minDistance = Number.MAX_VALUE,
      itemId = -1,
      closestIntersection = null,
      segmentId = -1;
    for (let idx = 0; idx < selectedIds.length; idx++) {
      const item = items[selectedIds[idx]];
      if (item.shapeBuilderIgnore || !item.tempSegments) continue;

      // for every item
      for (let j = 0; j < item.tempSegments.length; j++) {
        // for every segment in this item
        let ints = item.tempSegments[j].segment.intersectsKLDLine(line);

        // find the closest intersection point with the line
        for (let k = 0; k < ints.length; k++) {
          const point = ints[k];

          const distance = Math.sqrt(Math.pow(point.x - pt.x, 2) + Math.pow(point.y - pt.y, 2));
          if (distance < minDistance) {
            minDistance = distance;
            itemId = selectedIds[idx];
            segmentId = j;
            closestIntersection = point;
            chosenLine = {
              p1: {
                x: pt.x,
                y: pt.y,
              },
              p2: {
                x: point.x,
                y: point.y,
              },
            };
          }
        }
      }
    }
    // closestSegment who does not have the line-intersection as one of its endpoints
    if (itemId == -1 || segmentId == -1) continue;
    const closestSegment = items[itemId].tempSegments[segmentId].segment;
    if (isSamePoint(closestIntersection, closestSegment.p1) || isSamePoint(closestIntersection, closestSegment.p2)) {
      continue;
    }

    closestSegments.push({
      segment: closestSegment,
      itemId,
      segmentId,
      pt,
      closestIntersection,
      line: chosenLine,
    });
  }
  return { segments: closestSegments, sequence };
}

function allSegments(items) {
  let res = [];
  for (let item of items) {
    if (item.shapeBuilderIgnore || !item.tempSegments) continue;
    res = [...res, ...item.tempSegments.map((x) => x.segment)];
  }
  return res;
}
export function diffAngleAtB(pointA, pointB, pointC) {
  let ab = Math.atan2(pointA.y - pointB.y, pointA.x - pointB.x); // B to A
  while (ab < 0) ab += 2 * Math.PI;
  let cb = Math.atan2(pointC.y - pointB.y, pointC.x - pointB.x); // B to C
  while (cb < 0) cb += 2 * Math.PI;
  let diff = Math.max(cb, ab) - Math.min(cb, ab);
  return diff;
}
export function angleAtB(pointA, pointB, pointC) {
  let ab = Math.atan2(pointA.y - pointB.y, pointA.x - pointB.x); // B to A
  while (ab < 0) ab += 2 * Math.PI;
  let cb = Math.atan2(pointC.y - pointB.y, pointC.x - pointB.x); // B to C
  while (cb < 0) cb += 2 * Math.PI;
  let diff = Math.max(cb, ab) - Math.min(cb, ab);
  const res = Math.min(cb, ab) + diff / 2;
  return normalize(res);
}
export function trimIfItReducesSizeV2(newSeg, e, trimPoints) {
  const a = newSeg.p1,
    b = newSeg.p1.handles[1],
    c = newSeg.p2.handles[0],
    d = newSeg.p2;
  let bestT = e == 0 ? 0 : 1,
    bestTrimPoint;
  for (let trimPoint of trimPoints) {
    const t = approximateTForPointOnBezier(a, b, c, d, trimPoint),
      p = bezierPoint(a, b, c, d, t);
    if (isSamePoint(p, trimPoint, 3)) {
      if ((e == 0 && bestT < t) || (e == 1 && bestT > t)) {
        bestT = t;
        bestTrimPoint = trimPoint;
      }
    }
  }
  // sequence.push({ type: "point", v: bestTrimPoint || (e == 0 ? newSeg.p1 : newSeg.p2) });

  if (!bestTrimPoint) {
    return newSeg;
  }
  const o = splitBezierCurveWithT(a, b, c, d, bestT);
  bestTrimPoint.handles = [o.curve1[2], o.curve2[1]];
  let p1 = newSeg.p1,
    p2 = newSeg.p2;
  p1.handles[1] = o.curve1[1];
  p2.handles[0] = o.curve2[2];

  if (e == 0) {
    p2 = newSeg.p2;
    p1 = bestTrimPoint;
  } else {
    p1 = newSeg.p1;
    p2 = bestTrimPoint;
  }
  const res = new BezierSegment("bezier", p1, p2);
  res.id = newSeg.id;
  // sequence.push({ type: "segment", v: res });
  return res;
}
export function findAnglesAtEndpoint(segments, s, ep) {
  let angles = [];
  const lines = getSegmentLines(s, ep);
  for (let thisLine of lines) {
    const otherSegments = segments.filter((x) => x.id != s.id && (isSamePoint(x.p1, thisLine.p1, 3) || isSamePoint(x.p2, thisLine.p1, 3)));

    let otherPoints = [];
    for (let other of otherSegments) {
      let l = getSegmentLines(other, isSamePoint(other.p1, thisLine.p1) ? 0 : 1);
      otherPoints = otherPoints.concat(l?.map((x) => x.p2));
    }
    let angs = otherPoints.map((otherPoint) => angleAtB(otherPoint, thisLine.p1, thisLine.p2));
    angles = angles.concat(angs);
  }

  angles = dedupFloats(angles);
  angles = angles.sort((a, b) => a - b);
  return angles;
}
// Function to calculate the angle between two line segments at point B with respect to the positive x-axis
export function getSegmentLines(s, ep, dist = 100) {
  if (s.type == "bezier") {
    let angle;
    const p = ep == 0 ? s.p1 : s.p2,
      q = ep == 0 ? s.p2 : s.p1;
    if (s.isStraightLine) {
      angle = Math.atan2(q.y - p.y, q.x - p.x);
    } else {
      angle = tangentAngleAtT(s.p1, s.p1.handles[1], s.p2.handles[0], s.p2, ep);
    }
    const otherEnd1 = {
        x: p.x + dist * Math.cos(angle),
        y: p.y + dist * Math.sin(angle),
      },
      otherEnd2 = {
        x: p.x + dist * Math.cos(angle + Math.PI),
        y: p.y + dist * Math.sin(angle + Math.PI),
      };
    let otherEnd = otherEnd1;
    if (diffAngleAtB(q, p, otherEnd2) < diffAngleAtB(q, p, otherEnd1)) {
      otherEnd = otherEnd2;
    }
    return [
      {
        p1: p,
        p2: otherEnd,
        stroke: s.stroke,
        strokeWidth: 4,
      },
    ];
  } else {
    console.error("getSegmentLines not implemented for segment type: ", s.type);
  }
}

export function translateAndTrim(segment, tempSegments) {
  let newSegments = [offsetCubicBezierSegment(segment, constants.shapeBuilder.d), offsetCubicBezierSegment(segment, -constants.shapeBuilder.d)];
  return newSegments.map((newSegment) => {
    let eps = [segment.p1, segment.p2],
      lines = [];
    for (let k = 0; k < 2; k++) {
      // For each endpoint, draw 2X lines, bisecting X intersections. If newSegment cuts these lines, trim it.
      let angles = findAnglesAtEndpoint(tempSegments, segment, k);
      angles = angles.concat(angles.map((x) => x + Math.PI));

      let ints = [];
      for (let ang of angles) {
        // for each bisecting angle, find intersection point of newSegment with the line drawn at bisecting angle
        const d = constants.shapeBuilder.bisectorLineDist;
        const p1 = {
            x: eps[k].x + d * Math.cos(ang),
            y: eps[k].y + d * Math.sin(ang),
          },
          p2 = {
            x: eps[k].x - d * Math.cos(ang),
            y: eps[k].y - d * Math.sin(ang),
          };
        const line = ShapeInfo.line(p1.x, p1.y, p2.x, p2.y);
        lines.push(line);
        ints = [...ints, ...newSegment.intersectsKLDLine(line)];
      }

      // trim only if it reduces the segment length -- if multiple bisecting lines intersect, choose the one that trims the newSegment the most
      newSegment = trimIfItReducesSizeV2(newSegment, k, ints);
    }

    if (isSamePoint(eps[0], newSegment.p1) && isSamePoint(eps[1], newSegment.p2)) {
      newSegment.untrimmed = true;
    }
    return newSegment;
  });
}
